@import '../../../../assets/colors.scss';
@import '../../../../assets/texts/slider_text.scss';

.past-events-container {
  margin-right: -36px;

  @media screen and (max-width: 1400px) {
    margin-right: -12px;
  }

  @media screen and (max-width: 450px) {
    margin-right: 0;
  }
}

.text-page-header--size-15 {
  margin: 23px 5px 20px;
}

.past-events {
  .slider {
    width: 100%;

    .slick-next {
      right: 5px;
      width: 30px;
      height: 30px;
      background: rgba(#fff, 0.9) url(../../../../assets/image/slider/next.svg) 12px 9px / 25% no-repeat;
      border-radius: 50%;
      box-shadow: 0 0 35px 10px rgba(#000, 0.2);
      transition: box-shadow 200ms;
    }

    .slick-prev {
      left: 5px;
      width: 30px;
      height: 30px;
      z-index: 555;
      background: rgba(#fff, 0.9) url(../../../../assets/image/slider/back.svg) 12px 9px / 25% no-repeat;
      border-radius: 50%;
      box-shadow: 0 0 35px 10px rgba(#000, 0.2);
      transition: box-shadow 200ms;
    }

    .slick-prev:hover,
    .slick-next:hover {
      box-shadow: 0 0 35px 10px rgba(#000, 0.3);
    }

    .slick-prev::before,
    .slick-next::before {
      content: '';
    }
  }

  .slider-item {
    position: relative;
    margin-right: 21px;
    height: 225px;
    border-radius: 15px;
    overflow: hidden;
    margin-bottom: 25px;

    @media screen and (max-width: 990px) {
      margin: 10px 5px;
    }

    @media screen and (max-width: 480px) {
      height: 175px;
      margin: 10px 5px;
    }

    &__note {
      position: absolute;
      top: 0;
      right: 20px;
      cursor: pointer;
      z-index: 4;
    }

    &__bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(rgba(6, 22, 52, 0) 45%, #000 125%);
        z-index: 2;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &__body {
      position: relative;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      padding: 20px 23px;
      z-index: 3;
    }

    &__button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      align-self: center;
      cursor: pointer;
    }

    &__title {
      @extend %slider_title;

      margin-bottom: 12px;
    }

    &__link {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &__text {
      @extend %slider_link;

      max-width: 200px;
      padding-right: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &__link::after {
      cursor: pointer;
    }
  }

  &__video {
    display: flex;
    width: 100% !important;
    height: auto !important;

    @media screen and (min-width: 768px) {
      width: auto !important;
    }

    @media screen and (min-width: 840px) {
      width: auto !important;
    }

    .ant-spin-nested-loading {
      min-height: 240px;
    }

    .ant-modal-body {
      padding: 0;

      & > div {
        @media screen and (max-width: 768px) {
          width: 100% !important;
          height: auto !important;
        }
      }
    }

    .ant-modal-content {
      background-color: transparent;
    }
  }
}
