@import '../../../../../assets/texts/questions_text.scss';
@import '../../../../../assets/colors.scss';
@import '../../../../../assets/mixin.scss';
@import '../../../../../assets/texts/edit_modal_form.scss';

.edit-photo-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .add-photo-icon {
    position: absolute;
    width: 30px;
    border-radius: 90px;
    height: 30px;
  }
}

.plus-outlined {
  width: 32px;
  height: 32px;
  background-color: $blue-light;
  padding: 9px;
  border-radius: 16px;
  color: #fff !important;
}

.ant-upload {
  border-radius: 10px !important;
  background-color: $background-light !important;
}

.form-edit {
  margin: 10px 0;
  margin-bottom: 0;
  border: none;
  display: flex;
  flex-direction: column;

  .form-select {
    .ant-select-selector {
      background-color: $background-light !important;
    }
  }

  .form-time-select {
    background-color: $background-light !important;
    height: 48px !important;
  }

  &__avatar {
    display: flex;
    justify-content: center;
    padding: 18px;
  }

  &__rows-container {
    display: flex;
    justify-content: space-around;
  }

  &__left-rows {
    width: 100%;
  }
  &__right-rows {
    width: 100%;
    margin-left: 17px;
  }

  &__container {
    padding: 0 30px;

    @include adaptive-value('padding-left', 30, 10, 1);
    @include adaptive-value('padding-right', 30, 10, 1);
    .input-field-other {
      &__tags {
        background-color: $background-light;
        border-radius: 10px;
      }

      &__label {
        @extend %label_text;
        padding: 0 2px 8px 0;
        background-color: $background-light;
      }
    }
    background-color: $background-light;
    border-top: 0.5px solid $border;
    border-bottom: 0.5px solid $border;
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &__row {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 8px 0;
      border-radius: 10px;
      .input-field-other {
        width: 100%;
      }
    }

    .upload-text {
      @extend %upload-text;
    }

    &__column {
      width: 48%;
      display: flex;
      flex-direction: column;
    }

    &__title {
      @extend %sub_title;
    }

    .input-field {
      width: 100%;
      &:focus-within .input-field__label:not(.input-field__label_error) {
        color: $blue-light2;
      }
      &__label {
        background-color: $background-light;
        border-radius: 15px;
      }
      &__input {
        background-color: $background-light !important;

        .ant-select-selector {
          background-color: $background-light !important;
        }
      }
    }

    .ant-input {
      border-radius: 10px;
      padding: 10px 20px 0;
      resize: none;
      background-color: $background-light;
    }
  }
  .buttons {
    padding: 0 30px;

    @include adaptive-value('padding-left', 30, 10, 1);
    @include adaptive-value('padding-right', 30, 10, 1);
  }
}

.answer-edit {
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid $grey2;
  border-radius: 15px;

  &__title {
    @extend %label;
  }

  &__type {
    @extend %text-label;

    textarea,
    textarea:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }
}

.form-edit-therapist {
  display: flex;
  flex-direction: row;
  border-top: 0.5px solid $border;
  width: 100%;
  justify-content: space-between;
  padding: 0;
  margin-bottom: 24px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    margin: 0;
  }

  &__avatar-bio {
    border-right: 0.5px solid $border;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    width: 62%;
    padding: 0 30px;

    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }

  &__avatar {
    padding: 0;
    padding-top: 10px;
    margin-bottom: 69px;
  }

  &__avatar-top {
    background: rgba(226, 226, 234, 0.5);
    height: 83.89px;
    border-radius: 10px;
    width: 100%;
  }

  &__rows-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 25px 30px 0;
    @media screen and (max-width: 600px) {
      padding: 5px 30px;
    }
  }

  &__btn-container {
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 600px) {
      background-color: $white;
      justify-content: center;
      padding: 30px 0 0;
      .cancel-button {
        padding: 0 !important;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    padding: 0 30px;

    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .edit-photo-container {
    img {
      border-radius: 10px;
    }
  }

  .ant-input {
    height: 115px;
    border-radius: 10px;
    background: $background-light;
    border: 1px solid $border;
  }

  .upload-container {
    position: absolute;
    margin-top: 25px;
    margin-bottom: 69px;
  }

  .input-field {
    @media screen and (max-width: 600px) {
      margin-bottom: 5px;
    }
    input {
      background-color: $background-light;
    }
  }
}
