@import '../../../../assets/texts/account_text.scss';
@import '../../../../assets/texts/session_item_text.scss';
@import '../../../../assets/texts/modal_change_group.scss';
@import '../../../../assets/colors.scss';

.groups-list {
  padding: 7px 38px;

  &__label {
    margin: 10px 0;

    @extend %prev_title;
  }

  &__items {
    height: 100%;
    max-height: 370px;
    overflow: auto;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
      max-height: 100%;
      gap: 5px;
    }
  }
}

.group-item {
  margin-bottom: 24px;
  padding: 20px;
  margin-right: 20px;
  display: flex;
  border-radius: 23px;
  border: 1px solid #e2e2ea;
  width: 100%;

  @media screen and (max-width: 500px) {
    flex-flow: column;
    margin: 0;
  }

  &__avatar {
    max-width: 120px;
    height: 120px;
    border: 1px solid #e2e2ea;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

    @extend %therapist-avatar;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 10px;
      object-fit: cover;
      object-position: center;
    }

    @media screen and (max-width: 920px) {
      max-width: 80px;
      height: 80px;
    }

    @media screen and (max-width: 500px) {
      margin-bottom: 16px;
    }
  }

  &__avatar-txt {
    font-size: 50px;
    width: 100%;
    height: 100%;
    background: $blue-light;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 920px) {
      font-size: 38px;
    }
  }

  &__avatar-img {
    width: 52px;
    height: 52px;
    border-radius: 10px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__body {
    margin: 0 20px;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 500px) {
      flex-flow: column;
      margin: 0;
    }
  }

  &__title {
    @extend %title;
  }

  &__item {
    display: flex;
  }

  &__label {
    @extend %label;
  }

  &__value {
    margin: 0 5px;

    @extend %value;
  }

  &__block {
    display: flex;
  }

  &__column {
    display: flex;
    flex-direction: column;
  }

  &__column-end {
    width: 40%;
    align-items: flex-end;
    justify-content: flex-end;
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .members-mini-list {
      max-width: 70%;
    }
  }

  &__begin {
    margin: 10px 0;
    padding: 0 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: rgba(255, 151, 74, 0.1);
  }

  &__begin-text {
    @extend %begin;
  }

  .btn-item {
    width: 150px;
  }
}
