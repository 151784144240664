.layout-account {
  margin: 0 auto;
  max-width: 536px;
  display: flex;
  flex-direction: column;

  .text-page-header {
    padding-bottom: 24px;
  }

  .box-account-container {
    min-height: auto;
    max-height: 100%;
    padding: 0;

    .ant-spin-nested-loading {
      min-height: auto;
    }
  }

  &__therapist {
    max-width: 100%;
  }
}
