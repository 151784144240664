@import './colors';

.form-dropdown {
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: url(../assets/image/questions/choice.png) no-repeat right 15px center, #edf7ff !important;
    color: $blue-light !important;
    font-weight: 400;
    transition: none;
  }

  .anticon-check {
    display: none;
  }
}

body {
  .ant-select-dropdown {
    border: 1px solid #f1f1f5;
    border-radius: 15px;
    padding: 11px 5px 11px 12px;
    box-shadow: 0 11px 25px rgba(10, 30, 135, 0.03);
  }

  .rc-virtual-list-holder {
    margin: 0 -12px !important;
  }

  .ant-select-item-option:not(.ant-select-item-option-selected) {
    color: $grey3 !important;
  }

  .ant-picker-datetime-panel {
    flex-wrap: wrap;
  }
}
