.sort-block-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: 1px solid #e2e2e9;
  border-right: 1px solid #e2e2e9;

  @media screen and (max-width: 991px) {
    width: 100%;
    border: none;
  }

  &__container {
    padding: 8px 14px;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 1200px) {
      flex-wrap: wrap;
    }

    @media screen and (max-width: 991px) {
      padding: 0;
      justify-content: flex-start;
    }
  }

  button {
    margin: 10px;

    @media screen and (max-width: 991px) {
      margin: 10px 0;
    }
  }
}
