@import '../../../../assets/texts/filter_modal_text.scss';

.filter-box {
  display: flex;

  &__title {
    margin-top: 25px;
    margin-left: 30px;
    max-width: 160px;

    @extend %box_title;

    &.sm {
      max-width: 170px;
      font-size: 16px;
    }

    @media screen and (max-width: 768px) {
      max-width: 100%;

      &.sm {
        max-width: 100%;
      }
    }
  }

  &__wrap {
    margin: 25px 30px;
    width: 440px;

    @media screen and (max-width: 768px) {
      width: auto;
    }

    .form-select .ant-select-selector {
      background-color: #f5f6fa80 !important;
    }
  }

  &__column {
    flex: 1 3;
    max-height: 184px;

    @media screen and (max-width: 768px) {
      max-height: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  .ant-checkbox-inner::after {
    top: 40%;
  }
}
