@import '../../../../assets/texts/change_group.scss';

.select-groups {
  min-height: 220px;

  &__items {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;

    &.no-wrap {
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
  }

  &__btn_last {
    cursor: pointer;
    z-index: 1;
  }

  &__title {
    text-align: center;
    padding-bottom: 12px;

    @extend %main-title;
  }

  &__before {
    margin: 48px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;

    @extend %before;

    a {
      @extend %link;

      text-decoration: underline;
    }
  }

  &__wrapper {
    position: relative;

    &_prev {
      @media screen and (min-width: 500px) {
        padding-left: 70px;
      }
    }
  }

  .slick-slider {
    width: 100%;
    max-width: 96vw;
    display: flex;
    align-items: center;

    .slick-slide {
      margin: 0 30px 0 0;
    }

    .slick-list {
      flex: 1 1 auto;
      margin-right: 12px;
    }

    .slick-prev,
    .slick-next {
      position: relative;
      left: 0;
      right: 0;
    }

    .slick-next {
      flex: 0 0 30px;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      border: 1px solid rgba(#e2e2e9, 0.8);
      background: #fff url(../../../../assets/image/slider/next.svg) no-repeat 55% center;
    }

    .slick-prev:hover,
    .slick-next:hover {
      box-shadow: 0 0 15px 0 $grey;
      transition: box-shadow 200ms;
    }

    .slick-prev::before,
    .slick-next::before {
      content: '';
    }

    .slick-prev {
      display: none !important;
    }

    .select-groups-item {
      margin: 30px 0 0;
    }

    @media screen and (max-width: 768px) {
      .slick-prev,
      .slick-next {
        display: none !important;
      }
    }

    @media screen and (max-width: 500px) {
      .slick-slide {
        margin: 0;
      }
    }
  }

  &__btn {
    position: absolute;
    top: 215px;
    left: 15px;
    flex: 0 0 56px;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 1px solid rgba(#e2e2e9, 0.8);
    background: #fff url(../../../../assets/image/slider/next.svg) no-repeat 53% center;
    transform: rotate(180deg);

    &_first {
      left: -186px;

      @media screen and (max-width: 991px) {
        left: -136px;
      }
    }

    &_last {
      left: 0;
    }
  }

  &__small-btn {
    position: relative;
    padding-bottom: 15px;
    @extend %before;

    padding-left: 30px;

    &::before {
      content: url(../../../../assets/image/slider/next.svg);
      position: absolute;
      top: 2px;
      left: 15px;
      transform: rotate(180deg);
    }

    &_first {
      top: -15px;
      padding-bottom: 0;
    }
  }

  &__three {
    @media screen and (min-width: 1280px) {
      min-width: 1280px;
    }

    .slick-slider {
      .slick-prev {
        display: block !important;
        margin-right: 12px;
        flex: 0 0 50px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px solid rgba(#e2e2e9, 0.8);
        background: #fff url(../../../../assets/image/slider/next.svg) no-repeat 55% center;
        transform: rotate(180deg) translateY(50%);
      }

      .slick-next {
        flex: 0 0 50px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px solid rgba(#e2e2e9, 0.8);
        background: #fff url(../../../../assets/image/slider/next.svg) no-repeat 55% center;
      }

      @media screen and (max-width: 768px) {
        .slick-prev,
        .slick-next {
          display: none !important;
        }
      }
    }
  }
}

.select-groups-item {
  // flex: 0 0 33.333%; TODO check on slider
  padding: 30px 30px 23px;
  margin: 30px 15px 0;
  max-width: 375px;
  min-height: 430px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 15px;

  @media screen and (max-width: 1360px) and (min-width: 1280px) {
    margin: 30px 10px 0;
    padding: 30px 10px 23px;
    max-width: 355px;
  }

  &__three {
    max-width: 398px;

    @media screen and (max-width: 1360px) and (min-width: 1280px) {
      max-width: 375px;
    }
  }

  &.bordered {
    border: 1px solid #e2e2e9;
  }

  @media screen and (max-width: 768px) {
    margin: 4px;
  }

  @media screen and (max-width: 400px) {
    padding: 30px 10px 23px;
  }

  &__not-found {
    text-align: center;
    font-weight: 600;
    font-size: 24px;
    color: $blue-light;
  }

  &__more {
    cursor: pointer;
    font-size: 14px;
    width: 100%;
    display: flex;
    justify-content: center;
    color: #1891e5;
    font-weight: 500;
    padding-top: 22px;
    border: 1px solid transparent;
  }

  &__buttons {
    width: 100%;
    padding-top: 28px;
    align-self: flex-end;
  }

  .btn-form {
    min-height: 40px;
    font-size: 14px;
  }

  .card-group {
    max-width: 100%;
    &__info {
      width: 231px;

      @media screen and (max-width: 1360px) and (min-width: 1280px) {
        width: auto;
      }

      @media screen and (max-width: 768px) {
        width: auto;
      }
    }
    &__avatar {
      min-width: 90px;
      height: 90px;
    }

    &__title {
      font-size: 22px;
    }

    &__text {
      font-size: 14px;
      letter-spacing: normal;
    }

    &__name-text {
      font-size: 14px;
      letter-spacing: normal;
    }

    &__tags-container {
      padding-top: 24px;
      margin-bottom: 3px;
    }

    &__body {
      padding-bottom: 26px;
    }

    &__about {
      margin-top: 0;
    }
  }
}
