@import '../../../assets/mixin.scss';

.table-container {
  position: relative;
  max-height: 810px;
  margin: 15px 0 0;
  padding: 24px 24px 16px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background: #fff;
  flex-shrink: 0;
  overflow: hidden;
  overflow-x: auto;

  //@include scrollbars(2px, #989898);

  @media screen and (max-width: 768px) {
    margin: 5px;
    padding: 5px;
  }
}
