@import "../../../../../assets/texts";
@import "../../../../../assets/colors";
@import "../../../../../assets/mixin";

.payment {
  @extend %family_poppins;

  font-size: 16px;
  letter-spacing: 0.01em;
  line-height: 26 / 16 * 100%;
  color: $black-light;
  border-radius: 10px;
  background: $background-light;
  padding: 30px;

  @include adaptive-value('padding', 30, 14, 1);

  &_light {
    background: $white;
    border: 1px solid $border;
    padding-bottom: 5px;
  }

  &__title {
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0.01em;
    line-height: 1;
    margin-bottom: 16px;
    color: $black;

    @include adaptive-value('font-size', 24, 20, 1);
  }

  &__summary-list {
    padding-bottom: 14px;
    border-bottom: 1px solid #d5d5db;
  }

  &__savigns {
    margin-top: 14px;
    color: $blue-light;
  }

  &__total {
    margin-top: 4px;
  }

  &__promo {
    margin-top: 15px;
  }

  &__confirm {
margin-top: 30px;

    @include adaptive-value('margin-top', 30, 15, 1);
  }
}

.item-payment {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    font-size: 14px;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &__sum {
    white-space: nowrap;
  }

  &.saving {
    font-weight: 500;

    span {
      white-space: nowrap;
    }
  }

  &.total {
    font-weight: 600;
    font-size: 20px;

    @include adaptive-value('font-size', 20, 16, 1);
  }
}

.promo {
  @extend %family_roboto;

  &__question {
    font-weight: 600;
    font-size: 14px;
    line-height: 20 / 14 * 100%;
    text-align: left;
    color: $blue-light;
  }

  &__form {
    display: flex;
    align-items: center;
    margin-top: 7px;

    @media screen and (max-width: 480px) {
      flex-wrap: wrap;
    }
  }

  &__input {
    flex: 1 1 auto;
    font-size: 14px;
    letter-spacing: 0.01em;
    text-align: left;
    color: $black-light;
    padding: 10px 45px 10px 20px;
    margin-right: 14px;
    border-radius: 10px;
    border: 1px solid $grey2;
    //background: $white url("../../../../../assets/image/common/checkBlackBlue.svg") 96% center no-repeat;
    outline: none;
    transition: all 0.3s ease;

    @media screen and (max-width: 1024px) {
      margin-right: 8px;
    }

    @media screen and (max-width: 480px) {
      flex: 1 1 100%;
      margin-bottom: 10px;
      margin-right: 0;
      padding: 8px 45px 8px 10px;
    }

    &::placeholder {
      color: $black-light;
      opacity: 0.5;
      transition: all 0.3s ease;
    }

    &:active,
    &:focus-visible {
      border-color: $blue-light;

      &::placeholder {
        color: transparent;
      }
    }

    &:hover {
      border-color: $blue-light;
    }
  }

  &__btn {
    flex: 0 0 30%;
    font-weight: 500;
    border-radius: 10px;
    transition: all 0.3s ease;

    @media screen and (max-width: 1024px) {
      flex: 0 1 40%;
    }

    @media screen and (max-width: 480px) {
      flex: 1 1 100%;
      min-height: 40px !important;
    }

    &[disabled] {
      background: #92929d;
    }

    &:hover {
      background: darken($blue-light, 10%);
    }
  }

  &__applied {
    font-size: 14px;
    letter-spacing: 0.01em;
    line-height: 20 /  14 * 100%;
    color: $blue-light;
    margin-top: 10px;
  }
}

.confirm-billing {
  max-width: 460px !important;

  .ant-modal-content {
    border-radius: 15px;
  }

  &__body {
    text-align: center;
    padding-top: 25px;
  }

  &__title {
    @extend %family_poppins;

    max-width: 300px;
    margin: 30px auto 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 28 / 24 * 100%;
    color: $blue-light;
  }

  &__sub-title {
    @extend %family_roboto;

    font-size: 14px;
    letter-spacing: 0.01em;
    color: $black;
    max-width: 350px;
    margin: 20px auto 0;
  }

  &__btn {
    text-transform: uppercase;
    font-weight: 500;
    margin-top: 40px;
  }
}
