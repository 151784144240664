@import '../../../../assets/texts/account_text.scss';

.subject-block {
  padding: 0 30px;
  margin-bottom: 4px;

  @media screen and (max-width: 768px) {
    padding: 0 5px;
  }

  &__prefix {
    padding-left: 20px;

    @extend %edit_title;
  }

  &__input {
    outline: none !important;
    border: 1px solid #e2e2ea !important;
    border-radius: 10px !important;
    height: 48px;
    width: 100%;
    padding: 0 20px;
    margin-top: 10px !important;

    &:focus {
      border: 1px solid $blue-light2 !important;
    }

    @extend %value_profile;

    &.errors {
      border: 1px solid $red !important;
    }
  }
}

.support-message-textarea {
  margin: 10px 0 0 !important;
  max-height: 280px !important;
  border: 1px solid #e2e2ea !important;
  outline: none !important;
  resize: none !important;
  border-radius: 10px !important;
  padding: 16px 20px !important;

  &:focus {
    border: 1px solid $blue-light2 !important;
    box-shadow: none !important;
  }

  &.errors {
    border: 1px solid $red !important;
  }
}

.subject-button {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 24px 30px;
  background-color: $white;
  border-top: 0.5px solid #e6e7e9;

  @media screen and (max-width: 768px) {
    padding: 24px 10px;
  }

  .btn-form {
    max-width: 230px;
    margin-bottom: 16px;

    &__reset {
      background-color: $white;
      color: $blue-light;
      border: 1px solid #e2e2e9;
    }

    @media screen and (max-width: 810px) {
      margin: 0 8px;
    }
  }
}
