@import '../../../../assets/texts/members_text.scss';
@import '../../../../assets/colors.scss';
@import '../../../../assets/texts';
@import '../../../../assets/mixin';

.members-block {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  border: 1px solid #e2e2ea;

  &__header {
    padding: 17px 17px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    @extend %title;
  }

  &__show {
    cursor: pointer;

    @extend %show;

    span {
      @extend %family_poppins;
      margin-left: 8px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  &__body {
    padding: 0 17px 24px;
    display: flex;
    flex-wrap: wrap;
  }

  &__body:empty {
    padding: 0;
  }

  &__member {
    margin: 5px 9px 5px 0;
    height: 42px;
    width: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    @extend %member;
  }

  &__dot {
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin: 0 5px 2px;
    background: #d8d8d8;
  }
}

.members-list {
  background-color: $background-light;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0 25px;
    border-bottom: 0.5px solid $border;
    background-color: $white !important;
  }

  &__title {
    @extend %modal_title;
  }

  &__switch {
    cursor: pointer;
    margin: 0 15px;

    @extend %title;

    color: $blue;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 30px 17px;
    background: $background-light;

    @media screen and (max-width: 768px) {
      padding-left: 15px;
      padding-right: 18px;
    }
  }

  &__item-user {
    display: flex;
    align-items: center;
    cursor: pointer;
    @extend %modal_text;
  }

  &__member {
    margin-right: 23px;
    height: 42px;
    width: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    @extend %member;
  }

  &__modal {
    max-height: 510px;
    overflow: auto;
  }
}

.members-list::-webkit-scrollbar {
  display: none;
}

.members-mini-list {
  display: inline-flex;
  flex-direction: row-reverse;
  align-items: center;
  cursor: pointer;

  &__total {
    @extend %family_roboto;

    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.01em;
    text-align: left;
    color: $black-light;
    margin: 0 0 0 10px;
  }

  &__title {
    @extend %title;
  }

  &__item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 4px solid $white;
    height: 33px;
    width: 33px;
    border-radius: 50%;
    margin: 0 -5px;

    @extend %member;
    font-size: 12px;
  }
}

.search-members {
  &__container {
    background-color: $background-light;
    padding: 20px 31px 8px;
    border-bottom: 1px solid $border;

    @media screen and (max-width: 768px) {
      padding: 20px 15px 8px;
    }
  }

  .input-field {
    &__label {
      background-color: $background-light;
      border-radius: 10px;
    }
    &__input {
      input {
        background-color: $background-light;
      }
    }
  }

  &__result {
    max-height: 340px;
    overflow: auto;
  }
}

.members-modal {
  width: 100% !important;
  max-width: 560px !important;

  .ant-modal-content {
    border-radius: 15px;
    overflow: hidden;
  }

  .ant-modal-body {
    padding: 0;
  }

  &__header {
    @extend %family_poppins;

    font-weight: 500;
    font-size: 18px;
    line-height: 26 / 18 * 100%;
    text-align: center;
    color: $black;
    padding: 35px 0 30px;
    border-bottom: 1px solid $border;

    @include adaptive-value('padding-top', 35, 14, 1);
    @include adaptive-value('padding-bottom', 30, 10, 1);
  }

  &__list {
    padding: 24px 30px;
    background: $background-light;
    max-height: 510px;
    overflow: auto;

    @include adaptive-value('max-height', 510, 400, 1);
    @include adaptive-value('padding-top', 24, 18, 1);
    @include adaptive-value('padding-left', 30, 14, 1);
    @include adaptive-value('padding-right', 30, 14, 1);
    @include adaptive-value('padding-bottom', 24, 18, 1);
  }

  &__item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      padding-bottom: 17px;
    }
  }

  &__avatar {
    @extend %family_poppins;

    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 42px;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: black;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: $white;
  }

  &__name {
    @extend %family_poppins;

    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.01em;
    padding-left: 23px;
    color: $black-light;
    cursor: pointer;
  }
}

@supports (-webkit-touch-callout: none) {
  .search-members__result {
    overflow: scroll;
  }
}
