@import '../../../../../assets/texts/modal_change_group.scss';
@import '../../../../../assets/colors.scss';
@import '../../../../../assets/texts/edit_modal_form.scss';

.ant-select-clear {
  border-radius: 10px;
}

.ant-modal-wrap {
  // padding: 0px !important;
}

.edit-modal {
  width: 100% !important;
  max-width: 760px !important;

  @media screen and (max-width: 768px) {
    width: auto;
    max-width: 100% !important;
  }

  .ant-modal-content {
    border-radius: 15px;
    width: 760px;
    @media screen and (max-width: 768px) {
      width: auto !important;
      max-width: 100%;
    }
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-spin-nested-loading {
    min-height: 340px;
  }

  .ant-modal-close-x {
    // margin: 20px 24px;
    // border: 1px solid $grey2;
    // border-radius: 10px;
  }
}

.edit-therapist-modal {
  max-width: 560px !important;

  .ant-modal-content {
    width: 560px;
    height: 750px;
    @media screen and (max-width: 768px) {
      width: auto !important;
      max-width: 100%;
    }
    @media screen and (max-width: 920px) {
      width: auto !important;
    }
  }

  .ant-upload-picture-card-wrapper {
    display: flex;
    justify-content: center;
  }
}

.buttons {
  padding: 0 30px;
}

.edit-container {
  display: flex;
  flex-direction: column;

  &__title {
    @extend %item;

    display: flex;
    justify-content: center;
    padding: 30px 0 21px;
  }

  &__header {
    @extend %header_text;

    text-align: center;
    padding: 32px 0 22px;
  }

  &__btn-light {
    margin: 24px 0;
    border: none !important;
  }

  &__therapist {
  }
}

.edit-group-modal {
  width: 560px !important;

  @media screen and (max-width: 768px) {
    width: auto !important;
  }

  .ant-modal-body {
    padding: 0 !important;
  }

  .ant-modal-content {
    min-height: 340px;
    width: 560px;
    border-radius: 15px;
    @media screen and (max-width: 768px) {
      width: auto !important;
    }
  }

  .ant-spin-nested-loading {
    min-height: 340px;
  }
}
