@import '../../../../assets/texts/questions_text.scss';
@import '../../../../assets/colors.scss';

.approve-client-modal {
  width: 460px !important;

  @media screen and (max-width: 768px) {
    width: auto !important;
  }

  .ant-modal-body {
    padding: 0 !important;
  }

  .ant-modal-content {
    min-height: 340px;
    width: 560px;
    border-radius: 15px;
    @media screen and (max-width: 768px) {
      width: auto !important;
    }
  }

  .ant-spin-nested-loading {
    min-height: 340px;
  }
}

.approve-client-container {
  display: flex;
  flex-direction: column;

  &__title {
    margin-bottom: 24px;
    padding: 38px 30px 0;
    text-align: center;

    @extend %title;
  }

  &__group-info {
    background-color: $background-light;
    margin-bottom: 24px;
    padding: 35px 0 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .m-client-box {
    background-color: $white;
    max-width: 260px;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    border-radius: 15px;
    border: 1px solid $grey2;

    &__avatar {
      min-width: 54px;
      width: 54px;
      height: 54px;
      margin: 12px 15px 11px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      background-color: $blue-light;

      @extend %archive-client-avatar;
    }

    &__username {
      @extend %archive-client-username;

      font-weight: 500;
    }
  }

  &__block {
    display: flex;
    flex-wrap: wrap;
    margin: 0 30px 24px;
  }

  &__column {
    width: 49%;
    display: flex;
    flex-direction: column;
  }

  &__finish-ico {
    width: 90px;
    margin: 10px 0;
  }
}