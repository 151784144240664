@import '../../../../../assets/colors';
@import '../../../../../assets/mixin';
@import '../../../../../assets/texts';

.stripe-det {
  @media screen and (max-width: 480px) {
    min-height: 650px;
  }

  & > *:not(:last-child) {
    margin-bottom: 24px;
  }

  &__card {
    position: relative;
    perspective: 1000px;
    transform-style: preserve-3d;
    transition: all 0.5s linear, opacity 0.2s linear;
    min-height: 375px;

    &.rotate {
      transform: rotateY(180deg);

      .stripe-det__face {
        opacity: 0;
      }
    }
  }

  &__face {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background: $white;
    transition: all 0.5s linear;
  }

  &__back {
    transform: rotateY(180deg);
    transition: all 0.5s linear;
    backface-visibility: hidden;
    overflow: hidden;

    .customer-card {
      margin-top: 16px;
    }
  }

  .toggle {
    position: relative;
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.01em;
    line-height: 20px;
    text-align: center;
    color: #44444f;
    border-radius: 10px;
    background: $background-light;
    transition: all 0.3s ease;

    &::after {
      content: '';
      position: absolute;
      width: 50%;
      height: 100%;
      left: 0;
      background: $blue-light;
      border-radius: 10px;
      transition: all 0.3s ease;
    }

    div {
      @extend %family_roboto;

      font-size: 16px;
      letter-spacing: 0.01em;
      line-height: 20 / 16 * 100%;
      text-align: center;
      flex: 0 0 50%;
      padding: 9px 5px;
      z-index: 2;
      // cursor: pointer;
      transition: all 0.3s ease;

      &:first-child {
        color: $white;
      }

      &:last-child {
        color: $black-light;
      }

      @media screen and (max-width: 480px) {
        font-size: 14px;
      }
    }

    &.second {
      &::after {
        left: 50%;
        right: 0;
      }

      div {
        &:first-child {
          color: $black-light;
        }

        &:last-child {
          color: $white;
        }
      }
    }
  }

  &__review {
    & > *:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__frequently {
    @extend %family_roboto;

    font-size: 16px;
    letter-spacing: 0.01em;
    color: $blue-light;
    padding-left: 15px;
    margin-top: 15px;

    @media screen and (max-width: 480px) {
      font-size: 14px;
    }
  }
}
