@import '../../../../../assets/texts/questions_text.scss';
@import '../../../../../assets/colors.scss';
@import '../../../../../assets/mixin.scss';

.frequently-modal {
  max-width: 1084px !important;
  width: 100% !important;

  .ant-modal-content {
    border-radius: 15px;
  }

  .ant-modal {
    @media screen and (max-width: 480px) {
      top: 0 !important;
    }
  }

  .ant-modal-body {
    padding: 0 !important;
  }

  .ant-modal-close {
    @media (min-width: 768px) {
      .ant-modal-close {
        top: 10px;
        right: 8px;
      }
    }

    @media (max-width: 480px) {
      top: -2px;
      right: -2px;
      transform: scale(0.8);
    }
  }
}

.frequently {
  &__header {
    @extend %family_poppins;

    font-weight: 600;
    font-size: 18px;
    line-height: 26 / 18 * 100%;
    text-align: center;
    color: $black;
    padding: 24px;
    border-bottom: 1px solid $border;

    @include adaptive-value('padding', 24, 12, 1);
    @include adaptive-value('font-size', 18, 15, 1);
  }

  &__body {
    padding: 50px 15px 30px;
    max-width: 824px;
    margin: 0 auto;

    @include adaptive-value('padding-top', 50, 20, 1);
    @include adaptive-value('padding-bottom', 30, 20, 1);
  }
}

.question {
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 21px 12px 37px;
    border-radius: 10px;
    background: $background-light;
    cursor: pointer;

    @include adaptive-value('padding-left', 37, 14, 1);
  }

  &__title {
    @extend %family_poppins;

    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    color: $black-light;
    padding-right: 15px;

    @include adaptive-value('font-size', 16, 14, 1);
  }

  &__icon {
    flex: 0 0 18px;
    width: 18px;
    height: 18px;
    position: relative;

    span {
      position: absolute;
      width: 100%;
      height: 2px;
      border-radius: 2px;
      background: $blue-light;
      transition: all 0.3s ease;
    }

    span:first-child {
      top: 50%;
      transform: translateY(-50%);
    }

    span:last-child {
      top: 50%;
      transform: rotate(90deg) translateX(-8%);
    }
  }

  &.active &__icon span:last-child {
    transform: rotate(0) translateY(-50%);
  }

  &__body {
    @extend %family_poppins;

    font-size: 14px;
    line-height: 24 / 14 * 100%;
    text-align: left;
    padding: 9px 37px;
    margin-top: 7px;
    color: #6e6e6e;
    border-radius: 15px;
    border: 1px solid $grey2;
    overflow: hidden;

    @include adaptive-value('padding-left', 37, 14, 1);
  }
}
