@import '../../../../assets/texts/previews_text';
@import '../../../../assets/texts/change_group.scss';
@import '../../../../assets/colors';
@import '../../../../assets/mixin';

.restart-success {
  width: 100vw;
  max-width: 560px;
  border-radius: 15px;
  background-color: $white;
  margin-top: 20px;
  padding: 40px 0 50px;

  @include adaptive-value('padding-bottom', 50, 20, 1);

  @media screen and (max-width: 768px) {
    width: calc(100vw - 20px);
    margin: 20px 10px 0;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 30px;
    @include adaptive-value('padding-left', 30, 10, 1);
    @include adaptive-value('padding-right', 30, 10, 1);
  }

  &__title {
    @extend %family_poppins;

    padding: 17px 0 25px;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: $black;
  }

  &__group-container {
    padding: 30px 30px 50px;
    background: rgba($background-light, 0.5);
    border-top: 0.5px solid $border;
    border-bottom: 0.5px solid $border;

    @include adaptive-value('padding-left', 30, 10, 1);
    @include adaptive-value('padding-right', 30, 10, 1);
    @include adaptive-value('padding-top', 30, 20, 1);
    @include adaptive-value('padding-bottom', 50, 20, 1);

    .small-group-card {
      border: 1px solid $border;
      border-radius: 10px;
      background-color: $white;
      padding: 20px;
      width: 100%;

      .begins {
        &__text {
          font-size: 12px;
        }
      }
    }
  }

  &__body-title {
    @extend %label;

    font-weight: normal;
    display: flex;
    align-items: center;
    padding-bottom: 17px;
  }

  &__button-container {
    padding: 43px 30px 3px;

    @include adaptive-value('padding-left', 30, 10, 1);
    @include adaptive-value('padding-right', 30, 10, 1);
    @include adaptive-value('padding-top', 43, 23, 1);
  }
}