@import '../../../../assets/texts/table_text.scss';
@import '../../../../assets/colors.scss';

.group-status-box {
  padding: 5px 0 !important;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  @extend %row_status;

  &__3 {
    background-color: rgba($color: $blue-light, $alpha: 0.16);
    color: $blue-light;
  }

  &__2 {
    background-color: rgba($color: $red2, $alpha: 0.16);
    color: $red2;
  }

  &__1 {
    background-color: rgba($color: $orange, $alpha: 0.16);
    color: $orange;
  }

  &__4 {
    background-color: rgba($color: $red4, $alpha: 0.16);
    color: $red4;
  }

  &__5 {
    background-color: rgba($color: $red4, $alpha: 0.16);
    color: $red4;
  }

  &__6 {
    background-color: rgba($color: $green, $alpha: 0.16);
    color: $green;
  }
}
