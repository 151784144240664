@import './main.scss';
@import '../colors.scss';
@import '../functions.scss';
//@extend %family_roboto;
//@extend %family_poppins;
%main-title {
  @extend %family_poppins;

  font-size: 28px;
  line-height: 36px;
  font-weight: 600;
  letter-spacing: letter-spacing(4.17);
  color: $black;
}

%desc-text {
  @extend %family_poppins;

  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
  letter-spacing: letter-spacing(4.17);
  color: $black;
}

%questin {
  @extend %family_poppins;

  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  color: $black;
}

%sub-questin {
  @extend %family_poppins;

  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: $black;
}

%label {
  @extend %family_poppins;

  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: $black;
}

%answer {
  @extend %family_roboto;

  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: letter-spacing(7.14);
  color: #44444f;
}

%number {
  @extend %family_poppins;

  font-size: 18px;
  line-height: 27px;
  font-weight: 900;
  color: $blue-light2;
}

%before {
  @extend %family_roboto;

  font-size: 18px;
  letter-spacing: letter-spacing(7.14);
}

%link {
  @extend %family_roboto;
  @extend %before;

  font-weight: 600;
}

%group-title {
  @extend %family_poppins;

  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
  letter-spacing: letter-spacing(0.01);
  color: $black;
}

%group-text {
  @extend %family_roboto;

  font-size: 14px;
  line-height: 16px;
  letter-spacing: letter-spacing(6.25);
  color: #3b3b3b;

  span {
    opacity: 0.8;
  }
}

%therapist-text-sm {
  @extend %family_roboto;

  font-size: 14px;
  line-height: 18px;
  letter-spacing: letter-spacing(6.25);
  color: $grey;

  span {
    color: $blue-light2;
  }
}

%group-begin {
  @extend %family_roboto;

  font-size: 13px;
  line-height: 18px;
  letter-spacing: letter-spacing(7.14);
  color: #ff974a;
}
