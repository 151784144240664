@import '../../../assets/colors.scss';

.event-details-container {
  width: 100%;
  display: flex;
  // flex-wrap: wrap;
  flex: 1 1;

  @media screen and (max-width: 1200px) {
    flex-wrap: wrap;
  }

  &__column {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1200px) {
      margin: 0 0 23px;
      width: 100%;
    }
  }

  &__column:last-child {
    margin-left: 23px;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1200px) {
      margin: 0;
    }

    .account-edit-block {
      .account-edit-block__title {
        border-bottom: none;
      }
    }

    .account-details {
      background: rgba(245, 246, 250, 0.5);
    }
  }

  .account-tabs {
    &__item {
      height: 468px;
      background: rgba($background-light, 0.2) !important;
      &-info {
        border-top: 0.5px solid #e6e7e9;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 30px;
        width: unset;
      }

      .members-modal__list {
        background: transparent !important;
        max-height: 410px;
      }
    }
  }

  .box-account-container {
    padding: 0;
  }
}
