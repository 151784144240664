@import '../../../../assets/texts/account_text.scss';
@import '../../../../assets/colors.scss';
@import '../../../../assets/mixin.scss';

.consultation-form {
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  &__title {
    @extend %title;
  }

  &__row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  &__block {
    display: grid;
    grid-template-areas: 'item1 item1' 'item2 item2' 'item3 item3' 'item4 item5' 'item6 item7' 'item8 item9' 'item10 item10';
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 50px;

    @include adaptive-value(grid-column-gap, 50, 8, 1);

    @media screen and (max-width: 460px) {
      grid-template-areas: 'item1 item1' 'item2 item2' 'item3 item3' 'item4 item4' 'item5 item5' 'item6 item6' 'item7 item7' 'item8 item8' 'item9 item9' 'item10 item10';
    }
  }

  &__item {
    margin: 7px 30px 8px 0;
    max-width: 320px;
    display: flex;
    flex-direction: column;

    @include adaptive-value(margin-right, 30, 8, 1);
  }

  &__button {
    margin-top: 30px;
  }

  &__item1 {
    grid-area: item1;
  }

  &__item2 {
    grid-area: item2;
  }

  &__item3 {
    grid-area: item3;
  }

  &__item4 {
    grid-area: item4;
  }

  &__item5 {
    grid-area: item5;
  }

  &__item6 {
    grid-area: item6;
  }

  &__item7 {
    grid-area: item7;
  }

  &__item8 {
    grid-area: item8;
  }

  &__item9 {
    grid-area: item9;
  }

  &__item10 {
    grid-area: item10;
  }

  &__label {
    @extend %label;

    font-size: 12px;
    color: $grey5;
    padding-bottom: 4px;
  }

  &__value {
    @extend %value;

    color: $black;

    &_bg {
      font-weight: 500;
    }
  }

  &__input-value {
    @extend %value;

    color: $black;
    display: flex;

    &_bg {
      font-weight: 500;
    }
  }
}

.view-superbill {
  color: #1891e5;
  font-weight: 500;
  cursor: pointer;
}
