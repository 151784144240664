@import '../../../../assets/colors';
@import '../../../../assets/texts';

.questions {
  @extend %family_roboto;

  .ant-radio-group {
    width: 100%;
  }

  .ant-radio-inner {
    background: transparent;
    border-width: 2px;

    &::after {
      top: 2px;
      left: 2px;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 28 / 18 * 100%;
    text-align: center;
    color: $black;
    margin-bottom: 16px;
  }

  &__sub-title {
    font-size: 16px;
    text-align: center;
    color: $grey9;
    margin-bottom: 50px;

    @media screen and (max-width: 480px) {
      margin-bottom: 30px;
    }
  }

  &__header {
    padding: 20px 27px 0;

    @media screen and (max-width: 480px) {
      padding: 15px 15px 0;
    }
  }

  &__header-top {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 0.5px solid $border;

    &.no-border {
      border-bottom: none;
    }
  }

  &__back {
    cursor: pointer;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 7px;
      height: 12px;
    }
  }

  &__progress {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__question-body {
    min-height: 82px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 14px;
    padding-bottom: 14px;
    font-weight: 500;
    font-size: 18px;
    line-height: 26 / 18 * 100%;
    color: $black;

    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }

  &__top-question {
    font-weight: 500;
    font-size: 18px;
    line-height: 28 / 18 * 100%;
  }

  &__question.bold {
    font-weight: 600;
  }

  &__group-question {
    text-align: center;
    max-width: 550px;
    margin: 0 auto;
  }

  &__group-question &__sub-question {
    padding: 0 40px;
  }

  &__smiles {
    margin-bottom: 30px;

    @media screen and (max-width: 480px) {
      margin-bottom: 10px;
    }
  }

  &__rate-body {
    padding: 16px 0;
    text-align: center;
    margin: 0 auto;
    max-width: 450px;

    @media screen and (max-width: 480px) {
      padding: 0;
    }
  }

  &__single-question {
    @extend %family_roboto;

    font-weight: 500;
    font-size: 18px;
    line-height: 28 / 18 * 100%;
    text-align: center;
    max-width: 400px;
    color: $black;
    margin: 0 auto;
  }

  &__sub-question {
    @extend %family_roboto;

    padding: 0 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22 / 14 * 100%;
    color: $grey;
    margin-top: 13px;

    @media screen and (max-width: 480px) {
      padding: 0;
    }
  }

  &__single-sub {
    @extend %family_roboto;

    font-size: 14px;
    line-height: 26 / 14 * 100%;
    text-align: center;
    color: $black;
    max-width: 300px;
    margin: 21px auto 0;
  }

  &__rating {
    margin-top: 38px;

    @media screen and (max-width: 480px) {
      margin-top: 15px;
    }
  }

  &__apply {
    font-weight: 300;
    font-size: 18px;
    line-height: 28 / 18 * 100%;
    color: $grey;

    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }

  &__body {
    min-height: 210px;
    padding: 20px 25px;
    background: rgba($background-light, 0.5);
    border-top: 0.5px solid $border;
    border-bottom: 0.5px solid $border;

    &.white {
      background: $white;
      padding: 30px 25px 40px;
    }

    @media screen and (max-width: 767px) {
      padding: 14px;
    }

    @media screen and (max-width: 767px) {
      padding: 15px;
      min-height: 150px;
    }

    &_group {
      padding: 20px 0;
    }
  }

  &__footer {
    padding: 24px 30px;
    flex-direction: column;

    &.pad-sm {
      padding: 24px 30px 10px;
    }

    @media screen and (max-width: 767px) {
      padding: 15px 10px;
    }
  }

  &__answer {
    width: 100%;
    line-height: 140%;
    padding: 13px 18px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    &.radio {
      background: $background-light;
    }

    &.selected {
      background: rgba($blue-light2, 0.16);
    }

    &.small {
      padding: 7px 18px;

      &:not(:last-child) {
        margin-bottom: 7px;
      }
    }

    &.other {
      margin-top: 12px;
      margin-bottom: 27px;
    }

    &.other.small {
      margin-top: 7px;
      margin-bottom: 20px;
    }
  }

  &__check-answer {
    display: flex;
    align-items: center;
  }

  &__answer-text {
    padding-left: 20px;
    letter-spacing: 0.01em;
    color: $grey3;
  }

  &__label {
    @extend %family_poppins;

    display: inline-block;
    line-height: 18 / 14 * 100%;
    color: $grey;
    padding-left: 15px;
    margin-bottom: 8px;

    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }

  &__textarea {
    width: 100%;
    min-height: 100px;
    border-radius: 10px;
    background: #fefefe;
    border: 1px solid $grey2;
    resize: vertical;
    max-height: 300px;
    padding: 16px 20px;

    &::placeholder {
      @extend %family_poppins;

      font-size: 14px;
      line-height: 18 / 14 * 100%;
      color: rgba($black, 0.6);
    }

    &[disabled] {
      opacity: 0.5;
    }
  }

  &__finish-body {
    text-align: center;
    margin-bottom: 60px;

    &.mb-sm {
      margin-bottom: 20px;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 30px;
    }
  }

  &__finish-ico {
    margin-top: 10px;
  }

  &__congrad {
    @extend %family_poppins;

    font-weight: 500;
    font-size: 24px;
    line-height: 28 / 24 * 100%;
    color: $blue-light;
    margin-top: 27px;
  }

  &__finish-title {
    @extend %family_roboto;

    font-weight: 500;
    font-size: 18px;
    line-height: 28 / 18 * 100%;
    text-align: center;
    color: $black;
    margin-top: 23px;
  }

  &__finish-text {
    font-size: 16px;
    line-height: 28 / 16 * 100%;
    text-align: center;
    color: $black;
    margin-top: 24px;
  }

  .ant-rate {
    display: flex;
    justify-content: center;
  }

  .ant-rate-star {
    flex: 0 0 38px;
    min-height: 38px;

    &:not(:last-child) {
      margin-right: 9px;
    }
  }

  &__cancel {
    @extend %family_poppins;

    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 600;
    font-size: 14px !important;
    color: $blue-light;
    margin-top: 10px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 10px;
    transition: all 0.3s ease;
    padding: 16px 0;

    &:hover {
      border-color: $blue-light;
    }
  }

  &__cancel-img {
    max-width: 88px;
    height: 88px;
    background: $blue-light;
    filter: drop-shadow(3px -5px 40px rgba(205, 205, 212, 0.1));
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;

    img {
      transform: scaleX(-1);
    }
  }

  &__done {
    margin-top: 50px;
  }
}
