@import '../../../../../assets/texts/info_text.scss';
@import '../../../../../assets/colors.scss';

.client-card {
  width: 404px;
  display: flex;
  flex-direction: column;
  background: $white;
  border-radius: 20px;
  // box-shadow: 0 0 5px $shadow;
  @media screen and (max-width: 1200px) {
    width: 100%;
    max-width: 655px;
  }

  &__avatar {
    margin-bottom: 24px;
    height: 138px;
    display: flex;
    justify-content: center;
    background: url(../../../../../assets/image/info/background.png) no-repeat top center;

    @media screen and (max-width: 1200px) {
      background: none;
    }
  }

  &__avatar-img {
    margin-top: 54px;
    width: 84px;
    height: 84px;
    border: 3px solid $white-grey;
    border-radius: 10px;
    background-color: $white-grey;
  }

  &__avatar-fake {
    margin-top: 54px;
    width: 84px;
    height: 84px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid $white-grey;
    border-radius: 10px;
    background-color: $blue-light;

    @extend %fake-avatar;
  }

  &__item {
    margin: 6px 25px;
  }

  &__title-name {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 25px 10px 35px;
    padding-bottom: 25px;
    text-align: center;
    border-bottom: 1px solid $grey2;

    @extend %name;
  }

  &__label {
    margin: 6px 0;

    @extend %label;
  }

  &__value {
    @extend %value;
  }

  &__block {
    margin: 0 25px 0 25px;

    .card-details {
      border-radius: 15px;
      border: 1px solid $grey2;
      background: $white-grey;
      margin-bottom: 20px;
    }

    .client-card__block {
      margin: 0;
      background: transparent;
      border: unset;
    }

    .btn-form {
      margin-bottom: 5px;
      font-size: 12px;
    }

    .card-details-buttons {
      margin: 0;

      .btn-form {
        &:nth-child(1) {
          background: #fff;
          border: 1px solid #f39b5a;
          color: #ff974a;
        }

        &.showRestartBtn {
          background-color: #1891e5;
          color: #fff;
          border: none;
        }

        &:nth-child(2) {
          background: #fff;
          border: 1px solid #d3452e;
          color: #e2351e;
        }

        &:nth-child(3) {
          background: #fff;
          border: 1px solid #3b91de;
          color: #1891e5;
        }
      }
    }
  }
}

.card-details {
  padding: 16px;
  padding-bottom: 6px;
  min-width: 320px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 500px) {
    padding: 4px;
    min-width: auto;
    width: 100%;
  }

  &__info {
    display: flex;
  }

  &__avatar {
    position: relative;
    margin-top: 4px;
    min-width: 73px;
    width: 73px;
    height: 73px;
    border-radius: 10px;
    background-color: $grey;

    div {
      @extend %fake-avatar;

      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $blue-light;
      border-radius: 10px;
    }
  }

  &__avatar-img {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    left: 0;
    top: 0;
    object-fit: cover;
    object-position: center;
  }

  &__desc {
    margin: 0 15px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 413px) {
      margin: 0 5px;
    }
  }

  &__title {
    cursor: pointer;

    @extend %card_title;

    @media screen and (max-width: 413px) {
      font-size: 13px !important;
    }
  }

  &__text {
    @extend %text;
  }

  &__icon {
    margin-right: 4px;
  }

  &__name,
  &__date {
    margin: 2px 0;
    display: flex;

    @extend %therapist_name;

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }
  }

  &__childrens {
    margin-top: 15px;
  }
}

// card-details-buttons
.card-details-buttons {
  margin: 0 15px;
  display: flex;
  flex-direction: column;

  .btn-form {
    margin: 5px 0;
  }
}
