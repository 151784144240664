@import './main.scss';
@import '../colors.scss';
@import '../functions.scss';
// previews texts
%prev_avatar_name {
  @extend %family_poppins;

  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  letter-spacing: letter-spacing(6.25);
  color: $white;
}

%row_name {
  @extend%prev_avatar_name;

  color: $black;
}

%row_default {
  @extend %row_name;

  font-size: 15px;
  font-weight: 400;
}

%row_status {
  @extend %family_poppins;

  font-size: 10px;
  line-height: 16px;
  font-weight: 600;
}

%row_participant {
  @extend %family_roboto;

  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
}

%row_day {
  @extend %prev_avatar_name;

  font-size: 15px;
  font-weight: 400;
  color: $black;
}

%member-text {
  @extend %family_poppins;

  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #1e1e1e;
}
