@import '../../../assets/colors.scss';
@import '../../../assets/texts/previews_text.scss';
@import '../../../assets/texts/form_btn_text.scss';

.preview-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $background-light;

  &__header {
    z-index: 1;
    padding-top: 122px;
    height: 409px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: url(../../../assets/image/headers/png/preview-header-bg.jpg) no-repeat bottom/contain;
    background-size: cover;
    overflow: hidden;
    position: relative;
    border-radius: 0 0 43px 43px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      background-color: rgba($color: #081931, $alpha: 0.5);
      z-index: -1;
    }

    @media (max-width: 1200px) {
      background-position: left bottom;
    }

    @media screen and (max-width: 768px) {
      padding-top: 24px;
      background: url(../../../assets/image/headers/png/preview-header-min.jpg) no-repeat left bottom/auto;
      border-radius: 0;
    }
  }

  &__after-box {
    z-index: 3;
    margin: -74px 16px 0 16px;
    padding: 16px;
    min-width: 560px;
    min-height: 200px;
    border-radius: 15px;
    background-color: $white;

    @media screen and (max-width: 768px) {
      min-width: 90vw;
      margin-top: -120px;
      padding-bottom: 30px;
    }

    @media screen and (min-width: 768px) {
      padding: 30px;
    }

    &_down {
      margin-top: -133px;

      @media screen and (max-width: 576px) {
        margin-top: -106px;
      }
    }
  }

  &__text-before-box {
    @extend %form_desc_text;

    margin-top: 22px;
    margin-bottom: 23px;
    text-align: center;

    @media (max-width: 576px) {
      margin: 6px 0 39px;
    }

    & :not(:first-child) {
      margin-top: 13px;
    }

    & a {
      text-decoration: underline;
    }
  }
}

// customization
@import '../../../assets/page/consultation.scss';
