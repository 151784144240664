@import './main.scss';
@import '../colors.scss';
@import '../functions.scss';
// previews texts
%preview_title {
  @extend %family_poppins;

  font-size: 56px;
  line-height: 85px;
  font-weight: 400;
  letter-spacing: letter-spacing(4.17);
  color: $white;
}

%preview_description {
  @extend %family_roboto;

  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  letter-spacing: letter-spacing(6.25);
  color: $white;
}

%template_footer {
  @extend %family_poppins;

  font-size: 14px;
  line-height: 21px;
  font-weight: 300;
  letter-spacing: letter-spacing(4.17);
  color: $black-light;
}
