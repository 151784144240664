@import '../../../../../assets/texts/questions_text.scss';
@import '../../../../../assets/colors.scss';
@import '../../../../../assets/mixin.scss';

.archive-group-modal {
  width: 560px !important;

  @media screen and (max-width: 768px) {
    width: auto;
    max-width: 100% !important;
  }

  .ant-modal-content {
    min-height: 464px;
    border-radius: 15px;
  }

  .ant-spin-nested-loading {
    min-height: 340px;
  }

  .ant-modal-body {
    padding: 0 !important;
  }
}

.archive-session-container {
  display: flex;
  flex-direction: column;

  &__title {
    padding: 30px 42px 25px;
    text-align: center;

    @include adaptive-value(padding-left, 42, 10, 1);
    @include adaptive-value(padding-right, 42, 10, 1);

    @extend %title;
  }

  &__main {
    padding: 32px 30px 42px;
    background: rgba($background-light, 0.5);
    border-top: 0.5px solid $border;
    border-bottom: 0.5px solid $border;

    @include adaptive-value(padding-left, 30, 5, 1);
    @include adaptive-value(padding-right, 30, 5, 1);
  }

  &__group-info {
    padding: 20px;
    border: 1px solid $grey2;
    border-radius: 15px;
    background-color: $white;

    .small-group-card {
      &__title {
        padding-bottom: 10px;
      }

      &__therapist {
        & span {
          color: $black-light !important;
        }
      }
    }
  }

  &__block {
    display: flex;
    flex-wrap: wrap;
    padding: 24px 30px;

    @include adaptive-value(padding-left, 30, 5, 1);
    @include adaptive-value(padding-right, 30, 5, 1);
  }

  &__column {
    width: 49%;
    display: flex;
    flex-direction: column;
  }
}

.archive-session-container {
  &__label {
    display: flex;
    padding-bottom: 17px;
  }

  &__date {
    padding-left: 6px;
    line-height: 1rem;

    @extend %reschedule_session_succes;
  }
}
