@import '../../../../assets/colors.scss';
@import '../../../../assets/texts/event_text.scss';

.event-topics {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -12px;
}

.event-topic {
  display: flex;
  align-items: center;
  margin: 0 12px 12px 0;
  padding: 5px 5px 5px 12px;
  background: rgba($blue-light2, 0.2);
  border-radius: 8px;

  &__text {
    margin-right: 15px;
    @extend %topic_value;
  }
}