.account-therapist-container {
  width: 100%;
  display: flex;
  // flex-wrap: wrap;
  flex: 1 1;
  align-items: flex-start;

  @media screen and (max-width: 1200px) {
    flex-wrap: wrap;
    padding: 0 10px;
  }

  &__column {
    display: flex;

    @media screen and (max-width: 1200px) {
      margin: 0;
      width: 100%;
    }
  }

  &__column:last-child {
    width: 100%;

    .account-details {
      padding: 23px 30px 40px;

      @media screen and (max-width: 768px) {
        padding: 4px;
      }
    }
  }

  .box-account-container {
    @media screen and (max-width: 1200px) {
      min-width: 100%;
    }

    min-height: 525px;

    .ant-tabs,
    .ant-tabs-content,
    .groups-list {
      height: 100%;
    }
  }
}
