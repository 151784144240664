@import './main.scss';
@import '../colors.scss';
@import '../functions.scss';

%title {
  @extend %family_poppins;

  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
  // letter-spacing: letter-spacing(6.25);
  color: $black;
}

%box_title {
  @extend %family_poppins;

  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  // letter-spacing: letter-spacing(6.25);
  color: $black;
}

%list {
  @extend %family_poppins;

  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  letter-spacing: letter-spacing(6.25);
  color: $black-light;
}

%button_text {
  @extend %family_poppins;

  font-weight: 600;
  text-decoration: underline;
  font-size: 15px;
  letter-spacing: 0.01em;
  line-height: 26px;
  text-align: left;
  color: $black-light;
}
