%label_text {
  @extend %family_poppins;

  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: $black-light;
}

%header_text {
  @extend %family_roboto;

  font-family: Roboto;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: $black;
}

%upload-text {
  @extend %family_roboto;

  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: $black-light;
}
