@import '../../../assets/colors.scss';

.header-btn-wrap {
  cursor: pointer;
  width: 45px;
  height: 42px;
  display: none;
  justify-content: center;
  align-items: center;
  background: $white;
  border-radius: 10px;
  border: 1px solid $grey2;

  @media (max-width: 768px) {
    display: flex;
  }
}

.main-header {
  padding-right: 70px;
  min-height: 70px;
  display: flex;
  align-items: center;
  background-color: $white;
  box-shadow: 0 12px 25px rgba(10, 30, 135, 0.03);
  z-index: 2;

  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }

  &__logo-container {
    min-width: 250px;
    display: flex;
    padding: 0 22px;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &__main-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__column {
    display: flex;
    flex-direction: row;
  }

  &__account {
    display: flex;
    align-items: center;
  }

  &__notific {
    margin-right: 25px;
    cursor: pointer;

    &.inactive {
      rect {
        display: none;
      }
    }
  }

  .header-btn-wrap {
    margin-right: 16px;
  }
}
