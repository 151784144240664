@import '../../../../assets/colors.scss';
@import '../../../../assets/texts/account_text.scss';
@import '../../../../assets/mixin.scss';

.account-tabs {
  .ant-tabs-tab {
    padding: 0 0 5px;

    @media screen and (max-width: 768px) {
      padding: 20px 4px;
    }

    .ant-tabs-tab-btn {
      @extend %item;
    }
  }

  .ant-tabs-nav-list {
    justify-content: normal !important;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 57px !important;

    @media screen and (max-width: 768px) {
      margin-left: 22px !important;
    }
  }

  .ant-tabs-nav {
    padding: 44px 30px 7px 26px;

    @media screen and (max-width: 768px) {
      padding: 0 5px;
    }
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      @extend %item_active;
    }
  }

  .ant-spin-nested-loading {
    min-height: 0;
  }

  .ant-tabs-ink-bar {
    border-bottom: 2px solid $blue-light;
  }

  .ant-tabs-nav::before {
    border-bottom: none !important;
  }

  @media screen and (min-width: 769px) {
    .ant-tabs-nav-list {
      justify-content: space-between;
      width: 100%;
    }
  }

  &__contacts {
    background: rgba(245, 246, 250, 0.5);
    border-top: 0.5px solid #e6e7e9;

    &.ant-tabs-tab {
      background: transparent !important;
      border-top: none !important;
    }
  }

  &__customer {
    .ant-tabs-tab + .ant-tabs-tab {
      margin-left: 44px !important;

      @media screen and (max-width: 768px) {
        margin-left: 22px !important;
      }
    }
  }
}

.account-details {
  &__items {
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }

  &__dots {
    height: 9px;
    width: 9px;
    background-color: $black;
    border-radius: 50%;

    &:not(:last-child) {
      margin-right: 5px;
    }
  }

  &__label {
    @extend %label;
  }

  &__value {
    @extend %edit_title;

    display: flex;
    align-items: center;
    font-weight: normal !important;
    letter-spacing: 0.01em !important;
  }

  &__edit {
    @extend %btn_edit;

    color: $blue-light;
    font-weight: 500;
    cursor: pointer;
    margin-left: 8px;
  }

  &__number-first {
    @extend %btn_edit;

    color: $blue-light;
    padding-right: 9px;
  }

  &__edit-icon {
    @include adaptive-value(padding-left, 22, 8, 3);

    font-size: 12px;
  }
}

// account-edit panel
.account-edit-container {
  background: rgba(245, 246, 250, 0.5);
  border-top: 0.5px solid #e6e7e9;
  border-bottom: 0.5px solid #e6e7e9;
}

.account-edit-block {
  min-height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px 0;

  @media screen and (max-width: 768px) {
    padding: 20px 5px 0;
  }

  &__title {
    @extend %edit_title;

    width: 100%;
    padding-bottom: 20px;
    border-bottom: 1px solid #f1f1f5;
  }

  &__edit-btn {
    cursor: pointer;
    white-space: pre;

    span {
      margin-left: 8px;
    }

    @extend %item_active;

    color: $blue-light !important;
  }
}

// manage subscription
.manage-subscription {
  display: flex;
  flex-direction: column;

  &__item {
    display: flex;
    width: 100%;
    padding: 20px 0;

    &:not(:last-child) {
      border-bottom: 0.5px solid $border;
    }
  }

  &__title {
    @extend %item;

    color: $black;
    line-height: 18px;
    width: 50%;
  }

  &__text {
    @extend %text_account;

    .anticon {
      margin-right: 7px;
    }

    &_italic {
      font-style: italic;
      font-weight: 300;
    }

    &_bold {
      font-weight: 500;
      color: $black;
    }

    &_light {
      opacity: 0.6;
    }
  }

  &__block {
    padding: 0 28px;
    width: 100%;
    min-height: 150px;
    background: rgba(245, 246, 250, 0.5);
    border-top: 0.5px solid #e6e7e9;
    border-bottom: 0.5px solid #e6e7e9;

    @media screen and (max-width: 768px) {
      padding: 5px;
    }
  }

  &__footer {
    padding: 24px 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .btn-form {
      width: 100%;
      display: flex;
      justify-content: center;
      font-size: 14px;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      &__cancel {
        background: $white;
        color: $red4;
        border: 1px solid $red4;
      }

      &__superbill {
        background: $white;
        color: $blue;
        border: 1px solid $blue;
      }
    }
  }

  &__item-wrap {
    width: 50%;
  }
}

.account-therapist-details {
  background-color: $white !important;
  padding-top: 0 !important;

  @media screen and (max-width: 768px) {
    padding: 0 30px 30px !important;
  }

  @media screen and (max-width: 420px) {
    padding-left: 10px !important;
  }

  .account-edit-block__edit-btn {
    margin: 0 !important;
  }

  &__btn-edit {
    width: 12%;

    @media screen and (max-width: 420px) {
      width: 20%;
    }
  }

  &__password-btn {
    display: flex;
    justify-content: flex-end;
    padding-top: 16px;
  }

  &__title {
    padding-bottom: 16px;

    @media screen and (max-width: 768px) {
      padding-left: 24px !important;
    }

    @media screen and (max-width: 420px) {
      padding-left: 4px !important;
    }
  }

  &__item {
    margin: 0 !important;
    padding: 17px 0;
    border-bottom: 0.5px solid $border;
  }

  &__value {
    width: 30%;

    @media screen and (max-width: 720px) {
      width: 40%;
    }
  }

  &__label {
    width: 40%;

    @media screen and (max-width: 920px) {
      width: 25%;
    }
  }
}

.buttons-container {
  width: 100%;
  display: flex;
  background-color: #f2f4f8;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 24px;

  @media screen and (max-width: 1200px) {
    margin-top: 10px;
  }

  @media screen and (max-width: 420px) {
    padding-right: 0;
  }

  &__delete-button {
    width: 162px;
    height: 38px;
    border-radius: 10px;
    background: #fff;
    border: 1px solid #d3452e;
    margin-right: 5px;

    @extend %delete_button_text;
  }

  &__archive-button {
    width: 162px;
    height: 38px;
    border-radius: 10px;
    background: #fff;
    border: 1px solid #1891e5;

    @extend %archive_button_text;
  }
}
