@import '../../../../assets/texts/main_header_text.scss';
@import '../../../../assets/colors.scss';

.profile-avatar {
  width: 32px;
  height: 32px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
  }
}

.profile-fake-avatar {
  @extend %simple_name_text;
  @extend %family_poppins;

  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  height: 32px;
  border-radius: 10px;
  background-color: $white;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: $blue-light;

  &.active {
    background: $blue-light;
    color: $white;
  }
}
