@import '../../../../assets/texts/account_text.scss';

.contacts-support {
  &__title {
    padding: 22px 30px 24px;

    @extend %form_title;

    @media screen and (max-width: 768px) {
      padding: 22px 5px 24px;
    }
  }
}

.contacts-support-form {
  display: flex;
  flex-direction: column;
  border-radius: 5px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.container-success {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  &__text {
    @extend %text_success;

    padding-bottom: 60px;
    text-align: center;
  }

  &__title {
    @extend %title_success;

    text-align: center;
    padding: 27px 0 24px;
  }
}

.modal-success {
  width: 100%;

  @media screen and (min-width: 768px) {
    max-width: 460px !important;
    margin-left: 250px !important;
  }

  &__minimal {
    @media screen and (min-width: 768px) {
      margin-left: 60px !important;
    }
  }

  .ant-modal-content {
    min-height: 340px;
    border-radius: 15px;
  }

  .ant-spin-nested-loading {
    min-height: 340px;
  }

  .ant-modal-body {
    padding: 50px 43px 24px 38px;
  }
}
