@import './main.scss';
@import '../colors.scss';
@import '../functions.scss';
%item {
  @extend %family_poppins;

  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  letter-spacing: letter-spacing(7.14);
  color: $grey;
}

%item_active {
  @extend %item;

  color: $black !important;
}

%block_title {
  @extend %family_poppins;

  font-size: 23px;
  line-height: 34px;
  font-weight: 600;
  letter-spacing: letter-spacing(6.25);
  color: $black;
}

%fake-avatar {
  @extend %family_poppins;

  font-size: 44px;
  font-weight: 400;
  letter-spacing: letter-spacing(7.14);
  color: $white;
}
