@import '../../../../assets/texts/choice_group_text.scss';
@import '../../../../assets/texts/change_group.scss';
@import '../../../../assets/colors.scss';

.card-group {
  max-width: 480px;
  display: flex;
  flex-direction: column;

  &__title {
    @extend %group_name;

    line-height: inherit;
    padding-bottom: 11px;
    min-height: 55px;
  }

  &__text {
    @extend %group_text;
  }

  &__label {
    @extend %group_label;
  }

  &__about:not(:empty) {
    margin-top: 20px;
  }

  &__about-text {
    overflow: auto;
    overflow-wrap: anywhere;
    @extend %group_desc_text;

    padding-top: 12px;
  }

  &__name {
    margin-top: 10px;
    display: flex;
    min-height: 36px;
  }

  &__name-text {
    @extend %group_therapist_name;
  }

  &__body {
    display: flex;
    flex: 1 1;
  }

  &__avatar {
    display: flex;
    flex-shrink: 1;
    margin-right: 17px;
    min-width: 95px;
    height: 95px;
    background: $grey2;
    border-radius: 10px;
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 10px;
    }

    & div {
      @extend %fake-avatar;

      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $blue-light;
      border-radius: 10px;
    }

    &_edit {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background-color: $blue-light;

      svg {
        height: 10px;
        width: 12px;
      }
    }
  }

  &__date {
    display: flex;
  }

  &__icon {
    font-size: 17px;
    margin-right: 5px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }

  &__tags {
    display: flex;
    column-gap: 12px;
    flex-wrap: wrap;
  }

  &__tag-item {
    margin-top: 12px;
    padding: 5px 12px;
    max-width: 158px;

    @extend %group_desc_text;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 8px;
    background-color: $background-light;
  }

  &__tags-container {
    padding-top: 30px;
    max-width: 480px;
  }

  &__edit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 10px;

    @media screen and (max-width: 768px) {
      flex-wrap: wrap-reverse;
    }
  }

  &__members {
    padding-left: 60px;
  }

  &__tags-container {
    min-height: 82px;
  }
}

.card-therapist {
  &__name {
    min-height: 36px;
  }

  &__info {
    min-height: 140px;
  }
}

// small group card
.small-group-card {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  @media screen and (max-width: 520px) {
    flex-direction: column;
  }

  &__avatar {
    align-self: flex-start;
    height: 120px;
    width: 120px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

    @extend %fake-avatar;

    @media screen and (max-width: 768px) {
      height: 90px;
      width: 90px;
    }

    @media screen and (max-width: 450px) {
      margin-bottom: 16px;
      margin-right: 5px;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
      object-position: center;
    }
  }
  &__avatar-txt {
    font-size: 40px;
    background-color: $blue-light;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    border-radius: 10px;
  }

  &__avatar-txt {
    font-size: 40px;
    background-color: $blue-light;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    border-radius: 10px;
  }

  &__avatar-txt {
    font-size: 40px;
    background-color: $blue-light;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    border-radius: 10px;
  }

  &__content {
    margin: 0 11px;
    display: flex;
    flex-direction: column;
    flex: 1 1;

    @media screen and (max-width: 520px) {
      flex-direction: column;
    }
  }

  &__title {
    @extend %group-title;

    padding-bottom: 7px;
  }

  &__when,
  &__session {
    @extend %group-text;

    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__therapist {
    @extend %therapist-text-sm;
  }

  &__time {
    padding-top: 12px;
  }
}

@supports (-webkit-touch-callout: none) {
  .card-group__about-text {
    overflow: scroll;
  }
}
