@import '../../../../../assets/texts/history-text.scss';
@import '../../../../../assets/colors.scss';

.session-history-list {
  max-height: 400px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  @media screen and (max-width: 768px) {
    max-height: 100%;
  }

  .session-item {
    margin: 16px 0;
    display: flex;

    &__value {
      @extend %history_value;
      color: #3b3b3b;
    }

    &__label {
      @extend %history_value;
    }

    &__footer {
      justify-content: space-between;
    }
  }

  &__collapse {
    margin: 10px 0 !important;
    border-radius: 10px !important;
    padding-right: 20px !important;
    border: none !important;
    background-color: transparent !important;

    .ant-collapse-header {
      padding: 12px 16px;
      @extend %title;

      color: $black !important;
    }

    .ant-collapse-content-box {
      padding: 0 !important;
    }
  }

  &__panel {
    border: none !important;
    border-radius: 10px !important;

    .ant-collapse-header {
      border-radius: 10px !important;
      background-color: #f1f1f5 !important;
    }

    .ant-collapse-content {
      border-top: none !important;
      background-color: transparent !important;
    }
  }
}

.history-container__button-container {
  margin: 28px;
  margin-top: 16px;
  font-weight: 500;
  font-size: 16px;
}
