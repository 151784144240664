@import './main.scss';
@import '../colors.scss';
@import '../functions.scss';
%name {
  @extend %family_poppins;

  font-size: 16px;
  line-height: 25px;
  font-weight: 600;
  letter-spacing: letter-spacing(6.25);
  color: $black;
}

%label {
  @extend %family_roboto;

  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: $grey5;
}

%value {
  @extend %family_roboto;

  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  letter-spacing: letter-spacing(7.14);
  color: $black-light;
}

%text {
  @extend %family_roboto;

  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: letter-spacing(6.25);
  color: $grey;
}

%card_title {
  @extend %family_poppins;

  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  letter-spacing: letter-spacing(7.14);
  color: $black-light;
}

%therapist_name {
  @extend %family_roboto;

  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: letter-spacing(6.25);
  color: $blue-light;
}

%fake-avatar {
  @extend %family_poppins;

  font-size: 38px;
  font-weight: 400;
  letter-spacing: letter-spacing(7.14);
  color: $white;
}
