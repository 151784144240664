@import '../../../assets/texts/table_text.scss';
@import '../../../assets/texts/account_text.scss';
@import '../../../assets/colors.scss';
@import '../../../assets/mixin.scss';

.table-item-box {
  position: absolute;
  height: 48px;
  display: flex;
  align-items: center;

  &__value {
    display: flex;

    @extend %row_default;

    color: $black-light;
  }

  &__value-bold {
    font-weight: 500;
  }

  &__value-row-limit {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  &__day {
    // min-width: 90px;
    margin-left: 17px;
    margin-right: 5px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    @extend %row_day;
  }

  &__day::before {
    content: '';
    position: absolute;
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 7px;
  }

  &__day-blue::before {
    background-color: #50b5ff;
  }

  &__day-green::before {
    background-color: $green;
  }

  &__day-pink::before {
    background-color: #f46899;
  }

  &__day-violet::before {
    background-color: #6c63ff;
  }

  &__day-yellow::before {
    background-color: #ffcd29;
  }

  &__day-ultraviolet::before {
    background-color: #ce00ff;
  }

  &__status {
    padding: 5px 0;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;

    @extend %row_status;
  }

  &__status-1 {
    background-color: rgba($color: $blue-light, $alpha: 0.16);
    color: $blue-light;
  }

  &__status-2, &__status-8 {
    background-color: rgba($color: $red2, $alpha: 0.16);
    color: $red2;
  }

  &__status-3, &__status-9 {
    background-color: rgba($color: $orange, $alpha: 0.16);
    color: $orange;
  }

  &__status-4 {
    background-color: rgba($color: #e5b618, $alpha: 0.16);
    color: #e5b618;
  }

  &__status-5, &__status-6, &__status-7 {
    background-color: rgba($color: $grey3, $alpha: 0.16);
    color: $grey3;
  }

  &__type {
    font-weight: normal;
    @extend %family_roboto;

    &_webinar {
      color: $green;
    }

    &_session {
      color: $red4;
    }
  }

  &__event-therapist {
    @extend %family_roboto;
    color: $black-light;
  }

  &__event-time {
    @extend %family_roboto;
    color: $grey;

    @media screen and (max-width: 975px) {
      font-size: 14px;
    }

    @media screen and (max-width: 675px) {
      font-size: 12px;
    }
  }

  &__participant {
    padding: 5px 0;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: rgba($color: $blue-light, $alpha: 0.16);
    color: $blue-light;

    @extend %row_participant;
  }
}

.table-item-box_name {
  cursor: pointer;
  position: relative;

  @extend %row_name;

  &__value {
    margin-left: 65px;

    @include adaptive-value(margin-left, 65, 55, 1);
  }

  .user-avatar {
    position: absolute;
    top: 0;
  }
}

.table-item-box_licensure {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.table-item-box_info-cart {
  display: flex;

  .table-item-box__value {
    margin-left: 20px;
    position: relative;
  }

  &_list {
    display: none;
    position: absolute;
    top: 30px;
    right: -5px;
    width: 150px;
    max-height: 175px;
    padding: 12px 20px 22px;
    border-radius: 10px;
    background-color: $white;
    box-shadow: 0 10px 30px rgba(10, 30, 135, 0.07);
    overflow: auto;
    z-index: 1;
    cursor: default;

    &_bg {
      width: 220px;
      max-height: 140px;
    }

    @include scrollbars(7px, #989898, transparent);
  }

  &_list-item {
    padding: 8px 0;
    border-bottom: 1px dashed $border;

    &:last-child {
      border-bottom: none;
    }

    @extend %member-text;
  }

  &:hover {
    .table-item-box_info-cart_list {
      display: block;
    }

    .table-item-box__value {
      color: #1891e5;
      transition: color 0.3s ease;
    }
  }
}
