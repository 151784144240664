@import '../../../../assets/texts/questions_text.scss';
@import '../../../../assets/colors.scss';

.symptoms-modal {
  width: auto !important;
}
.anxiety-questions-modal {
  .ant-spin-nested-loading {
    min-height: 665px !important;
  }
}

.anxiety-questions-modal {
  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-content {
    min-width: 320px;
    min-height: 340px;
    border-radius: 15px;
  }

  .ant-spin-spinning {
    width: 320px;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .qustion-container {
    max-width: 460px;
  }

  .qustion-container__answers {
    margin-top: 20px;
  }

  .change-group-container__question {
    text-align: start;
    font-weight: 700;
  }
}
.change-group-container {
  .change-group-container__main-title {
    font-weight: 500;
    color: #3d5581;
  }
  .btn-container {
    display: flex;
    justify-content: center;
    .btn-form-start {
      background-color: #df9d8b;
      padding: 10px 30px;
      width: auto;
      font-size: 20px;
    }
  }
  .change-group-container__desc-text {
    line-height: 25px;
    color: #9197a3;
  }
  .change-group-container__sub-text {
    margin-top: 50px;
    margin-bottom: 20px;
    font-size: 17px;
  }
}
.note-section {
  padding: 24px 30px;
  text-align: center;
  max-width: 560px;
  color: #92929d;
  font-style: italic;
  font-size: 14px;
}

.assessment-tracker-container {
  padding: 24px 30px;
  text-align: center;
  max-width: 560px;
  margin: 0 auto;
  border-radius: 15px;
  background: #f2f8f9;
  transition: all 0.3s ease;

  &__graph {
    height: 120px !important;
    position: relative;

    &-desc1 {
      color: #959aa4;
      position: absolute;
      top: 50px;
      left: -30px;
      font-size: 10px;
    }

    &-desc2 {
      color: #959aa4;
      position: absolute;
      top: -22px;
      left: 45px;
      font-size: 10px;
    }

    &-desc3 {
      color: #959aa4;
      position: absolute;
      top: -22px;
      right: 20px;
      font-size: 10px;
    }

    &-desc4 {
      color: #959aa4;
      position: absolute;
      top: 50px;
      right: -25px;
      font-size: 10px;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 0px;

    svg {
      cursor: pointer;
    }
  }

  &__title {
    @extend %family_roboto;

    flex-grow: 1;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 26 / 18 * 100%;
    color: #9297a2;
  }
}

.progress-questions-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__body {
    max-width: 310px;
  }

  &__title {
    margin: 15px 0;

    @extend %title;

    font-size: 28px;
  }

  p {
    margin: 9px 0;

    @extend %desc;

    font-size: 18px;
  }

  &__btn-check,
  &__btn-init {
    cursor: pointer;
    margin: 0 auto;
    max-width: 88px;
    height: 88px;
    border-radius: 20px;
    border: none;
    outline: none;
  }

  &__btn-init {
    background: url(../../../../assets/image/group/around.png) center no-repeat, #1891e5;
    background-size: 100% 100%;
  }

  .btn-form {
    margin-top: 20px;
  }
}

.details-tracker {
  width: 560px !important;

  @media screen and (max-width: 600px) {
    width: 100% !important;
  }

  .ant-modal-content {
    border-radius: 15px;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-close {
    top: 15px;
    right: 10px;
  }

  &__body {
    padding: 24px 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    @extend %family_poppins;

    padding-top: 0px;
    font-weight: 500;
    font-size: 20px;
    line-height: 36 / 28 * 100%;
    color: $black;
  }

  &__descr {
    @extend %family_roboto;

    font-size: 16px;
    line-height: 28 / 16 * 100%;
    color: #9297a2;
    margin-top: 40px;
  }

  &__btn {
    margin-top: 40px;
    width: 220px !important;
    padding: 20px;
    background-color: #d5a08d !important;

    @media screen and (max-width: 678px) {
      margin-top: 25px;
    }
  }
}

.assessment-start-bottom-text {
  line-height: 25px !important;
}
