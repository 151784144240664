@import '../../../../assets/dropdown.scss';
@import '../../../../assets/mixin.scss';

#root .form-consultation, .form-add-event, .form-edit-event {
  @import '../../../../assets/selects.scss';

  margin-top: 10px;

  &__row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 15px;
  }

  &__column {
    flex: 1 1 50%;
  }

  .input-field-other {
    margin-bottom: 18px;
  }

  .input-field-other_error {
    .ant-select-selector {
      border: 1px solid $red !important;
    }
  }

  .form-select {
    .ant-select-selector {
      padding: 1px 20px;

      .ant-select-selection-overflow {
        overflow-x: scroll;
        overflow-y: hidden;
        flex-wrap: nowrap;
        -ms-overflow-style: none;
        align-items: flex-end;

        &::-webkit-scrollbar {
          display: none;
        }

        .ant-select-selection-search-input {
          height: 24px !important;
        }

        & + .ant-select-selection-placeholder {
          left: 20px;
        }
      }
    }

    &__error .ant-select-selector {
      border-color: $red3 !important;
    }

    .ant-select-item-option {
      color: $grey3;
    }

    .ant-checkbox-inner {
      width: 13px;
      height: 13px;
      background: $white-grey;
      border: 1px solid $grey2 !important;
      border-radius: 3px;

      &::after {
        left: 22%;
        width: 4.5px;
        height: 7.4px;
      }
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      border-color: $blue-light !important;
    }

    .ant-select-item-option-state {
      display: none;
    }

    .ant-select-item {
      min-height: 34px;
      padding: 5px 12px 5px 20px;
    }

    .ant-tag {
      padding: 0;
      margin: 0;
      background: transparent;
      border: none;
    }

    .ant-select-multiple .ant-select-selection-item {
      min-height: 26px;
    }

    &__option {
      display: flex;
      align-items: center;
    }

    &__label {
      margin-left: 12px;
      letter-spacing: 0.01em;
    }
  }
}

.form-country-checker {
  margin: -14px 0 26px 0;
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px;
  color: #1891e5;
  font-weight: 700;
}
