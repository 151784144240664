.search-clients-field {
  width: 100%;
  // width: 900px;

  &__input {
    border: 1px solid #e2e2ea !important;
  }

  .input-field__input {
    input {
      height: 38px;
    }

    input::placeholder {
      color: #92929d !important;
    }
  }

  @media screen and (max-width: 991px) {
    min-width: 220px;
  }

  .input-field__error-container {
    display: none;
  }
}

.search-event-selects {
  grid-template-columns: repeat(4, 1fr) 0.5fr;
  display: grid;
  //grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 16px;
  //display: flex;
  //flex-direction: row;
  padding: 16px 0;

  .form-select .ant-select-selector {
    height: 40px !important;
  }

  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
  }
}
