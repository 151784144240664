@import '../../../../assets/texts/questions_text.scss';

.add-event-modal {
  width: 560px !important;

  @media screen and (max-width: 768px) {
    width: 100% !important;
  }

  .ant-modal-content {
    min-height: 340px;
    width: 560px;
    border-radius: 15px;

    @media screen and (max-width: 768px) {
      width: auto !important;
    }
  }

  .ant-modal-body {
    padding: 0 !important;
  }

  .ant-spin-nested-loading {
    min-height: 340px;
  }
}

.add-event-container {
  display: flex;
  flex-direction: column;

  &__title {
    margin-bottom: 24px;
    margin-top: 30px;
    text-align: center;

    @extend %title;
  }
}
