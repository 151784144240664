@import "../../../assets/mixin";

.progress-block-box {
  padding: 24px 30px;
  border-radius: 15px;
  background-color: #fff;

  @include adaptive-value('padding-left', 30, 15, 1);
  @include adaptive-value('padding-right', 30, 15, 1);
  @include adaptive-value('padding-top', 24, 20, 1);
  @include adaptive-value('padding-bottom', 24, 20, 1);
}
