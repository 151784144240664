@import '../../../../assets/colors';
@import '../../../../assets/texts/stripe_text';

.first-consultation {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid $background-light;
  background: url("../../../../assets/image/common/background-consultation.jpg") bottom/cover no-repeat;
  min-height: 100vh;

  &__logo {
    margin: 24px 0;
  }

  &__container {
    width: 100%;
    padding-top: 24px;
    max-width: 550px;
    min-height: 440px !important;
    background-color: $white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 300px;
  }

  &__congrats-img {
    max-width: 150px;
  }

  &__button {
    width: 100%;
    padding: 30px;
  }

  &__about {
    max-width: 535px;
    padding: 16px 22.5px 0;
    display: flex;

    &__image {
      width: 120px;
      height: 120px;
      border-radius: 10px;
    }

    &__content {
      padding-left: 16px;
    }

    &__title {
      padding-bottom: 8px;
      @extend %item;

      font-size: 18px;
      font-weight: 500;
    }

    &__subtitle {
      padding-bottom: 12px;
      @extend %item;
    }

    &__text {
      @extend %item;

      font-size: 14px;
    }
  }

  &__title {
    @extend %title;
    padding: 15px 0 8px;
  }

  &__subtitle {
    padding-bottom: 8px;
    text-align: center;

    @extend %item;

    font-size: 14px;
  }

  &__form {
    width: 100%;
    max-width: 500px;
    padding: 30px 0;

    @media screen and (max-width: 768px) {
      padding: 30px 10px;
    }
  }

  .input-field {
    margin: 20px 0;
  }

  .input-field__input_error input {
    border: none !important;
  }

  .input-datepicker {
    height: 48px !important;
  }

  &__dropdown-datepicker {
    z-index: 1 !important;
  }
}