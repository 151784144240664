@import './main.scss';
@import '../colors.scss';
@import '../functions.scss';
@import '../mixin.scss';

// previews texts
%block_title {
  @extend %family_poppins;

  font-size: 22px;
  line-height: 27 / 22 * 100%;
  font-weight: 600;
  color: $black;
}

%box_title {
  @extend %family_roboto;

  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  color: $black;
}

%assessments_title {
  @extend %family_poppins;

  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  color: #000;
}

%text {
  @extend %family_roboto;

  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}

%text_bold {
  @extend %family_roboto;

  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}

%item {
  @extend %family_roboto;

  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
}

%page_title {
  @extend %family_poppins;

  font-size: 28px;
  line-height: 34 / 28 * 100%;
  font-weight: 500;
  color: $black;

  @include adaptive-value('font-size', 28, 20, 1);
}

%page_text {
  @extend %family_montserrat;

  font-size: 22px;
  line-height: 21px;
  font-weight: 400;
  color: $grey7;
}

%list_item {
  @extend %family_montserrat;

  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  color: $grey7;
}

%list_head {
  @extend %family_montserrat;

  font-size: 18px;
  line-height: 21px;
  font-weight: 600;
  color: $black;
}

%list_text {
  @extend %family_montserrat;

  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: $black;
}

%list_link {
  @extend %family_montserrat;

  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: $blue-light;
}

%tab {
  @extend %family_montserrat;

  font-size: 22px;
  line-height: 27px;
  font-weight: 600;
  color: #808080;
}

%tab_active {
  @extend %family_montserrat;

  font-size: 22px;
  line-height: 27px;
  font-weight: 600;
  color: $blue-light;
}
