@import "../../../../../assets/colors";
@import "../../../../../assets/mixin";
@import "../../../../../assets/texts";

.customer-card {
  display: flex;
  align-items: center;
  border-radius: 10px;
  background: #f5f6fa;
  padding: 20px;

  @include adaptive-value('padding', 20, 10, 1);

  @media screen and (max-width: 480px) {
    flex-wrap: wrap;
    justify-content: center;
  }

  &__card {
    flex: 0 0 84px;
    min-height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: linear-gradient(to right, #3754b2 10.8%, #142c81 75.02%);

    @media screen and (max-width: 480px) {
      flex: 1 1 100%;
    }
  }

  &__body {
    flex: 1 1 auto;
    padding-left: 23px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: 480px) {
      margin-top: 20px;
      padding: 0 10px;
    }
  }

  &__payments {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__det {
    @extend %family_roboto;

    font-size: 16px;
    letter-spacing: 0.01em;
    line-height: 130%;
    color: $grey;
  }

  &__ending {
    @extend %family_poppins;

    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    color: $black;
  }

  &__expires {
    @extend %family_poppins;

    font-size: 14px;
    line-height: 160%;
    color: $black;
  }

  &__btn {
    @extend %family_poppins;

    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.01em;
    color: $blue-light;
    padding-left: 20px;
    background: url("../../../../../assets/image/common/editBlue.svg") left 4px / 12px 12px no-repeat;
  }
}
