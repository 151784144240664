@import '../../../assets/colors.scss';

.select-tag {
  display: flex;
  align-items: center;
  min-height: 26px;
  border-radius: 8px;
  background: rgba($blue-light2, 0.2);
  padding: 3px 12px;
  margin-right: 12px;

  &__label {
    margin-right: 23px;
    font-size: 12px;
    letter-spacing: 0.01em;
    color: $blue-light;

    @media screen and (max-width: 768px) {
      margin-right: 5px;
    }
  }

  img {
    cursor: pointer;
  }
}
