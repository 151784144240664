@import '../../../../assets/texts/questions_text.scss';

.add-therapits-modal {
  width: 100% !important;
  max-width: 905px !important;
  max-height: 907px;

  @media screen and (max-width: 768px) {
    width: auto !important;
  }

  .ant-modal-content {
    min-height: 907px;
    border-radius: 15px;
  }

  .ant-modal-body {
    padding: 0;
  }

  .upload-container {
    height: 0px;
  }

  .ant-upload-picture-card-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 36px;
  }

  .ant-select-selection-overflow-item-suffix {
    opacity: 0 !important;
  }

  .ant-select-selection-placeholder {
    padding-left: 18px !important;
    left: 0;
    right: 0;
  }

  .form-select {
    .ant-select-selection-placeholder {
      padding-left: 8px !important;
    }
  }

  .ant-spin-nested-loading {
    min-height: 340px;
  }

  .form-time-select {
    background: $background-light !important;
    height: 48px !important;
  }

  .input-field {
    &__label {
      background: $background-light;
      border-radius: 11px;
    }
  }

  .input-field__input,
  .input-field-other__input {
    margin: 8px 0;
    .ant-select-selector {
      background: $background-light !important;
    }
  }

  input {
    background: $background-light !important;
  }
  .ant-picker-input {
    input {
      padding-left: 7px;
      line-height: 1rem;
    }
  }
}

.add-therapist-container {
  display: flex;
  flex-direction: column;
  max-height: 905px;

  &__title {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;

    @extend %title;
  }
}
