.event-details-buttons {
  display: flex;
  //grid-template-columns: 3fr 2fr;
  column-gap: 16px;
  margin-top: 24px;

  @media screen and (max-width: 600px) {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 16px;
  }

  .btn-form {
    @media screen and (max-width: 991px) {
      font-size: 13px;
    }
  }
}

.preview-events-page {
  &__slider {
    display: flex;
  }
}

.slider-item {
  &__header-hardcode {
    padding-top: 0px !important;
  }

  &__bg-hardcode {
    background: url('../../../../assets/image/slider/event-background.svg') !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }
}

.past-events-container {
  &__hardcode {
    display: flex;
  }
}

.past-events {
  &__hardcode {
    min-width: 50%;
  }
}
