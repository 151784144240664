@import '../../../../../assets/selects.scss';

.form-add-therapist {
  display: flex;
  flex-direction: row;
  border-top: 0.5px solid $border;
  width: 100%;
  max-height: 905px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  &__info {
    background-color: $background-light;
    width: 100%;
    padding: 18px 30px 4px;
    border-bottom: 0.5px solid $border;
  }

  &__edit-icon {
    position: absolute;
    margin: 90px;
    margin-left: 163px;
  }

  &__edit-icon-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__container {
    width: 100%;
  }

  &__avatar-bio {
    background-color: $background-light;
    width: 58%;
    border-bottom: 0.5px solid $border;
    border-right: 0.5px solid $border;
    margin-bottom: 144px;
    padding: 30px 30px 0 30px;

    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 0;
    }
  }

  &__avatar-top {
    background: rgba(226, 226, 234, 0.5);
    height: 83.89px;
    border-radius: 10px;
  }

  &__avatar {
    height: 133px;
    margin-bottom: 24px;
  }

  &__personal-info {
    display: flex;
  }

  &__first-name {
    width: 50%;
    margin-right: 20px;
  }

  &__second-name {
    width: 50%;
  }

  .input-field__input,
  .input-field-other__input {
    margin: 8px 0;
  }

  .input-field__label {
    top: 0;
  }

  .input-field__label_error,
  .input-field-other__label_error {
    position: absolute;
    left: 15px;
  }

  &__buttons {
    background-color: $white;

    @media screen and (max-width: 768px) {
      padding: 24px 30px;
    }
  }

  .btn-form {
    margin: 24px 30px;
    width: 500px;

    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 0;
    }
  }

  .btn-light {
    padding-bottom: 24px;
  }

  .input-field_error {
    .ant-select-selector,
    .form-time-select {
      border: 1px solid $red !important;
    }
  }

  .ant-input {
    resize: none;
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid #e1e1e9;
    border-radius: 10px;
    height: 463.84px;
    width: 100%;
    padding-left: 20px;
    padding-top: 8px;
    background-color: $background-light;

    @media screen and (max-width: 768px) {
      height: 200px;
    }
  }

  .form-select__search {
    .ant-select-selection-search {
      width: auto;
      z-index: -1;
    }

    &.ant-select-focused {
      .ant-select-selection-search {
        z-index: 0;
      }
    }
  }
}
