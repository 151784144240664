@import '../../../assets/buttons.scss';

.box-container {
  padding: 30px;
  width: 100%;
  max-width: 539px;
  min-height: 250px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 3px 0 40px rgba($color: #cdcdd4, $alpha: 10%);

  @media screen and (max-width: 768px) {
    width: 95vw;
    padding: 30px 10px;
  }

  .ant-spin-nested-loading {
    min-height: 250px;
  }
}

.btn-form_join-group {
  margin: 30px auto 0 auto;
  width: 100%;
  max-width: 478px;

  @media screen and (max-width: 768px) {
    padding: 0 30px;
  }
}
