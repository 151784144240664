@import '../../../assets/colors.scss';

.share-btn {
  display: flex;
  margin-left: 10px;

  &__icon {
    cursor: pointer;

    &:hover {
      circle {
        fill: darken($blue-light, 5);
        transition: all .3s;
      }
    }

    &.disabled {
      circle {
        fill: $grey2;
        transition: all .3s;
      }
    }
  }
}