@import '../../../assets/colors.scss';
@import '../../../assets/texts.scss';
@import '../../../assets/mixin.scss';

.input-field {
  position: relative;
  display: flex;
  flex-direction: column;

  &:focus-within label:not(.input-field__label_error) {
    color: $blue-light2;
  }

  &__label {
    position: absolute;
    z-index: 1;
    top: -8px;
    left: 15px;
    padding: 0 5px;
    background: $white;
    white-space: nowrap;
    max-width: 90%;

    @extend %field_label;
  }

  &__icon {
    cursor: pointer;
    position: absolute;
    bottom: 50%;
    right: 10px;
    transform: translate(-5px, 10%);

    @media (max-width: 576px) {
      transform: translate(-5px, 25%);
    }
  }

  &__input {
    #practiceYears {
      background-color: $background-light !important;
    }
    input {
      padding: 0 30px 0 20px;
      height: 48px;
      width: 100%;
      border-radius: 10px;
      border: 1px solid $grey6;
      outline: none;

      @extend %field_input;

      &::placeholder {
        color: $grey !important;
      }

      @include safari-only {
        line-height: 42px;
      }
    }

    &:not(.input-field__input_error):focus-within input {
      border-color: $blue-light2;
    }

    input::placeholder {
      line-height: 42px;

      @extend %field_input_placeholder;

      color: $grey2 !important;
    }
    // styles for picker time in Field
    .ant-picker-input {
      input {
        padding: 0;
        height: auto;
        width: 100%;
        border-radius: 0;
        border: none;
        outline: none;
      }
    }
  }

  &__input_error {
    input, textarea {
      border: 1px solid #d3452e !important;
    }
  }

  &__label_error {
    @extend %field_label_error;

    color: #e2351e;
  }

  &__error-container {
    min-height: 20px;
    padding-left: 20px;
    line-height: 1;

    @media (max-width: 576px) {
      min-height: 14px;
    }
  }

  &__error-message {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    color: $red3;
    display: block;
    padding-top: 4px;

    &::first-letter {
      text-transform: uppercase;
    }

    @media (max-width: 576px) {
      padding-top: 0;
    }
  }
}

// other
.input-field-other {
  position: relative;
  display: flex;
  flex-direction: column;

  &__label {
    padding: 0 5px;
    background: $white;

    @extend %field_label;

    color: $black;
  }

  &__label_error {
    @extend %field_label_error;

    color: $red;
  }

  &__icon {
    position: absolute;
    top: 12px;
    right: 10px;
  }

  &__input {
    input {
      padding: 0 30px 0 11px;
      height: 42px;
      width: 100%;
      border-radius: 10px;
      border: 1px solid $grey2;
      outline: none;

      @extend %field_input;

      @include safari-only {
        line-height: 42px;
      }
    }

    input::placeholder,
    .ant-select-selection-placeholder {
      @extend %field_input_placeholder;
    }
    // styles for picker time in Field
    .ant-picker-input {
      input {
        padding: 0;
        height: auto;
        width: 100%;
        border-radius: 0;
        border: none;
        outline: none;
      }
    }
  }

  &__input_error {
    input {
      border: 1px solid $red !important;
    }
  }
}

.input-field,
.input-field-other {
  .ant-input-affix-wrapper {
    padding: 0 30px 0 11px;
    border-radius: 10px;
  }
}

.validation-form {
  .input-field {
    &.input-field_error {
      .ant-select-selector,
      .form-time-select {
        border: 1px solid $red !important;
  
        input {
          border: none !important;
        }
      }
    }

    &__label_error {
      color: $red4 !important;
      font-weight: 400 !important;
    }
  }
  .ant-select-selection-overflow-item-suffix {
    opacity: 0 !important;
  }
}
