@import '../../../../../../assets/texts/info_text.scss';
@import '../../../../../../assets/texts/consultation_text.scss';
@import '../../../../../../assets/colors.scss';

.client-card-mini {
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    padding-bottom: 12px;
  }

  &__avatar {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 10px;
    background-color: $grey;
  }

  &__avatar-img {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    left: 0;
    top: 0;
    object-fit: cover;
    object-position: center;
  }

  &__avatar-fake {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: $blue-light;

    @extend %fake-avatar;

    font-size: 29px;
  }

  &__avatat-group {
    margin-right: 15px;
    margin-top: 3px;
    width: 32px;
    height: 32px;
    border-radius: 5px;
    background-color: $grey;
  }

  &__avatar-img-group {
    width: 32px;
    height: 32px;
    border-radius: 5px;
  }

  &__content {
    margin: 0 24px;
    display: flex;
    flex-direction: column;
  }

  &__block {
    max-width: 321px;
    padding: 11px 16px 14px 16px;
    margin-top: 6px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 15px;
    border: 1px solid #e2e2ea;
    background-color: #fafafb;
  }

  &__title {
    padding-bottom: 6px;

    @extend %block_title;

    font-size: 24px;
  }

  &__label {
    @extend %label;
  }

  &__name {
    @extend %card_title;
  }

  &__date {
    @extend %text;
  }

  &__status {
    display: flex;
    align-items: center;
  }

  &__status-value {
    margin-left: 12px;
    padding: 6px 20px;
    display: flex;
    border-radius: 5px;
    font-size: 10px;
    font-family: 'Poppins', sans-serif;
  }

  &__status-1 {
    background-color: rgba($color: $blue-light, $alpha: 0.16);
    color: $blue-light;
  }

  &__status-2 {
    background-color: rgba($color: $red2, $alpha: 0.16);
    color: $red2;
  }

  &__status-3, &__status-9 {
    background-color: rgba($color: $orange, $alpha: 0.16);
    color: $orange;
  }

  .card-details__date {
    margin: -3px 0 0;
  }
}
