@import '../assets/colors.scss';
@import '../assets/mixin.scss';
@import '../assets/texts/form_select_text.scss';

.form-select {
  cursor: pointer;
  .ant-select-selector {
    border-radius: 10px !important;
    background: #fff !important;
    height: 48px !important;
    border: 1px solid #e2e2ea !important;
    cursor: pointer !important;

    @include safari-only {
      input {
        line-height: 42px;
      }
    }
  }

  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
    color: $grey8;
  }

  .ant-select-selection-item {
    color: $black-light;
  }

  .anticon-close {
    color: #fff;
  }

  .ant-select-selection-search {
    margin-inline-start: 0;
    width: 0;
    z-index: 2;
  }

  .ant-select-selection-search-input {
    height: 40px !important;
  }

  &__search {
    .ant-select-selection-search {
      margin-inline-start: 0;
      width: 100%;
    }
    .ant-select-selection-search-input {
      height: 46px !important;
    }
  }

  .ant-select-arrow {
    svg {
      background: url(../assets/image/forms/arrow.svg) no-repeat center;

      path {
        display: none;
      }
    }
  }

  .ant-select-item-option-selected {
    background: url(../assets/image/questions/choice.png) no-repeat right 25px center, rgba($blue-light2, 0.2) !important;
    color: $blue-light !important;
    transition: none;
  }

  .ant-select-item-option:not(.ant-select-item-option-selected) {
    color: $grey3 !important;
  }

  .rc-virtual-list-scrollbar-thumb {
    width: 6px !important;
    background: $grey2 !important;
  }
}

.form-select.ant-select-multiple {
  .ant-select-selection-item {
    @extend %form_select_text;

    background: rgba($blue-light2, 0.2);
  }
}

.form-time-select {
  width: 100%;
  border-radius: 10px !important;
  background: #fff !important;
  height: 42px !important;
  border: 1px solid #e2e2ea !important;

  @include safari-only {
    input {
      line-height: 42px;
    }
  }

  input::placeholder {
    color: $grey2;
  }
}
