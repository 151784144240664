@import '../../../../assets/texts/questions_text';
@import '../../../../assets/colors';
@import '../../../../assets/mixin';


.add-co-host-modal {
    width: 560px !important;

    @media screen and (max-width: 768px) {
        width: auto;
        max-width: 100% !important;
    }

    .ant-modal-content {
        min-height: 240px;
        border-radius: 15px;
    }

    .ant-spin-nested-loading {
        min-height: 240px;
    }

    .ant-modal-body {
        padding: 0 !important;
    }
}

.add-co-host-container{
    display: flex;
    flex-direction: column;
    width: 100%;

    &__title {
        padding: 30px 42px 25px;
        text-align: center;

        @include adaptive-value(padding-left, 42, 10, 1);
        @include adaptive-value(padding-right, 42, 10, 1);

        @extend %title;
    }
    &__main {
        padding: 24px 30px 42px;
        background: rgba($background-light, 0.5);
        border-top: 0.5px solid $border;
        border-bottom: 0.5px solid $border;
        display: flex;
        justify-content: center;
        flex-direction: column;

        @include adaptive-value(padding-left, 30, 5, 1);
        @include adaptive-value(padding-right, 30, 5, 1);

        .input-field__label {
            background-color: $background-light;
            border-radius: 15px;
        }

        .ant-input {
            border-radius: 10px;
            padding: 10px 20px 0;
            resize: none;
            background-color: $background-light;
        }

        &.photo {
            align-items: center;
        }
    }
    .form-select{
        width: 100%;
    }

    &__buttons {
        display: flex;
        flex-wrap: wrap;
        padding: 24px 30px;
        justify-content: center;

        @include adaptive-value(padding-left, 30, 5, 1);
        @include adaptive-value(padding-right, 30, 5, 1);

        .cancel-button {
            padding-bottom: 0;
        }
    }
}