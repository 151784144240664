@import '../../../../assets/colors';

.goals-template {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &__body {
    flex-grow: 1;
    padding: 50px 15px 30px;
    background: $background-light;

    @media (max-width: 768px) {
      padding: 20px 15px;
    }
  }
}