@import '../../../../assets/texts/questions_text.scss';
@import '../../../../assets/texts/account_text.scss';

.edit-event-modal {
  width: 560px !important;

  @media screen and (max-width: 768px) {
    width: 100% !important;
  }

  .ant-modal-content {
    min-height: 340px;
    width: 560px;
    border-radius: 15px;

    @media screen and (max-width: 768px) {
      width: auto !important;
    }
  }

  .ant-modal-body {
    padding: 0 !important;
  }

  .ant-spin-nested-loading {
    min-height: 340px;
  }
}

.edit-event-container {
  display: flex;
  flex-direction: column;

  &__title {
    margin-bottom: 24px;
    margin-top: 30px;
    text-align: center;

    @extend %title;
  }
}

.edit-event {
  display: flex;
  align-items: center;

  &_icon {
    font-size: 12px;
  }

  &_text {
    @extend %btn_edit;

    color: $blue-light;
    font-weight: 500;
    cursor: pointer;
    margin-left: 8px;
  }
}
