@import '../../../../../assets/texts/history-text.scss';
@import '../../../../../assets/colors.scss';

.history-item {
  max-height: 500px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &__prev-title {
    margin-top: 20px;

    @extend %name;
  }

  &__members {
    display: flex;
    flex-direction: column;
  }

  &__member {
    margin-bottom: 24px;
    display: flex;

    .user-avatar {
      &__prev-avatar,
      &__img-avatar {
        width: 46px;
        height: 46px;
      }
    }
  }

  &__body {
    margin: 0 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__row {
    // margin: 10px 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  &__name {
    @extend %name;
  }

  &__text {
    @extend %text;
  }
}

.session-item-status {
  padding: 4px 13px;

  @extend %status_text;

  color: #fff;
  border-radius: 5px;
  background-color: $grey;
  // status types

  &.cancelled {
    background-color: rgba($color: $red4, $alpha: 0.16);
    color: $red4;
  }

  &.missed {
    background-color: rgba($color: $orange, $alpha: 0.16);
    color: $orange;
  }

  &.attended {
    background-color: $blue-light;
  }
}
