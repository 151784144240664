@import '../../../assets/texts/progress_text.scss';
@import '../../../assets/colors.scss';
@import '../../../assets/mixin';

.progress-term-box {
  position: relative;
  max-width: 1084px;
  margin: 0 auto;
  border-radius: 15px;
  background-color: white;
  min-height: 540px;

  @media (max-width: 480px) {
    min-height: 480px;
  }

  .ant-spin-spinning {
    display: none;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    border-bottom: 1px solid $border;

    @include adaptive-value('padding', 30, 20, 1);
  }

  &__top-title {
    @extend %family_roboto;

    font-weight: 500;
    font-size: 18px;
    line-height: 26 / 18 * 100%;
    text-align: center;
    color: $black;
  }

  &__title {
    margin: 30px 0;
    text-align: center;

    @extend %page_title;
  }

  &__info {
    @extend %family_roboto;

    max-width: 580px;
    font-size: 18px;
    line-height: 28 / 18 * 100%;
    text-align: center;
    color: $grey7;

    @include adaptive-value('font-size', 18, 16, 1);
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;

    @include adaptive-value('padding', 30, 15, 1);
  }

  &__btn-add {
    position: absolute;
    right: 30px;
    bottom: 30px;
    font-size: 20px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $blue-light;
    color: $white;
  }

  &__btn-add:hover {
    cursor: pointer;
  }
}

.term-list {
  max-width: 560px;
  width: 100%;
  border-radius: 15px;
  margin-top: 32px;
  border: 1px solid $grey6;

  @media screen and (max-width: 768px) {
    padding: 5px;
  }

  &__body {
    padding: 24px 30px 10px;

    @include adaptive-value('padding-left', 30, 12, 1);
    @include adaptive-value('padding-right', 30, 12, 1);
    @include adaptive-value('padding-top', 24, 12, 1);
  }

  &__title {
    @extend %family_poppins;

    font-weight: 600;
    font-size: 18px;
    text-align: left;
    color: $black;
    margin-bottom: 20px;
  }

  &__goals-list {
    max-height: 145px;
    overflow-y: auto;
  }

  &__item {

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  &__item-text {
    @extend %family_roboto;

    padding: 0 20px;
    font-size: 16px;
    line-height: 21 / 16 * 100%;
    word-break: break-word;
  }

  &__item-add {
    margin-top: 20px;
  }

  &__input-wrap {
    position: relative;
    display: flex;
    align-items: center;
    flex-grow: 1;
    min-height: 25px;
  }

  &__input-rm {
    min-height: 25px;
    position: relative;
    flex-grow: 1;
    margin-left: 20px;
    border-bottom: 1px solid $grey6;
  }

  &__item-input {
    @extend %family_roboto;

    min-height: 30px;
    font-size: 16px;
    line-height: 21 / 16 * 100%;
    margin-left: 20px;
    padding: 0 5px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid $grey6;
    background-color: #fdfdfd;
    outline: none;
    color: $grey7;

    &_edit {
      @extend %family_roboto;

      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      font-size: 16px;
      line-height: 21 / 16 * 100%;
      padding-right: 20px;
      margin-right: 20px;
      outline: none;
      background: transparent;
      border: none;
      word-break: break-word;
    }
  }

  &__remove-btn {
    position: absolute;
    z-index: 1;
    right: 5px;
    user-select: none;
  }

  &__add {
    @extend %list_item;

    margin-left: auto;
    margin-top: 10px;
    font-weight: 600;
    font-size: 14px;
    text-align: right;
    color: $blue-light;
    cursor: pointer;

    &.disabled {
      color: $grey;
      cursor: not-allowed;
    }
  }

  &__footer {
    padding: 24px 30px 10px;
    border-top: 1px solid $border;

    @include adaptive-value('padding-left', 30, 12, 1);
    @include adaptive-value('padding-right', 30, 12, 1);
    @include adaptive-value('padding-top', 24, 12, 1);
  }

  &__btn {
    margin-top: 5px;
  }
}

.term-ul {
  @include adaptive-value('padding-left', 24, 12, 1);
  @include adaptive-value('padding-right', 24, 12, 1);
  @include adaptive-value('padding-top', 30, 14, 1);

  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 580px;
  padding: 24px 30px;
  margin-top: 30px;
  border-radius: 15px;
  border: 1px solid $grey6;

  ol {
    margin-top: 20px;
    list-style: decimal inside;

    @media screen and (min-width: 768px) {
      max-height: 205px;
      overflow: auto;
    }
  }

  &__title {
    @extend %page_title;

    font-size: 18px;
  }

  &__item {
    @extend %family_roboto;

    font-size: 16px;
    line-height: 21 / 16 * 100%;
    color: $grey7;
    overflow: auto;
    overflow-wrap: anywhere;


    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &.done {
      text-decoration: line-through;
    }

  }

  &__footer {
    margin-top: 10px;
    width: auto;
    display: flex;
    justify-content: flex-end;
  }

  &__btn-edit {
    cursor: pointer;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: black;
  }
}
