@import '../../../../assets/colors.scss';
@import '../../../../assets/texts/slider_text.scss';

.help-full-slider {
  width: 100%;

  .slider-item {
    position: relative;
    width: 250px;
    height: 370px;
    margin: 0 10px;
    border-radius: 10px;
    overflow: hidden;

    &__bg {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(rgba(6, 22, 52, 0) 45%, #000 125%);
      }

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
        object-position: center;
      }
    }

    &__body {
      position: relative;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      padding: 20px 23px;
      z-index: 1;
    }

    &__button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      align-self: center;
      cursor: pointer;
    }

    &__title {
      @extend %slider_title;

      margin-bottom: 12px;
    }

    &__link {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &__text {
      @extend %slider_link;

      max-width: 200px;
      padding-right: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &__link::after {
      cursor: pointer;
    }
  }

  .slick-slider {
    width: 100%;
    display: flex;
    align-items: center;

    .slick-list {
      flex: 1 1 auto;
      margin-right: 12px;
    }

    .slick-prev,
    .slick-next {
      position: relative;
      left: 0;
      right: 0;
    }

    .slick-next {
      min-width: 30px;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      border: 1px solid rgba(#e2e2e9, 0.8);
      background: #fff url(../../../../assets/image/slider/next.svg) no-repeat 55% center;

      @media screen and (max-width: 768px) {
        display: none !important;
      }
    }

    .slick-prev {
      min-width: 30px;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      border: 1px solid rgba(#e2e2e9, 0.8);
      background: #fff url(../../../../assets/image/slider/next.svg) no-repeat 55% center;
      transform: rotateY(180deg) translateY(-50%);
      margin-left: 3px;

      @media screen and (max-width: 768px) {
        display: none !important;
      }
    }

    .slick-prev.slick-disabled, .slick-next.slick-disabled {
      min-width: 0;
      width: 0;
      border: none;
    }

    .slick-next:hover, .slick-prev:hover {
      box-shadow: 0 0 5px 0 $grey;
      transition: box-shadow 200ms;
    }

    .slick-next.slick-disabled:hover, .slick-prev.slick-disabled:hover {
      box-shadow: 0 0 0 0 $grey;
      transition: box-shadow 200ms;
    }

    .slick-prev::before,
    .slick-next::before {
      content: '';
    }
  }
}
