@import '../../../assets/texts/choice_group_text.scss';
@import '../../../assets/colors.scss';

.simple-template {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &__title {
    margin: 30px 0 20px;
    text-align: center;

    @extend %template_title;
  }

  &__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    background: $white-grey;

    &_group-registration {
      position: relative;
    }
  }

  &__before {
    margin: 18px 0 28px;
    text-align: center;
  }

  &__error-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
    height: calc(100vh - 70px);
  }

  &__error-text {
    text-align: center;

    @extend %template_title;

    color: $red;
  }
}

.border-card {
  border: 1px solid $grey2;
  border-radius: 15px;
  padding: 17px 20px 19px;

  &.sm {
    padding: 13px 10px 20px 22px;
    width: 100%;
    margin-top: 20px;
  }

  @media screen and (max-width: 768px) {
    padding: 13px 10px 20px 22px;
  }

  &.white {
    background-color: $white;
  }
}

.padding-card {
  padding: 0 0 28px;
}

.not-found-group {
  display: flex;
  justify-content: center;
  align-items: center;
}
