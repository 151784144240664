@import '../../../../assets/colors.scss';
@import '../../../../assets/texts/consultation_text.scss';

.page-client-container {
  width: 100%;
  min-height: 480px;
  display: flex;
  // flex-wrap: wrap;
  flex: 1 1;

  @media screen and (max-width: 1300px) {
    flex-wrap: wrap;
  }

  &__column {
    display: flex;
    align-items: flex-start;

    @media screen and (max-width: 1300px) {
      margin-bottom: 20px;
      width: 100%;
    }
  }

  &__column:last-child {
    margin: 0 0 0 23px;
    width: 100%;

    @media screen and (max-width: 1310px) {
      margin-left: 16px;
    }

    @media screen and (max-width: 1300px) {
      margin: 5px;
      width: 100%;
    }

    .account-edit-block,
    .account-details {
      padding: 7px 38px;
    }

    .ant-tabs-tab {
      padding: 43px 0 5px 3px !important;

      @media screen and (max-width: 768px) {
        padding-top: 20px !important;
      }
    }

    .info-tabs {
      border-radius: 15px;
    }
  }

  // tabs
  .info-tabs {
    padding: 0 16px 30px;
    width: 100%;
    background: white;
    border-radius: 10px;
    // box-shadow: 0 0 5px $shadow;
    .ant-tabs-tab {
      padding: 20px;

      .ant-tabs-tab-btn {
        @extend %item;
      }
    }

    .ant-tabs-nav {
      //padding: 0 30px;
      @media screen and (max-width: 768px) {
        padding: 0 5px;
      }
    }

    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        @extend %item_active;
      }
    }

    .ant-tabs-ink-bar {
      border-bottom: 2px solid $blue-light;
    }

    .ant-tabs-nav::before {
      border-bottom: none !important;
    }
  }

  .ant-spin-container {
    padding-bottom: 10px;
    overflow-y: auto;
  }
}

.info-client-container {
  padding: 40px 30px 15px;
  min-width: 434px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background: $white;
  // box-shadow: 0 0 5px $shadow;
  @media screen and (max-width: 498px) {
    padding: 10px;
    width: 100%;
    min-width: 100%;
  }

  .client-card-mini {
    margin-bottom: 6px;

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      justify-content: center;

      .client-card-mini__title {
        text-align: center;
      }
    }
  }
}

@supports (-webkit-touch-callout: none) {
  .page-client-container {
    .ant-spin-container {
      overflow-y: scroll;
    }
  }
}
