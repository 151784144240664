@import "./functions.scss";

@mixin scrollbars($size, $foreground-color, $background) {
  // style for scroll bar
  &::-webkit-scrollbar {
    width: get-vw($size);
  }

  &::-webkit-scrollbar-track {
    // border-radius: 3px;
    width: get-vw($size);
    background-color: $background;
    //border-top: 2px solid #CAD2DE;
    //border-left: 2px solid #CAD2DE;
    //box-shadow: inset 0px 2px 6px rgba(43, 135, 220, 0.2);
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb:hover {
    width: $size;
    background: $foreground-color;
    border: get-vw(1px) solid white;
    border-radius: get-vw(10px);
  }
}

// adaptive value mixin
$max-width: 1440;
$max-width-container: 1440;

@mixin adaptive-value($property, $start-size, $min-size, $type) {
  $add-size: $start-size - $min-size;

  @if $type==1 {
    // adaptive value if only less than container
    #{property}: $start-size + px;

    @media (max-width: #{$max-width-container + px}) {
      #{$property}: calc(#{$min-size + px} + #{$add-size} * ((100vw - 320px) / #{$max-width-container - 320}));
    }
  }

  @else if $type==2 {
    // adaptive value if only large than container
    #{property}: $start-size + px;

    @media (min-width: #{$max-width-container + px}) {
      #{$property}: calc(#{$min-size + px} + #{$add-size} * ((100vw - 320px) / #{$max-width - 320}));
    }
  }

  @else {
    // adaptive value always
    #{$property}: calc(#{$min-size + px} + #{$add-size} * ((100vw - 320px) / #{$max-width - 320}));
  }
}

// region Browser Hacks

// Safari 10.1+
@mixin safari-only {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      @content;
    }
  }
}

// endregion