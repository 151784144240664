@import '../../../assets/texts/account_text.scss';
@import '../../../assets/colors.scss';

.zoom-integration-container {
  margin: 16px 0;
  padding: 24px 13px 20px;
  width: 404px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background-color: #fff;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    width: auto;
  }

  &__title {
    padding-left: 10px;
    padding-bottom: 10px;
    @extend %prev_title;
  }

  &__body {
    padding: 22px;
    display: flex;
    justify-content: space-between;
    border: 1px solid $grey6;
    border-radius: 15px;
    background-color: #fafafb;
  }

  &__mail {
    @extend %value;
  }

  &__edit {
    cursor: pointer;

    @extend %value;

    color: $blue-light;
  }
}
