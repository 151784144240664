@import '../colors.scss';
@import './main.scss';
@import '../functions.scss';

%title {
  @extend %family_poppins;

  font-size: 27px;
  line-height: 40px;
  font-weight: 600;
  letter-spacing: letter-spacing(4.17);
  color: $blue-light;
}

%title_text {
  @extend %family_poppins;

  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: letter-spacing(6.25);
  color: $grey;
}

%price_h1 {
  @extend %family_poppins;

  font-size: 57px;
  line-height: 26px;
  font-weight: 400;
  letter-spacing: letter-spacing(6.25);
  color: $black;
}

%price_h2 {
  @extend %family_poppins;

  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  letter-spacing: letter-spacing(6.25);
  color: $black;
}

%item {
  @extend %family_poppins;

  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: letter-spacing(6.25);
  color: $black;
}
