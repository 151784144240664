@import '../../../assets/texts/progress_text.scss';
@import '../../../assets/colors.scss';
@import '../../../assets/mixin';

.progress-logs-box {
  position: relative;
  max-width: 1084px;
  margin: 0 auto;
  border-radius: 15px;
  background-color: white;
  transition: all 0.3s ease;

  .ant-spin-spinning {
    display: none;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    border-bottom: 1px solid $border;

    @include adaptive-value('padding', 30, 20, 1);
  }

  &__top-title {
    @extend %family_roboto;

    font-weight: 500;
    font-size: 18px;
    line-height: 26 / 18 * 100%;
    text-align: center;
    color: $black;
  }

  &__title {
    margin: 24px 0 30px;
    text-align: center;

    @include adaptive-value('margin-top', 24, 14, 1);
    @include adaptive-value('margin-bottom', 30, 18, 1);

    @extend %page_title;
  }

  &__info {
    @extend %family_roboto;

    flex-grow: 1;
    max-width: 600px;
    font-weight: normal;
    font-size: 18px;
    line-height: 28 / 18 * 100%;
    text-align: center;
    color: $grey7;

    @include adaptive-value('font-size', 18, 14, 1);
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    min-height: 470px;

    @include adaptive-value('min-height', 470, 390, 1);
    @include adaptive-value('padding-left', 30, 15, 1);
    @include adaptive-value('padding-right', 30, 15, 1);
    @include adaptive-value('padding-top', 30, 20, 1);
    @include adaptive-value('padding-bottom', 30, 20, 1);
  }

  &__btn-add {
    margin-left: auto;
    font-size: 20px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $blue-light;
    color: $white;
    cursor: pointer;
  }

  &__log-body {
    max-width: 655px;
  }

  &__answers-title {
    @extend %family_poppins;

    font-weight: 600;
    font-size: 22px;
    color: #000;
  }

  &__answers-sub-title {
    @extend %family_roboto;

    font-size: 16px;
    line-height: 21 / 16 * 100%;
    color: $black-light;
    margin-top: 12px;
  }
}

.progress-item-box {
  margin: 0 auto;
  max-width: 665px;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    flex-direction: column;
  }

  &__title {
    @extend %page_title;
  }

  &__info {
    @extend %page_text;

    font-size: 18px;
  }
}

.choice-colors {
  margin: 21px 0 35px;
  display: flex;
  flex-wrap: wrap;

  &__wrap {
    cursor: pointer;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2px solid transparent;
    transition: all 0.3s ease;

    &:not(:last-child) {
      margin-right: 45px;

      @include adaptive-value('margin-right', 45, 27, 1);
    }

    &-active {
      border: 2px solid #3b91de;
    }
  }

  &__item {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
}

.logs-list {
  max-width: 780px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  @media (min-width: 768px) {
    margin-left: -15px;
    margin-right: -15px;
  }

  margin-top: 24px;

  &__wrap {
    padding: 0 15px;
    margin-bottom: 30px;
    height: 157px;
    flex: 0 0 33.333%;

    @media (max-width: 991px) {
      flex: 0 0 50%;
    }

    @media (max-width: 768px) {
      flex: 0 0 100%;
      padding: 0;
      margin-bottom: 15px;
    }
  }

  &__item {
    height: 100%;
    padding: 21px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 12px;
  }

  &__item-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__date {
    @extend %list_text;

    font-weight: 400;
    font-style: italic;
  }

  &__note {
    @extend %family_poppins;

    font-size: 14px;
    line-height: 19 / 14 * 100%;
    text-align: left;
    color: $black-light;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    overflow-wrap: anywhere;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  &__btn-edit {
    cursor: pointer;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: black;
  }
}

.note-box {
  padding: 24px 30px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  transition: all 0.3s ease;

  @include adaptive-value('padding-top', 24, 20, 1);
  @include adaptive-value('padding-top', 24, 20, 1);
  @include adaptive-value('padding-left', 30, 20, 1);
  @include adaptive-value('padding-right', 30, 20, 1);

  &__header {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__link {
    @extend %family_poppins;

    display: inline-block;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    text-align: right;
    color: $blue-light;
    cursor: pointer;

    &.back {
      position: relative;
      padding-left: 20px;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 3px;
        width: 8px;
        height: 14px;
        background: url("../../../assets/image/slider/arrow2.png") center / 8px 14px no-repeat;
      }
    }

    &:disabled {
      color: $grey5;
      cursor: not-allowed;
    }
  }

  &__textarea {
    @extend %family_roboto;

    border: none !important;
    outline: none !important;
    background-color: transparent !important;
    max-height: 210px;
    font-size: 16px;
    line-height: 26 / 16 * 100%;
    color: $black-light;
  }

  &__footer {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  &__date {
    @extend %page_text;

    font-size: 14px;
    font-style: italic;
  }
}
