@import './colors.scss';
@import './texts.scss';

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  cursor: pointer;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

%btn {
  cursor: pointer;
  border: none;
  outline: none;
}

%btn_form {
  min-height: 48px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  background-color: $blue-light;
}

%btn_default {
  min-height: 48px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  color: $black;
  background-color: #f1f1f5;
}

%btn_sort {
  width: 164px;
  height: 32px;
  border-radius: 10px;

  @extend %family_poppins;

  font-size: 14px;
}

.btn-form {
  @extend %btn;
  @extend %btn_form;
  @extend %btn_form_text;
}

.btn-form:disabled {
  background-color: $grey5;
  cursor: default;
}

.btn-default {
  @extend %btn;
  @extend %btn_default;
  @extend %btn_form_text;
}

.btn-close {
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #e2e2ea;
  background-color: #fff;
}

.btn-item {
  @extend %btn;
  @extend %btn_form;
  @extend %btn_form_text;

  max-width: 160px;
  min-height: 36px;
  height: 36px;
  font-size: 11px;
}

.btn-item:disabled {
  background-color: $grey5;
}

.btn-light {
  @extend %family_poppins;

  width: 100%;
  height: 48px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $blue-light;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  border-radius: 10px;

  &:hover {
    border-color: $blue-light;
  }

  &_cancel {
    &:hover {
      border-color: transparent;
    }
  }
}

.btn-remove {
  width: 12px;
  height: 14px;
  background: url("../assets/image/common/delete.svg") center no-repeat;
}

.btn-sort {
  @extend %btn_sort;

  background-color: #f5f6fa;
  color: #44444f;

  &_sm {
    width: 105px;
  }
}

.btc-sort-adaptive {
  @extend %btn_sort;

  @media screen and (max-width: 470px) {
    padding: 0;
    margin-right: 5px !important;
    width: 75px;
  }
}

.minus-margin_right {
  @media screen and (max-width: 470px) {
    margin-right: 5px !important;
  }
}

.btn-sort_active {
  @extend %btn_sort;

  background-color: $blue-light;
  color: #fff;

  &_sm {
    width: 105px;
  }
}
