@import '../../../../assets/selects.scss';
@import '../../../../assets/texts/account_text.scss';

.form-zoom-integration {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .input-field__input,
  .input-field-other__input {
    margin: 8px 0;
  }

  .input-field__label_error,
  .input-field-other__label_error {
    position: absolute;
    left: 15px;
    top: -8px;
  }

  &__title {
    padding-left: 1px;
    @extend %select_zoom_title;
  }

  &__select-zoom {
    padding: 24px 30px 70px;
    background-color: $background-light;
  }

  &__zoom-input {
    margin-top: 16px;
  }

  &__integration-btn {
    padding: 25px 30px;
  }

  .btn-form {
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .input-field_error {
    .ant-select-selector,
    .form-time-select {
      border: 1px solid $red !important;
    }
  }
}
