@import '../../../../assets/texts';

.dropdown-menu {
  min-width: 270px;
  max-width: 320px;
  padding: 7px 0 !important;

  @media (min-height: 413px) {
    max-width: 290px;
  }

  &__item {
    display: flex;
    align-items: center;
    white-space: normal;
  }

  &__text {
    @extend %family_poppins;

    line-height: 21 / 14 * 100%;
    color: $black;
    margin-left: 18px;
  }
}
