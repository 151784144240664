@import '../../../../assets/colors';
@import '../../../../assets/texts/stripe_text';
@import '../../../../assets/mixin';

.stripe-list {
  @extend %family_poppins;

  &__title {
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.01em;
    line-height: 1;
    margin-bottom: 27px;
    color: $black;
  }

  &__sub-title {
    @extend %family_roboto;

    font-weight: 400;
    font-size: 13.5px;
    letter-spacing: 0.01em;
    line-height: 100%;
    margin-top: 5px;
    text-align: left;
    color: $black-light;
  }

  &__item {
    position: relative;
    min-height: 130px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    font-size: 16px;
    color: $black;
    line-height: 20 / 16 * 100%;
    letter-spacing: 0.01em;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid $border;
    transition: all 0.3s ease;

    @include adaptive-value('padding-top', 16, 20, 3);
    @include adaptive-value('padding-left', 20, 10, 1);
    @include adaptive-value('padding-right', 20, 10, 1);
    
    @media screen and (max-width: 768px) {
        min-height: 115px;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &.active {
      color: $white;
      background: $blue-light;
      border-color: transparent;
    }
  }

  &__paid {
    display: flex;
    align-items: center;
    padding-right: 10px;
  }

  &__main-info {
    flex-grow: 1;
    padding-left: 22px;
    
    @media screen and (max-width: 480px) {
      padding-left: 14px;
    }

    & > *:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  &__paid-title {
    font-weight: 600;
    
    @include adaptive-value('font-size', 16, 14, 1);
  }

  &__summary {
    @include adaptive-value('font-size', 16, 14, 1);
  }

  &__save {
    color: $orange;
    white-space: nowrap;

    @media screen and (max-width: 400px) {
      display: block;
    }
  }

  &__discount {
    max-width: 85px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border-radius: 5px;
    background: rgba($orange, 0.16);
    color: $orange;

    @include adaptive-value('font-size', 14, 12, 1);
    @include adaptive-value('max-width', 85, 70, 1);
  }

  &__item.active &__discount {
    background: $white;
  }

  &__check {
    flex: 0 0 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid #707070;

    img {
      width: 100%;
      height: 100%;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s ease;
    }
    
    @media screen and (max-width: 480px) {
      flex: 0 0 18px;
      height: 18px;
    }

    &.none {
      border: transparent;
    }
  }

  &__item.active &__check {
    border-color: transparent;

    img {
      visibility: visible;
      opacity: 1;
    }
  }

  &__amount {
    display: flex;
    align-items: center;
  }

  &__sum {
    font-weight: 600;
    font-size: 36px;
    line-height: 20 / 36 * 100%;

    @include adaptive-value('font-size', 36, 20, 1);
  }
  
  &__week {
    @media screen and (max-width: 480px) {
          font-size: 13px;
    }
  }

  &__continue {
    max-width: 50%;
    margin-left: auto;
    font-weight: 600;
    
    @media screen and (max-width: 768px) {
      max-width: 100%;
      font-size: 14px !important;
      min-height: 40px !important;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;

    @extend %item;
  }

  &__title {
    font-weight: 600;

    span {
      color: $blue-light;
    }

    @include adaptive-value('font-size', 20, 18, 1);
  }

  &__text {
    span {
      font-weight: 600;
      color: $blue-light;
    }
  }

  &__box {
    cursor: pointer;
    min-width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid $grey;
  }

  &__box-active {
    background-color: $blue-light;
    border: 1px solid $blue-light;
    transition: background-color 200ms linear;
  }

  &__current-item {
    position: absolute;
    top: 0;
    padding: 0 5px;
    border-radius: 0 0 7px 7px;
    font-size: 12px;
    background: rgba($orange, 0.16);
    color: $orange;
  }
}
