.account-therapist-container {
  width: 100%;
  display: flex;
  // flex-wrap: wrap;
  flex: 1 1;

  @media screen and (max-width: 1200px) {
    flex-wrap: wrap;
  }

  &__column {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1200px) {
      margin: 0 23px;
      width: 100%;
    }
  }

  &__column:last-child {
    margin-left: 23px;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1200px) {
      margin: 16px 0 0;
    }

    .account-edit-block {
      .account-edit-block__title {
        border-bottom: none;
      }
    }

    .account-details {
      background: rgba(245, 246, 250, 0.5);
    }
  }

  .account-tabs {
    &__item {
      &-info {
        border-top: 0.5px solid #e6e7e9;
        background: rgba(230, 231, 233, 0.2);

        .account-edit-block {
          padding: unset;
          padding-left: 30px;

          &__title {
            padding-bottom: unset;
          }
        }

        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 30px;
        width: unset;

        .count_group {
          font-weight: 600 !important;
          border: none;
        }
      }

      .groups-list {
        background: rgba(230, 231, 233, 0.2);
        padding: 0 10px 0 30px;
      }
    }
  }

  .box-account-container {
    padding: 0;
  }
}
