@import '../../../../../assets/texts/questions_text.scss';
@import '../../../../../assets/colors.scss';
@import '../../../../../assets/mixin.scss';

.pause-client-modal {
  width: 460px;

  @media screen and (max-width: 768px) {
    width: auto !important;
  }

  .ant-modal-content {
    min-height: 340px;
    border-radius: 15px;
  }

  .ant-modal-body {
    padding: 0 !important;
  }

  .ant-spin-nested-loading {
    min-height: 340px;
  }
}

.pause-client-container {
  display: flex;
  flex-direction: column;

  &__title {
    padding: 30px 83px 25px;

    @include adaptive-value('padding-left', 83, 20, 1);
    @include adaptive-value('padding-right', 83, 20, 1);

    @extend %title;
    text-align: center;
  }

  &__group-info {
    padding: 25px 30px 68px;
    margin-bottom: 25px;
    background-color: $background-light;
    border-top: 1px solid $border;
    border-bottom: 1px solid $border;

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }

    .input-field {
      &__label {
        margin: 0 30px;
        background-color: $background-light;
      }
      input {
        background-color: $background-light;
      }
    }

    &_confirm {
      padding-bottom: 30px;
    }
  }

  &__block {
    display: flex;
    flex-wrap: wrap;
    padding: 0 30px 25px;
  }

  &__column {
    width: 49%;
    display: flex;
    flex-direction: column;
  }
}

.pause-client-info {
  @extend %pause_info;

  span {
    padding-left: 5px;
    color: $blue-light;
  }
}
