@import '../../../assets/colors';

.form-edit {
  .input-field {
    margin-bottom: 15px;

    @media (max-width: 576px) {
      margin-bottom: 8px;
    }
  }

  .btn-light {
    border: white;
    margin-top: 12px;
    font-size: 16px;
  }

  .cancel-button {
    padding: 0 30px 24px;
  }

  .edit-account-form {
    &__buttons {
      padding: 25px 30px 0;
    }

    &__inputs {
      padding: 33px 30px 28px;
      background: rgba($background-light, 0.5);
      border-top: 0.5px solid $border;
      border-bottom: 0.5px solid $border;

      .input-field {
        &__label {
          background-color: #fafafc;
          border-radius: 11px;
        }

        &__input {
          input {
            background: rgba(255, 255, 255, 0.5);
          }
        }
      }
    }
  }
}
