@import './main.scss';
@import '../colors.scss';

%greeting {
  @extend %family_poppins;

  font-size: 28px;
  font-weight: 500;
  text-align: center;
}

%therapist_name {
  @extend %family_roboto;

  font-weight: normal;
  font-size: 18px;
  font-weight: normal;
  line-height: 28px;
  color: #535353;
}

%group_description {
  @extend %family_roboto;

  font-size: 18px;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #535353;
}
