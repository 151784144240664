@import '../../../../assets/texts/modal_change_group.scss';
@import '../../../../assets/colors.scss';

.admin-chage-group-modal {
  width: 590px !important;

  @import '../../../../assets/selects.scss';

  @media screen and (max-width: 768px) {
    width: auto !important;
  }

  .form-select {
    .ant-select-selector {
      background-color: $background-light !important;
    }
  }

  .ant-modal-content {
    min-height: 340px;
    border-radius: 15px;
  }

  .ant-modal-body {
    padding: 0px;
  }

  .ant-spin-nested-loading {
    min-height: 340px;
  }

  .btn-container {
    margin: 24px 30px;
  }
}

.add-therapist-container {
  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .btn-form {
    @extend %add-therapist-btn;
  }

  &__group-column-container {
    display: flex;
    flex-direction: row;
    border-top: 1px solid $border;
    justify-content: space-between;

    padding: 24px 0;
    margin: 0px 30px;

    @media screen and (max-width: 570px) {
      flex-direction: column;
      padding: 24px 0;
    }
  }

  &__column {
    display: flex;
    width: 49%;

    @media screen and (max-width: 570px) {
      width: 100%;
      align-items: center;
    }
  }

  &__group-change-form {
    padding: 25px 30px 60px;
    background-color: $background-light;
    border-top: 1px solid $border;
    border-top: 1px solid $border;

    @media screen and (max-width: 570px) {
      padding: 25px 15px 60px;
    }
  }

  &__label {
    @extend %add-therapist-label;
    margin: 0px 11px 16px;

    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }
}

.m-client-box,
.m-group-box,
.m-therapist-box {
  display: flex;
}

.m-group-box,
.m-therapist-box {
  width: 100%;
}

.m-client-box {
  min-width: 260px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  border-radius: 15px;
  margin-left: 30px;
  margin-bottom: 24px;
  margin-top: 30px;

  @media screen and (max-width: 768px) {
    margin-left: 0px;
  }

  &__header {
    @extend %header;
  }
  &__username {
    @extend %username;

    font-weight: 600;
    font-size: 16px;
  }

  &__avatar {
    width: 34px;
    height: 34px;
    margin: 12px 15px 11px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: $blue-light;

    @extend %avatar;
  }
}

.m-group-box {
  border-radius: 10px;
  border: 1px solid #e2e2ea;
  flex-direction: row;
  padding-top: 12px;
  padding-left: 12px;
  padding-bottom: 4px;

  #Schedule {
    margin-right: 4px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
  }

  &__header {
    @extend %header;
  }

  &__name {
    @extend %name;
  }
  &__date {
    @extend %time;

    align-items: center;
    display: flex;
  }
  &__group-avatar {
    position: relative;
    min-width: 53px;
    width: 53px !important;
    height: 53px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: $blue-light;

    @extend %therapist-avatar;

    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }
}

.m-therapist-box {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  padding-top: 12px;
  padding-left: 12px;
  padding-bottom: 4px;
  border-radius: 10px;
  border: 1px solid #e2e2ea;

  @media screen and (max-width: 570px) {
    margin-left: 0;
    margin-top: 10px;
  }

  &__info {
    padding-left: 8px;
  }

  &__header {
    @extend %header;
  }
  &__name {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @extend %name;
  }

  &__avatar {
    position: relative;
    min-width: 53px;
    width: 53px;
    height: 53px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: $blue-light;

    @extend %therapist-avatar;

    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }

  &__img {
    position: absolute;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
    width: 53px !important;
    height: 53px !important;
  }
}

.success-change-group-container {
  &__group-card-container {
    background-color: $background-light;
    border-bottom: 0.5px solid $border;
    border-top: 0.5px solid $border;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px 30px 48px;
    align-items: flex-start;
    margin-top: 25px;
  }

  &__title-img {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__img {
    height: 94px;
    width: 94px;
    margin-top: 40px;
  }

  .small-group-card {
    border: 1px solid $border;
    border-radius: 10px;
    background-color: $white;
    padding: 20px;
    width: 100%;

    &__therapist-name {
      color: $blue-light;
    }

    &__begins-in {
      width: 50%;
    }
  }

  &__title {
    @extend %success-change-group-title;

    padding-top: 16px;
  }

  &__label {
    @extend %every-label;

    padding-bottom: 16px;
    padding-left: 1px;
  }

  &__button {
    padding: 24px 30px;
  }
}
