@import "../../../../../assets/colors";
@import "../../../../../assets/mixin";
@import "../../../../../assets/texts";

.benefits {
  padding: 30px;
  background: $background-light;
  border-radius: 10px;

  @include adaptive-value('padding', 30, 14, 1);

  &__item {
    @extend %family_roboto;

    font-size: 16px;
    letter-spacing: 0.01em;
    line-height: 28 / 16 * 100%;
    color: #000;
    padding-left: 40px;
    background: url("../../../../../assets/image/common/checkTeal.svg") no-repeat;

    @include adaptive-value('font-size', 16, 14, 1);

    &:not(:last-child) {
      margin-bottom: 35px;

      @include adaptive-value('margin-bottom', 35, 10, 1);
    }
  }
}
