@import '../../../assets/colors.scss';
@import '../../../assets/mixin.scss';
@import '../../../assets/texts/form_select_text.scss';

.input-datepicker {
  width: 100%;
  border-radius: 10px !important;
  background: #fff !important;
  height: 42px !important;
  border: 1px solid #e2e2ea !important;

  @include safari-only {
    input {
      line-height: 42px;
    }
  }

  input::placeholder {
    color: $grey2;
  }

  .ant-picker-panel {
    background: red !important;
  }
}

.dropdown-datepicker {
  width: 100%;
  max-width: 500px;

  .ant-picker-panel-container {
    border-radius: 15px;
  }

  .ant-picker-panel {
    width: 100%;
    padding-bottom: 30px;
  }

  .ant-picker-date-panel,
  .ant-picker-year-panel,
  .ant-picker-month-panel {
    width: 100%;
  }

  .ant-picker-content {
    width: 100% !important;

    * > th {
      font-weight: 500;
    }
  }

  .ant-picker-header {
    border-bottom: none;
    padding: 35px 40px 20px !important;
  }

  .ant-picker-cell-inner {
    border-radius: 50% !important;
    min-width: 40px !important;
    height: 40px !important;
    line-height: 40px !important;
    font-weight: 500;

    &::before {
      border-radius: 50% !important;
    }
  }

  .ant-picker-cell-today {
    .ant-picker-cell-inner {
      background-color: $border;
      color: #1891e4 !important;

      &::before {
        border: none !important;
      }
    }
  }

  .ant-picker-cell-selected {
    .ant-picker-cell-inner {
      color: $white !important;
    }
  }

  .ant-picker-header-view {
    font-size: 16px;
    color: #1891e4 !important;
  }

  .ant-picker-header-super-prev-btn,
  .ant-picker-header-super-next-btn {
    display: none !important;
  }

  .ant-picker-header-prev-btn,
  .ant-picker-header-next-btn {
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $border;
    border-radius: 10px;
    color: #1891e4 !important;
  }

  .ant-picker-next-icon {
    margin-right: 3px;
  }

  .ant-picker-prev-icon {
    margin-left: 3px;
  }
}
