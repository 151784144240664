@import '../../../../../assets/texts/questions_text.scss';
@import '../../../../../assets/colors.scss';

.questions-rating-modal.ant-modal {
  width: 560px !important;

  @media screen and (max-width: 600px) {
    width: 100% !important;
  }

  .ant-modal-content {
    border-radius: 15px;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-spin-nested-loading {
    min-height: 405px !important;
  }
}

.rating-container {
  min-height: 320px;

  &__header {
    @extend %family_roboto;

    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 105px;
    text-align: center;
    padding: 30px 42px;
    font-weight: 500;
    font-size: 18px;
    line-height: 26 / 18 * 100%;
    color: $black;

    @media screen and (max-width: 768px) {
      padding: 20px;
    }
  }

  &__body {
    min-height: 220px;
    padding: 24px 30px;
    background: $background-light;
    border: 0.5px solid #e6e7e9;

    &.center {
      display: flex;
      align-items: center;
      min-height: 250px;

      & > div {
        flex-grow: 1;
      }
    }

    @media screen and (max-width: 768px) {
      padding: 14px;
    }
  }

  &__footer {
    padding: 24px 30px;

    @media screen and (max-width: 768px) {
      padding: 14px;
    }
  }

  &__center {
    display: flex;
    justify-content: center;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @extend %answer;

    color: $blue-light;
    font-weight: 600;
  }

  &__textarea {
    margin: 50px 0 !important;
    border: none !important;
    outline: none !important;
    border-bottom: 2px solid rgba($color: $blue-light, $alpha: 0.2) !important;
    color: $blue-light !important;
    font-size: 30px !important;
    font-weight: 600 !important;
  }

  &__textarea::placeholder {
    color: rgba($color: $blue-light, $alpha: 0.2) !important;
    font-size: 30px;
    font-weight: 600;
  }

  .ant-input-focused,
  .ant-input:focus {
    box-shadow: none;
  }

  .btn-item {
    width: 100%;
    max-width: 100%;
    text-transform: uppercase;
  }
}
