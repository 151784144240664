.main-template {
  min-height: 100vh;

  &__header {
    height: 70px;
    z-index: 1;
    background: #fff;

    @media screen and (max-width: 768px) {
      padding: 0 5px;
    }
  }

  &__section {
    display: flex;
    flex-direction: row;
  }

  &__menu-container {
    min-width: 250px;
    background: #fff;
  }

  &__content {
    overflow: auto;
    width: 100%;
    height: calc(100vh - 70px);
    background: #f2f4f8;
    padding: 40px 70px 46px 36px;

    @media screen and (max-width: 1400px) {
      padding: 15px 12px 20px;
    }

    @media not all and (min-resolution: 0.001dpcm) {
      padding: 15px 12px 120px;
    }
  }

  .ant-layout-sider {
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  .item {
    width: 100%;
    height: 2000px;
    border: 1px solid;
  }
}
