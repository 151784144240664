.consultation-page {
  .preview-container {
    &__header {
      padding-top: 122px;

      @media screen and (max-width: 768px) {
        padding-top: 24px;
      }
    }

    &__box {
      margin-top: -169px;
      max-width: 560px;

      @media screen and (max-width: 768px) {
        margin: -149px 10px 0 10px;
        width: 100%;
      }
    }
  }

  .input-field {
    margin-bottom: 15px;
  }
}
