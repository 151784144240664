@import '../../../../../assets/selects.scss';
@import '../../../../../../src/assets/texts/form_select_text.scss';

.form-add-group {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__selections {
    border-top: 1px solid $border;
    border-bottom: 1px solid $border;
    padding: 14px 30px 30px;
    background-color: $background-light;
  }

  &__submit-btn {
    background-color: $white;
    padding: 24px 30px;
    border-radius: 15px;
  }

  .form-select {
    .ant-select-selector {
      background-color: $background-light !important;
    }
  }

  .form-time-select {
    background-color: $background-light !important;
  }

  .input-field__input,
  .input-field-other__input {
    margin: 8px 0;
    .ant-select-selector {
      background-color: $background-light !important;
    }
  }

  .input-field__label {
    @extend %form_label_text;
    background-color: $background-light;
    border-radius: 11px;
    top: 1px;
  }

  .btn-form {
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__column {
    width: 49%;
    display: flex;
    flex-direction: column;
  }

  .input-field_error {
    .ant-select-selector,
    .form-time-select {
      border: 1px solid $red !important;
    }
  }
}
