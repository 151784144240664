@import './main.scss';
@import '../colors.scss';
@import '../functions.scss';
%btn_form_text {
  @extend %family_poppins;

  font-size: 16px;
  line-height: 25px;
  font-weight: 600;
  color: $white;
}

%form_desc_text {
  @extend %family_roboto;

  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  letter-spacing: letter-spacing(7.14);
  color: $grey3;
}
