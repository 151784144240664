@import '../../../../assets/texts';
@import '../../../../assets/mixin';

.more-tracker {
  width: 1084px !important;

  @media screen and (max-width: 1100px) {
    width: 100% !important;
  }

  .ant-modal-content {
    border-radius: 15px;
  }

  .ant-modal-body {
    padding: 24px 30px;

    @include adaptive-value('padding-left', 30, 10, 1);
    @include adaptive-value('padding-right', 30, 10, 1);
  }

  .ant-modal-close {
    top: 15px;
    right: 10px;
  }
}
.layout-superbill {
  margin: 0 auto;
  max-width: 536px;
  display: flex;
  flex-direction: column;
}
.superbill-page-container {
  max-width: 536px;

  .superbill-details-header {
    margin-top: 50px;
    display: flex;
    width: 100%;

    &__title {
      @extend %family_roboto;
      font-size: 9px;
      color: gray;
      line-height: 26 / 16 * 100%;
      font-weight: normal;
      margin-top: 10px;
      letter-spacing: 0.5px;
    }

    &__text {
      @extend %family_roboto;
      font-size: 10.5px;
      color: black;
      line-height: 26 / 16 * 100%;
      font-weight: 500;
      margin-left: 50px;
      letter-spacing: 0.5px;
    }
  }

  .superbill-details-middle {
    display: flex;
    width: 100%;
    &__title {
      @extend %family_roboto;
      font-size: 9px;
      color: gray;
      line-height: 26 / 16 * 100%;
      font-weight: normal;
      margin-top: 10px;
      letter-spacing: 0.5px;
    }

    &__text {
      @extend %family_roboto;
      font-size: 10.5px;
      color: black;
      line-height: 26 / 16 * 100%;
      font-weight: 500;
      letter-spacing: 0.5px;
    }
  }

  .flex-grow {
    flex-grow: 1;
  }

  .name-block-box {
    margin-bottom: 30px;
  }
}

.superbill-page-subtitle {
  @extend %family_roboto;

  max-width: 630px;
  font-size: 16px;
  line-height: 26 / 16 * 100%;
  color: $grey9;
  margin-top: 12px;
  margin-bottom: 30px;
}

.superbill-page-description {
  @extend %family_roboto;

  max-width: 630px;
  font-size: 16px;
  line-height: 26 / 16 * 100%;
  color: $grey9;
  margin-top: 12px;
  margin-bottom: 30px;
  text-align: center;
}

.superbill-name {
  font-weight: bold;
  justify-content: center;
  display: flex;
}

.superbills {
  display: flex;
  flex-direction: column;

  &__item {
    display: flex;
    width: 100%;
    padding: 20px;
    font-weight: bold;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      border-bottom: 0.5px solid $border;
    }
  }
  &__item:hover {
    background-color: #eeeeee;
    cursor: pointer;
  }
  &__dash {
    padding: 0 20px;
  }
  &__title {
    @extend %item !optional;

    color: $black;
    line-height: 18px;
    width: 50%;
  }

  &__text {
    @extend %text_account !optional;

    .anticon {
      margin-right: 7px;
    }

    &_italic {
      font-style: italic;
      font-weight: 300;
    }

    &_bold {
      font-weight: 500;
      color: $black;
    }

    &_light {
      opacity: 0.6;
    }
  }

  &__block {
    width: 100%;
    background: rgba(245, 246, 250, 0.5);
    border-top: 0.5px solid #e6e7e9;
    border-bottom: 0.5px solid #e6e7e9;

    @media screen and (max-width: 768px) {
      padding: 5px;
    }
  }

  &__group-details {
    width: 50%;
  }
}

.btn-form {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 14px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &__confirm {
    background: #1891e5;
  }

  &__cancel {
    background: $white;
    color: $red4;
    border: 1px solid $red4;
  }
}

.superbill-table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 30px;
  th {
    padding: 8px;
    text-align: left;
    font-size: 10.5px;
  }

  th:last-child {
    text-align: end;
  }
  td {
    padding: 8px;
    text-align: left;
    font-size: 10.5px;
    color: black;
    font-weight: 500;
    letter-spacing: 0.5px;
  }

  td:last-child {
    text-align: end;
  }

  tbody tr:nth-child(odd) {
    background: #f9fafc;
  }
}
.superbill-total-price {
  font-size: 10.5px;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  .superbill-total-price-amount {
    margin-left: 30px;
    margin-right: 9px;
    font-weight: bold;
  }
}
.superbill-logo {
  width: 168px;
  height: 40px;
  object-fit: contain;
}
.superbill-details-download-button {
  margin-top: 60px;
  background: #58bea7;
  .superbill-details-download-icon {
    width: 15px;
    height: 15px;
    margin-left: 20px;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
}
.superbill-next-icon {
  min-width: 30px;
  width: 30px;
  height: 30px;
  margin-left: 30px;
  background: url(../../../../assets/image/slider/superbill-right.svg) no-repeat 55% center;

  @media screen and (max-width: 768px) {
    display: none !important;
  }
}
.superbill-icon-layout {
  display: flex;
  justify-content: flex-end;
  .superbill-icon-button {
    width: 20px;
    height: 20px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}
