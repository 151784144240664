@import '../../../../assets/texts/filter_modal_text.scss';

.check-box-list {
  .ant-checkbox + span {
    @extend %list;

    text-transform: capitalize;
  }

  .ant-checkbox-wrapper {
    min-width: 200px;
  }

  &__btn {
    padding-top: 15px;
    button {
      @extend %button_text;
    }
  }
}

.radio-list {
   span {
    @extend %list;

    text-transform: capitalize;
  }

  .ant-radio-wrapper {
    min-width: 200px;
  }
}
