@import './main.scss';
@import '../colors.scss';
@import '../functions.scss';

%title {
  @extend %family_poppins;

  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  color: $black;
}

%subtitle {
  @extend %family_roboto;

  font-size: 14px;
  color: $grey;
  letter-spacing: 0.01em;
}

%therapist {
  @extend %family_poppins;

  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: $black-light;
}

%label {
  @extend %family_roboto;

  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: $grey5;
}

%value {
  @extend %family_roboto;

  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  letter-spacing: letter-spacing(7.14);
  color: $black-light;
}

%topic_value {
  @extend %family_roboto;

  font-size: 12px;
  letter-spacing: 0.01em;
  color: $blue-light;
}