@import '../../../assets/colors.scss';
@import '../../../assets/texts.scss';

.ant-progress-circle-path {
  stroke-width: 11.5;
  stroke: #aeddff !important;
}

.ant-progress-circle-trail {
  stroke-width: 7.5;
  stroke: #ebecef !important;
}

.step-number {
  @extend %family_poppins;

  font-size: 18px;
  font-weight: 700;
  color: #1891e5;
}
