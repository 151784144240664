@import './main.scss';
@import '../colors.scss';
@import '../functions.scss';
// previews texts
%title {
  @extend %family_poppins;

  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  color: $black;
}

%title_before {
  @extend %family_poppins;

  font-size: 11px;
  line-height: 17px;
  font-weight: 400;
}

%sub_title {
  @extend %family_poppins;

  font-size: 20px;
  line-height: 27px;
  font-weight: 400;
  color: #707070;
}

%answer {
  @extend %family_roboto;

  font-size: 14px;
  line-height: 19px;
  letter-spacing: letter-spacing(7.14);
}

%label {
  @extend %family_roboto;

  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  letter-spacing: letter-spacing(6.25);
}

%text-label {
  @extend %family_poppins;

  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: $black;
}

%number {
  @extend %family_poppins;

  font-size: 18px;
  line-height: 27px;
  font-weight: 900;
  color: $blue-light2;
}

%desc {
  @extend %family_poppins;

  font-size: 20px;
  line-height: 18px;
  font-weight: 500;
  color: $black;
}

%archive-client-avatar {
  @extend %family_poppins;

  font-weight: normal;
  font-size: 22px;
  text-align: left;
  color: $white;
}

%archive-client-username {
  @extend %family_poppins;

  font-weight: 500;
  font-size: 14px;
  text-align: left;
  color: $black;
}

%reschedule_session_succes {
  @extend %family_poppins;

  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: $green;
}

%cancel_title {
  @extend %family_roboto;

  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: $black;
}
%cancel_subtitle {
  @extend %family_roboto;

  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: $black;
}

%cancellation {
  @extend %family_poppins;

  font-weight: 500;
  font-size: 28px;
  line-height: 28px;
  color: #1891e5;
}

%pause_info {
  @extend %family_poppins;

  font-size: 14px;
  letter-spacing: 0.01em;
  color: $black;
}
