@import '../../../../assets/texts/choice_group_text.scss';
@import '../../../../assets/colors.scss';

.card-therapist {
  display: flex;
  flex-direction: column;

  &__body {
    display: flex;
    flex: 1 1;

    @media screen and (max-width: 374.98px) {
      flex-direction: column;
      row-gap: 10px;
    }
  }

  &__avatar {
    position: relative;
    margin: 5px 16px 0 0;
    width: 77px;
    height: 77px;
    border-radius: 10%;
    background: $grey2;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 10px;
    }

    & div {
      @extend %fake-avatar;

      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $blue-light;
      border-radius: 10px;
    }
  }

  &__label {
    @extend %group_label;

    padding-bottom: 12px;
  }

  &__item {
    min-width: 190px;
    display: flex;
  }

  &__name {
    @extend %therapist_name;

    margin-bottom: 5px;
  }

  &__before {
    @extend %therapist_before;

    margin-right: 3px;
  }

  &__value {
    @extend %therapist_value;
  }

  &__description {
    overflow: auto;
    overflow-wrap: anywhere;
    @extend %group_desc_text;
  }

  &__tags {
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
  }

  &__info {
    padding-bottom: 19px;
    flex: 1;
  }

  &__tag-item {
    margin-right: 10px;
    padding: 5px 11px;
    max-width: 160px;

    @extend %group_label;

    color: #92929d;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 5px;
    background-color: $background-light;
  }

  &__edit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 10px;

    @media screen and (max-width: 768px) {
      flex-wrap: wrap-reverse;
    }
  }
}

@supports (-webkit-touch-callout: none) {
  .card-therapist__description {
    overflow: scroll;
  }
}
