@import '../../../../../assets/texts/history-text.scss';
@import '../../../../../assets/colors.scss';
@import '../../../../../assets/mixin.scss';

.history-container {
  display: flex;
  flex-direction: column;
  padding: 0 20px 0 30px;

  @include adaptive-value(padding-left, 30, 5, 1);
  @include adaptive-value(padding-right, 20, 0, 1);

  &.content {
    min-height: 300px;
  }

  &__title {
    @extend %title;

    color: $grey3 !important;
  }

  &__collapse {
    max-height: 600px !important;
    overflow: auto !important;
    margin: 5px 0 0 !important;
    border-radius: 0 !important;
    border: none !important;
    background-color: transparent !important;
    padding-right: 10px !important;

    @media screen and (max-width: 768px) {
      max-height: 100% !important;
    }

    .ant-collapse-header {
      padding: 16px 20px !important;

      @extend %title;

      color: $black-light !important;
    }

    .ant-collapse-content-box {
      padding: 0 10px 0 20px !important;
    }
  }

  &__panel {
    margin: 16px 0;
    border: 1px solid $grey2 !important;
    border-radius: 10px !important;

    .ant-collapse-content {
      border-top: none !important;
      background-color: transparent !important;
    }
  }

  &__text {
    margin: 20px 45px;

    @extend %text;

    @media screen and (max-width: 768px) {
      margin: 5px;
    }
  }

  &__cancelled-status {
    display: block;
    padding: 0 10px;
    border-radius: 5px;
    background-color: rgba($color: $red4, $alpha: 0.16);
    color: $red4;

    @extend %status_text;
  }

  &__button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;

    @extend %button;
  }

  &__header {
    display: flex;
    justify-content: space-between;

    &_members {
      position: relative;
      padding-right: 35px;

      @extend %sub_title;

      &::before {
        content: url("../../../../../assets/image/group/arrow.svg");
        position: absolute;
        top: 0;
        right: 7px;
        transition: transform 0.5s ease;
      }

      &.open {
        &::before {
          content: url("../../../../../assets/image/group/arrow.svg");
          position: absolute;
          top: 0;
          right: 7px;
          transform: rotateX(180deg);
          transition: transform 0.5s ease;
        }
      }
    }
  }

  &_admin {
    padding: 0 4px 0 14px;

    @include adaptive-value(padding-left, 14, 4, 1);
    @include adaptive-value(padding-right, 4, 0, 1);

    &__title {
      @extend %title;

      color: $black-light !important;

      &:nth-child(3) {
        margin-top: 16px;
      }
    }
  }
}
