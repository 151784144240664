@import '../colors.scss';
@import '../functions.scss';
%family_poppins {
  font-family: 'Poppins', sans-serif;
}

%family_roboto {
  font-family: 'Roboto', sans-serif;
}

%family_montserrat {
  font-family: 'Montserrat', sans-serif;
}

%header-page {
  @extend %family_poppins;

  font-size: 24px;
  line-height: 110%;
  font-weight: 600;
  letter-spacing: letter-spacing(4.17);
  color: $black;
}

.text-link {
  @extend %family_roboto;

  font-size: 14px;
  line-height: 19px;
  // font-weight: 500;
  color: $blue-light;
}

.text-page-header {
  @extend %header-page;

  &--size-15 {
    @extend %header-page;

    font-size: 15px;
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
}

.view-text-under-header {
  width: 100%;
  margin-top: 10px;
}

.text-under-header {
  font-size: 15px;
  width: 100%;
  font-weight: 400;
  max-width: 700px;
}

.text-page-header-before {
  @extend %family_roboto;

  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: letter-spacing(5.56);
  color: $grey;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}
