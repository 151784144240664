@import '../../../assets/texts';
@import '../../../assets/mixin';

.more-tracker {
  width: 1084px !important;

  @media screen and (max-width: 1100px) {
    width: 100% !important;
  }

  .ant-modal-content {
    border-radius: 15px;
  }

  .ant-modal-body {
    padding: 24px 30px;

    @include adaptive-value('padding-left', 30, 10, 1);
    @include adaptive-value('padding-right', 30, 10, 1);
  }

  .ant-modal-close {
    top: 15px;
    right: 10px;
  }
}

.text-page-subtitle {
  @extend %family_roboto;

  max-width: 630px;
  font-size: 16px;
  line-height: 26 / 16 * 100%;
  color: $grey9;
  margin-top: 12px;
  margin-bottom: 7px;
}
