@import '../../../assets/texts/session_item_text.scss';

.card {
  padding: 24px 16px;
  border-radius: 15px;
  background-color: $white;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 991px) {
    padding: 18px 12px;
  }

  @media screen and (max-width: 768px) {
    padding: 10px 18px;
  }

  &__header {
    display: flex;
    align-items: center;
    padding-right: 20px;

    @media screen and (max-width: 991px) {
      padding-right: 14px;
    }
  }

  &__title {
    @extend %family_poppins;

    flex-grow: 1;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.01em;
    color: $grey3;
    margin-bottom: 14px;
  }
}

.session-list__members {
  padding: 7px;
}
