@import '../../../../assets/texts/progress_text.scss';
@import '../../../../assets/colors.scss';

.log-container {
  display: flex;
  flex-direction: column;

  &__header {
    @extend %family_roboto;

    max-width: 800px;
    font-size: 16px;
    line-height: 28 / 16 * 100%;
    text-align: left;
    color: $black-light;
  }

  &__title {
    @extend %page_title;

    font-size: 18px;
  }

  &__items {
    position: relative;
    max-width: 800px;
    max-height: 70px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 24px -15px 0;

    @media (max-width: 480px) {
      margin: 24px 0 0;
    }

    &::after {
      content: '';
      position: absolute;
      width: 101%;
      height: 101%;
      left: 0;
      top: 0;
      background: linear-gradient(rgba(255, 255, 255, 0) 0%, #fff 100%);
      z-index: 2;
    }
  }

  &__wrap {
    flex: 1 0 33.333%;
    min-width: 240px;
    height: 157px;

    @media (min-width: 480px) {
      padding: 0 15px;
    }
  }

  &__item {
    height: 100%;
    padding: 21px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 12px;
  }

  &__item-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__date {
    @extend %list_text;

    font-weight: 400;
    font-style: italic;
  }

  &__note {
    @extend %family_poppins;

    font-size: 14px;
    line-height: 19 / 14 * 100%;
    text-align: left;
    color: $black-light;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  &__btn-edit {
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: black;
  }

  &__link {
    @extend %family_poppins;

    color: $blue-light;
    font-size: 14px;
    margin-top: 15px;
    cursor: pointer;
    font-weight: 600;
  }
}
