@import '../../../assets/colors.scss';

.box-account-container {
  padding: 10px 0 25px 0;
  width: 100%;
  min-width: 425px;
  min-height: 545px;
  max-height: 750px;
  background-color: #fff;
  border-radius: 15px;
  // box-shadow: 0 0 5px rgba($color: $shadow, $alpha: 10%);
  overflow: auto;

  @media screen and (max-width: 768px) {
    padding: 5px;
    min-width: 100%;
    max-height: 100%;
  }

  .ant-spin-nested-loading {
    min-height: 545px;
  }

  .account-details {
    padding: 23px 30px 40px;

    @media screen and (max-width: 768px) {
      padding: 5px;
    }
  }
}
