@import '../../../assets/texts';

.smile-range {

  .noUi-horizontal {
    height: 20px;
  }

  .noUi-connect {
    background: linear-gradient(to left, #3b91de 0%, rgba(24, 145, 229, 0.31) 100%);
  }

  .noUi-connects {
    border-radius: 10px;
  }

  .noUi-base {
    padding: 3px;
  }

  .noUi-target {
    border: 1px solid #d5d5db;
    border-radius: 10px;
  }

  .noUi-handle::before,
  .noUi-handle::after {
    display: none;
  }

  .noUi-handle {
    width: 47px;
    height: 47px;
    border: none;
    box-shadow: none;
    top: -15px;
    right: -17px;
  }

  .i1 .noUi-handle {
    background: url("../../../assets/image/rangeSmiles/1.svg") center 100%;
    right: -40px;
  }

  .i2 .noUi-handle {
    background: url("../../../assets/image/rangeSmiles/2.svg") center 100%;
    right: -35px;
  }

  .i3 .noUi-handle {
    background: url("../../../assets/image/rangeSmiles/3.svg") center 100%;
    right: -30px;
  }

  .i4 .noUi-handle {
    background: url("../../../assets/image/rangeSmiles/4.svg") center 100%;
    right: -25px;
  }

  .i5 .noUi-handle {
    background: url("../../../assets/image/rangeSmiles/5.svg") center 100%;
    right: -22px;
  }

  .i6 .noUi-handle {
    background: url("../../../assets/image/rangeSmiles/6.svg") center 100%;
    right: -18px;
  }

  .i7 .noUi-handle {
    background: url("../../../assets/image/rangeSmiles/7.svg") center 100%;
    right: -11px;
  }

  .i8 .noUi-handle {
    background: url("../../../assets/image/rangeSmiles/8.svg") center 100%;
    right: -9px;
  }

  .i9 .noUi-handle {
    background: url("../../../assets/image/rangeSmiles/9.svg") center 100%;
    right: -4px;
  }

  .i10 .noUi-handle {
    background: url("../../../assets/image/rangeSmiles/10.svg") center 100%;
    right: -3px;
  }

  &__items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    padding: 0 15px;

    span {
      @extend %family_poppins;

      font-size: 14px;
      line-height: 26 / 14 * 100%;
      color: #919191;
      cursor: pointer;
    }
  }

  &__range-names {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;
  }

  &__name {
    @extend %family_poppins;

    font-weight: 500;
    font-size: 14px;
    color: $black-light;
    border-radius: 10px;
    padding: 9px 16px;
    border: 1px solid #d5d5db;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }
}
