@import '../../../../assets/texts/main_header_text.scss';
@import '../../../../assets/colors.scss';

.profile-dropdown-menu {
  padding: 20px 0 25px 0 !important;
  width: 240px;

  .ant-menu-item:first-child.ant-menu-item-selected {
    background: none;
  }

  &__user {
    display: flex;
    align-items: center;
    padding: 14px;
    background: $white-grey;
    border-radius: 8px;
    border: 1px solid $grey2;
  }

  &__user-container {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .ant-menu-title-content {
    flex-grow: 1;
  }

  &__user-info {
    padding-left: 8px;
  }

  &__user-name {
    @extend %simple_name_text_black;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 120px;
  }

  &__session-time {
    @extend %session_time_text;

    margin-top: 3px;
  }

  &__item {
    margin: 0 !important;
    padding: 0 !important;
    min-height: 42px;

    .ant-spin-dot-item {
      background: $red4;
    }
  }

  &__label {
    @extend %item_text;

    margin-left: 10px;
  }

  &__item_logout &__label {
    color: $red4;
  }

  &__item-wrap {
    display: flex;
    align-items: center;
  }

  &__item.ant-menu-item-selected {
    background-color: $white-grey !important;
  }

  .ant-spin {
    width: 20px;
    height: 20px;
  }

  // antd edit styles
  .ant-menu-item {
    height: auto !important;
  }
}
