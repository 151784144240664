@import '../../../assets/texts/choice_group_text.scss';
@import '../../../assets/colors.scss';

.progress-template {
  min-height: 99vh;
  display: flex;
  flex-direction: column;

  &__title {
    margin: 51px 0;

    @extend %template_title;
  }

  &__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    background: $white-grey;
  }

  &__before {
    margin-top: 49px;
    text-align: center;
  }
}

.simple-template {
  &__assessment-body {
    background: url('../../../assets/image/headers/svg/background.svg') no-repeat bottom/contain;
    background-size: cover;
    min-height: calc(100vh - 70px);
    .check-in-start {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
