@import '../../../../assets/texts/questions_text';
@import '../../../../assets/colors';
@import '../../../../assets/mixin';

.cansel-group-modal {
  width: 560px !important;

  @media screen and (max-width: 768px) {
    width: auto;
    max-width: 100% !important;
  }

  .ant-modal-content {
    min-height: 340px;
    border-radius: 15px;
  }

  .ant-spin-nested-loading {
    min-height: 340px;
  }

  .ant-modal-body {
    padding: 0 !important;
  }
}

.cansel-session-container {
  display: flex;
  flex-direction: column;

  &__title {
    padding: 30px 42px 25px;
    text-align: center;

    @include adaptive-value(padding-left, 42, 10, 1);
    @include adaptive-value(padding-right, 42, 10, 1);

    @extend %title;

    &_success {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 16px;
    }
  }

  &__finish-ico {
    margin-top: 10px;
  }

  &__main {
    padding: 24px 30px 42px;
    background: rgba($background-light, 0.5);
    border-top: 0.5px solid $border;
    border-bottom: 0.5px solid $border;

    @include adaptive-value(padding-left, 30, 5, 1);
    @include adaptive-value(padding-right, 30, 5, 1);
  }

  &__label {
    display: flex;
    align-items: center;
    padding-bottom: 17px;
    gap: 6px;

    @extend %text-label;

    font-size: 14px;
  }

  &__group-info {
    padding: 20px;
    border: 1px solid $grey2;
    border-radius: 15px;
    background-color: $white;

    .small-group-card {
      &__title {
        padding-bottom: 10px;
      }

      &__therapist {
        & span {
          color: $black-light !important;
        }
      }

      &__therapist-item {
        padding-top: 30px;

        & span:last-child {
          color: $black-light;
          font-weight: 500;
          opacity: 1;
        }
      }
    }
  }

  &__block {
    display: flex;
    flex-wrap: wrap;
    padding: 24px 30px;

    @include adaptive-value(padding-left, 30, 5, 1);
    @include adaptive-value(padding-right, 30, 5, 1);
  }

  &__column {
    width: 49%;
    display: flex;
    flex-direction: column;
  }
}

.cancel-client-modal {
  .ant-modal-content {
    min-height: 462px !important;
    border-radius: 15px;
  }
  @media screen and (max-width: 768px) {
    width: auto;
    max-width: 100% !important;
  }
}

.cancel-session-warning-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__icon {
    padding-top: 26px;
  }

  &__main {
    flex-direction: column;
    text-align: center;
  }

  &__title {
    @extend %cancel_title;

    margin-top: 30px;
  }

  &__subtitle {
    @extend %cancel_subtitle;

    margin-bottom: 94px;
  }

  &__cancellation {
    margin-top: 27px;
    @extend %cancellation;
  }

  &__cancellation-title {
    margin-bottom: 94px;
  }

  &__question {
    padding: 0 20px;
  }
}
