@import '../../../assets/texts/event_text.scss';
@import '../../../assets/mixin.scss';

.event-details {
  width: 404px;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 20px;
  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  &__main {
    padding: 12px 24px 24px;
    margin-top: 0 !important;
  }

  &__title {
    padding-top: 10px;
    border-top: 1px solid #f1f1f5;

    @extend %title;
  }

  &__subtitle {
    padding-top: 5px;
    @extend %subtitle;
  }

  &__therapist {
    padding-top: 7px;
    @extend %therapist;
  }

  &__info-card {
    background-color: #fafafc;
    border-radius: 10px;
    padding: 14px 16px;
    margin-top: 16px;

    &__item {
      padding-bottom: 10px;
    }

    &__label {
      padding-bottom: 5px;

      @extend %label;
    }

    &__value {
      @extend %value;
    }
  }

  &__image {
    position: relative;
    height: 170px;
    width: 100%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
      border-radius: 15px 15px 0 0;
    }
  }

  &__false-image {
    background: linear-gradient(rgba(6, 22, 52, 0) 45%, #000 125%);
    height: 170px;
    width: 100%;
    border-radius: 15px 15px 0 0;
  }

  &__start-time {
    @extend %family_roboto;

    position: absolute;
    left: 0;
    top: 19px;
    padding: 10px 10px 10px 25px;
    border-radius: 0 15px 15px 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: left;
    color: #000;
    background: rgba($white, 0.61);
    backdrop-filter: blur(10px);
    --webkit-backdrop-filter: blur(10px);

    @media screen and (max-width: 480px) {
      padding: 5px 10px;
    }
  }

  &__title-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    padding: 0 24px;
  }

  &__session-type {
    @extend %family_roboto;

    @include adaptive-value('width', 138, 110, 1);
    @include adaptive-value('font-size', 14, 12, 1);

    font-size: 14px;
    width: 138px;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 24px;
    margin-left: -24px;
    color: $white;
    font-weight: 500;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    line-height: 20px;

    span {
      z-index: 10;
    }

    &::after {
      content: '';
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      border-right: 12px solid transparent;
      border-top: 20px solid $red4;
    }

    &_webinar {
      &::after {
        border-top: 20px solid $green;
      }
    }
  }

  &__label {
    @extend %family_roboto;

    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.01em;
    color: $orange;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: rgba($orange, 0.2);
    border: 1px solid #f1f1f5;
    padding: 3px 10px;
  }

  &__main {
    margin-top: 14px;
  }

  &__begins {
    max-width: 170px;
    margin-top: 16px;
  }

  &__item-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
