@import '../../../../../assets/colors.scss';
@import '../../../../../assets/texts/modal_change_group.scss';

.restart-client-modal {
  width: 590px !important;

  .ant-modal-content {
    border-radius: 15px !important;
  }

  &_customer {
    width: 560px !important;

    @media screen and (min-width: 768px) {
      max-width: 560px !important;
      margin-left: 250px !important;
    }

    @media screen and (min-width: 1400px) {
      margin-left: 215px !important;
    }

    &.minimal {
      @media screen and (min-width: 768px) {
        margin-left: 60px !important;
      }

      @media screen and (min-width: 1400px) {
        margin-left: 25px !important;
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: auto !important;
  }

  .ant-modal-body {
    padding: 0 !important;
  }
}

.restart-client-form {
  &__select-group {
    background-color: $background-light;
    padding: 23px 30px 42px;
    border-top: 0.5px solid $border;
    border-bottom: 0.5px solid $border;
  }

  &__client-box {
    padding: 30px 30px 24px;

    .m-client-box {
      margin: 0 !important;
    }
  }
  .ant-select-selector {
    padding-right: 30px !important;
  }

  &__title {
    @extend %restart-group-label;

    padding-left: 11px;
    padding-bottom: 16px;
  }

  &__customer-title {
    @extend %label;

    font-size: 24px;
    padding: 30px;
  }

  &__text {
    padding: 50px 0;
    text-align: center;
    @extend %restart-group-label;

    line-height: 21px;
  }

  &__main {
    padding: 30px 30px 24px;
  }

  &__confirm-button {
    padding: 25px 30px;
  }
}

.success-restart-client-container__group-card {
  background-color: $white !important;
  border: none !important;
  padding: 0 30px 0 !important;
}
