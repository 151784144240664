@import '../../../../../assets/texts/questions_text.scss';

.reschedule-group-modal {
  width: 560px !important;

  @media screen and (max-width: 768px) {
    width: auto;
  }

  .ant-modal-content {
    min-height: 340px;
    border-radius: 15px;
  }

  .ant-spin-nested-loading {
    min-height: 340px;
  }

  .ant-modal-body {
    padding: 0 !important;
  }
}

.reschedule-session-container {
  display: flex;
  flex-direction: column;

  &__title {
    @extend %title;
  }

  &__block {
    display: flex;
    flex-wrap: wrap;
  }

  &__column {
    width: 49%;
    display: flex;
    flex-direction: column;
  }
}

.reschedule-group-success-container {
  .session-item__item {
    font-size: 14px;
    color: #3b3b3b;
  }
  .session-item__value {
    font-size: 14px;
    color: #3a3a3a !important;
  }
}
