@import '../../../../assets/texts/questions_text.scss';
@import '../../../../assets/colors.scss';

.notification-discount-modal {
  width: 460px !important;

  @media screen and (max-width: 768px) {
    width: auto !important;
  }

  .ant-modal-content {
    min-height: 340px;
    border-radius: 15px;
  }

  .ant-modal-body {
    padding: 0 !important;
  }

  .ant-spin-nested-loading {
    min-height: 340px;
  }
}

.notification-discount-container {
  display: flex;
  flex-direction: column;

  &__title {
    margin: 30px 0 25px;

    @extend %cancellation;
    text-align: center;
  }

  &__text-info {
    padding: 25px 30px 50px;
    margin-bottom: 25px;

    @extend %cancel_subtitle;
    text-align: center;
  }

  &__block {
    padding: 0 30px 25px;
  }

  .btn-form {
    &__submit-btn {
      margin: 0 30px 25px;
    }
  }
}
