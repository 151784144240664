@import '../../../../assets/texts/questions_text';
@import '../../../../assets/colors';
@import '../../../../assets/dropdown';
@import '../../../../assets/selects';
@import '../../../../assets/antd';

.before-after-modal {
  max-width: 470px !important;
  width: 100% !important;

  .ant-modal-body {
    padding: 40px 30px 30px;
  }

  @media screen and (max-width: 768px) {
    width: auto;
  }

  .ant-modal-content {
    height: inherit;
    border-radius: 15px;

    @media screen and (max-width: 450px) {
      height: auto;
    }
  }

  .ant-spin-nested-loading {
    min-height: 200px;

    .ant-spin {
      max-height: 100%;
    }
  }
}

.questions-modal {
  max-width: 470px !important;
  width: 100% !important;
  overflow: hidden;

  .ant-modal-content {
    height: inherit;
    border-radius: 15px;

    @media screen and (max-width: 450px) {
      height: auto;
    }
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-spin-nested-loading {
    min-height: 470px;

    .ant-spin {
      max-height: 100%;
    }

    @media screen and (max-width: 768px) {
      min-height: 391px;
    }

    @media screen and (max-width: 480px) {
      min-height: 385px;

      .ant-spin {
        max-height: 100%;
      }
    }
  }

  &.height-lg .ant-spin-nested-loading {
    min-height: 555px;

    @media screen and (max-width: 768px) {
      min-height: 525px;
    }
  }

  &__confirm {
    .ant-spin-nested-loading {
      min-height: auto;
    }
  }

  &__select {
    max-width: 560px !important;
  }
}

.symptoms-modal {
  width: 1084px !important;

  @media screen and (max-width: 1100px) {
    width: 100%;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-content {
    border-radius: 15px;
  }
}
