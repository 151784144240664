@import '../../../assets/texts/table_text.scss';

.user-avatar {
  &__prev-avatar,
  &__img-avatar {
    width: 48px;
    height: 48px;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }

  &__prev-avatar {
    @extend %prev_avatar_name;
  }
}
