@import '../../../../assets/colors.scss';
@import '../../../../assets/texts/slider_text.scss';
@import '../../../../assets/mixin.scss';

.btn-form {
  &__red {
    background: #fff !important;
    border: 1px solid #d3452e !important;
    color: #e2351e !important;
  }
}

.upcoming-events-container {
  margin: 5px 20px 5px 5px;
  width: auto;
  overflow: hidden;
  margin-right: -36px;
  padding-right: 20px;

  @media screen and (max-width: 1400px) {
    margin-right: -12px;
    padding-right: 15px;
  }

  @media screen and (max-width: 450px) {
    margin-right: 0;
    padding-right: 0px;
  }

  &__title {
    font-size: 15px !important;
  }

  .slider-item {
    min-width: 400px;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 21px;

    @media screen and (max-width: 450px) {
      margin: 0 5px;
    }

    @media screen and (max-width: 480px) {
      min-width: auto;
    }

    &__header {
      position: relative;
      padding-top: 41%;

      @media screen and (max-width: 480px) {
        padding-top: 65%;
      }
    }

    &__bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }

    &__start-time {
      @extend %family_roboto;

      position: absolute;
      left: 0;
      top: 19px;
      padding: 10px 10px 10px 25px;
      border-radius: 0 15px 15px 0;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      text-align: left;
      color: #000;
      background: rgba($white, 0.61);
      backdrop-filter: blur(10px);
      --webkit-backdrop-filter: blur(10px);

      @media screen and (max-width: 480px) {
        padding: 5px 10px;
      }
    }

    &__note {
      position: absolute;
      top: 0;
      right: 20px;
      cursor: pointer;
    }

    &__body {
      @include adaptive-value('padding', 22, 12, 3);

      padding: 22px;
      background: white;
    }

    &__title-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__session-type {
      @extend %family_roboto;

      @include adaptive-value('width', 138, 110, 1);
      @include adaptive-value('font-size', 14, 12, 1);

      font-size: 14px;
      width: 138px;
      position: relative;
      display: flex;
      align-items: center;
      padding: 0 22px;
      margin-left: -25px;
      color: $white;
      font-weight: 500;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      line-height: 20px;

      span {
        z-index: 10;
      }

      &::after {
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        border-right: 12px solid transparent;
        border-top: 20px solid $red4;
      }
    }

    &__label {
      @extend %family_roboto;

      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.01em;
      color: $orange;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      background: rgba(243, 155, 90, 0.2);
      border: 1px solid #f1f1f5;
      padding: 3px 10px;
    }

    &__main {
      margin-top: 14px;
    }

    &__title {
      @extend %family_poppins;

      @include adaptive-value('font-size', 20, 16, 1);

      font-weight: 600;
      line-height: 1;
      color: #000;
    }

    &__sub-title {
      @extend %family_roboto;

      @include adaptive-value('font-size', 14, 13, 1);

      letter-spacing: 0.01em;
      color: $grey;
      margin-top: 4px;
    }

    &__lead {
      @extend %family_poppins;

      @include adaptive-value('font-size', 14, 13, 1);

      font-weight: 500;
      line-height: 20 / 14 * 100%;
      color: $black-light;
      margin-top: 7px;
    }

    &__footer {
      display: flex;
      align-items: center;
      margin: 0 -8px;
      margin-top: 20px;

      & > * {
        flex: 0 0 50%;
        padding: 0 8px;
      }

      .begins {
        min-height: 36px;
      }

      .begins__text {
        font-size: 13px;
      }
    }

    &__btn {
      @extend %family_poppins;

      max-width: 100%;
      font-weight: 500;
      font-size: 16px;
      color: $white-grey;
      transition: all 0.3s ease;

      &:hover {
        background: darken($blue-light, 10%);
      }
    }

    .ant-spin-dot-item {
      background-color: #fff;
    }
  }

  .slider {
    .slick-next {
      right: 5px;
      width: 30px;
      height: 30px;
      background: rgba(#fff, 0.9) url(../../../../assets/image/slider/next.svg) 12px 9px / 25% no-repeat;
      border-radius: 50%;
      box-shadow: 0 0 35px 10px rgba(#000, 0.2);
      transition: box-shadow 200ms;
    }

    .slick-prev {
      left: 5px;
      width: 30px;
      height: 30px;
      z-index: 555;
      background: rgba(#fff, 0.9) url(../../../../assets/image/slider/back.svg) 12px 9px / 25% no-repeat;
      border-radius: 50%;
      box-shadow: 0 0 35px 10px rgba(#000, 0.2);
      transition: box-shadow 200ms;
      //display: none;
    }

    .slick-prev:hover,
    .slick-next:hover {
      box-shadow: 0 0 35px 10px rgba(#000, 0.3);
    }

    .slick-prev::before,
    .slick-next::before {
      content: '';
    }
  }
}

.upcoming-events-slider {
  width: 100%;
  min-height: 360px;
}
