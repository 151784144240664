@import '../../../../../assets/texts/filter_modal_text.scss';

.filters-container {
  display: flex;
  flex-direction: column;

  &__title {
    margin: 28px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @extend %title;
  }

  &__body {
    background-color: #f5f6fa80;
    border-bottom: 1px solid #e6e7e9;
    border-top: 1px solid #e6e7e9;
  }

  &__line {
    margin: auto 30px;
    height: 1px;
    background: #f1f1f5;
  }

  &__footer {
    margin: 25px 30px;
    display: flex;
    flex-direction: column;
  }

  .btn-clear-all {
    margin: 15px 0;

    @extend %box_title;

    color: #1891e5;
  }

  .input-field__error-container {
    display: none;
  }
}
