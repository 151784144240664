@import '../../../../../assets/texts/reschedule_text.scss';
@import '../../../../../assets/mixin.scss';

.form-reschedule-session {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba($background-light, 0.5);
  border-top: 0.5px solid $border;
  border-radius: 0 0 15px 15px;

  @import '../../../../../assets/selects.scss';

  .input-field__input,
  .input-field-other__input {
    margin: 8px 0;
  }

  .input-field__label {
    top: 1px;
  }

  &__main {
    padding: 20px 30px 13px;

    @include adaptive-value(padding-left, 30, 5, 1);
    @include adaptive-value(padding-right, 30, 5, 1);
  }

  &__small-row {
    max-width: 400px;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__column {
    width: 49%;
    display: flex;
    flex-direction: column;
  }

  .input-field_error {
    .ant-select-selector,
    .form-time-select {
      border: 1px solid $red !important;
    }
  }

  &__footer {
    padding: 24px 30px;
    background-color: $white;
    border-top: 0.5px solid $border;
    border-radius: 0 0 15px 15px;

    @include adaptive-value(padding-left, 30, 5, 1);
    @include adaptive-value(padding-right, 30, 5, 1);

    button {
      width: 100%;
    }
  }
}

.different-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  &__titles {
    display: flex;
    flex-wrap: wrap;
  }

  &__old {
    width: 100%;

    @extend %sub_title;
  }

  &__new {
    width: 100%;
    padding: 21px 0;

    @extend %sub_title;
  }

  &__title {
    padding: 0 20px 10px;
  }

  &__label {
    @extend %label;

    .anticon {
      margin-right: 6px;
    }
  }

  .session-item {
    max-width: 499px;
    display: flex;
    flex-wrap: wrap;
    background-color: $white;

    .session-item__body {
      flex: 0 0 auto;
      min-height: 100px;

      .begins {
        margin-top: 10px;
      }
    }

    &__avatar {
      height: 100px;
      width: 100px;

      @media screen and (max-width: 450px) {
        margin-right: 5px;
        margin-bottom: 16px;
      }
    }
  }
}
