@import '../../../assets/texts/stripe_text.scss';
@import '../../../assets/colors.scss';
@import '../../../assets/mixin';

.superbill-container {
  .ant-spin {
    min-height: 150px;
  }

  &__promo-alert {
    @extend %family_poppins;

    padding: 15px 40px 15px 56px;
    background: $blue-light;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      height: 18px;

      @media screen and (max-width: 480px) {
        transform: scale(0.8);
      }
    }

    @media screen and (max-width: 768px) {
      padding: 10px 20px 10px 30px;
    }
  }

  &__promo-top {
    flex-grow: 1;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: $white;
    padding: 0 10px;

    @include adaptive-value('font-size', 16, 13, 1);
  }

  &__body {
    min-height: calc(100vh - 70px);
    position: relative;
    padding: 90px 16px 30px;
    background: $background-light;

    @include adaptive-value('padding-top', 90, 25, 1);
    @include adaptive-value('padding-left', 16, 10, 1);
    @include adaptive-value('padding-right', 16, 10, 1);
    @include adaptive-value('padding-bottom', 30, 25, 1);
  }

  &.change &__body {
    padding-top: 20px;
  }

  &__top {
    @extend %family_poppins;

    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 120%;
    text-align: center;
    color: $black;
    margin-bottom: 25px;

    @media screen and (max-width: 768px) {
      padding: 0 10px;
      font-size: 20px;
    }
  }

  &:not(.change) &__top {
    @media screen and (max-width: 991px) {
      display: flex;
      align-items: center;
      padding-right: 56px;
    }
  }

  &__title {
    flex-grow: 1;

    &.pad {
      padding-left: 30px;
    }
  }

  &__close {
    button {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      background: $white url('../../../assets/image/account/close4.svg') center no-repeat;

      @media screen and (max-width: 768px) {
        transform: scale(0.6);
      }
    }
  }

  @media screen and (min-width: 991px) {
    // &__top &__back {
    // display: none;
    // }
  }

  @media screen and (max-width: 480px) {
    // &__top &__back {
    //   flex: 0 0 30px;
    //   height: 30px;
    // }

    // &__top &__cancel {
    //   background: $white url('../../../assets/image/slider/next.svg') 13px center no-repeat;
    // }
  }

  &__wrap {
    display: flex;
    align-items: center;
    padding-right: 56px;
  }

  @media screen and (max-width: 991px) {
    &__wrap {
      padding-right: 0;
    }

    // &__wrap &__back {
    //   display: none;
    // }
  }

  &__back {
    flex: 0 0 56px;
    height: 56px;
    overflow: hidden;
    margin-left: 10px;
  }

  &__cancel {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: $white url('../../../assets/image/slider/next.svg') 26px center no-repeat;
    transform: scale(-1);

    @media screen and (max-width: 768px) {
      transform: scale(-0.6);
    }
  }

  &__card {
    flex: 1 1 auto;
    max-width: 1084px;
    min-height: 570px;
    margin: 0 auto;
    padding: 40px;
    background: $white;
    border-radius: 15px;

    @include adaptive-value('padding', 40, 14, 1);

    @media screen and (max-width: 1250px) and (min-width: 991px) {
      margin: 0 10px;
    }
  }

  &__row {
    display: flex;
    align-items: flex-start;
    margin: 0 -18px;

    @media screen and (max-width: 1200px) {
      margin: 0 -10px;
    }

    @media screen and (max-width: 991px) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }

    &.second {
      @media screen and (max-width: 991px) {
        flex-direction: column;
      }
    }
  }

  &__col {
    flex: 0 0 50%;
    width: 100%;
    padding: 0 18px;

    @media screen and (max-width: 1200px) {
      padding: 0 10px;

      &:first-child {
        flex: 0 0 45%;
      }

      &:last-child {
        flex: 0 0 55%;
      }
    }

    @media screen and (max-width: 991px) {
      &:last-child {
        margin-bottom: 24px;
        min-height: 500px;
      }

      &.second {
        min-height: 440px;
      }

      &:first-child,
      &:last-child {
        flex: 1 1 100%;
      }
    }

    @media screen and (max-width: 768px) {
      &:last-child {
        min-height: 450px;
      }
    }
  }

  &.change .stripe-det {
    @media screen and (max-width: 415px) {
      min-height: 640px;
    }
  }
}

.stripe-price {
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__item {
    display: flex;
    align-items: flex-end;
  }

  &__value {
    @extend %price_h1;
  }

  &__date {
    @extend %price_h2;

    line-height: 5px;
  }
}
