@import '../../../../assets/texts/account_text.scss';
@import '../../../../assets/texts/info_text.scss';
@import '../../../../assets/colors.scss';
@import '../../../../assets/mixin.scss';

.assesment-modal {
  display: flex;
  width: 1260px !important;

  @media screen and (max-width: 768px) {
    width: auto;
    max-width: 100% !important;
  }

  .ant-modal-content {
    border-radius: 20px;
  }

  .ant-spin-nested-loading {
    min-height: 240px;
  }

  .ant-modal-close-x {
    padding: 24px 0 20px !important;
  }

  .ant-modal-body {
    padding: 0 !important;
  }
}

.assesment-container {
  display: flex;
  flex-direction: column;

  &__title {
    margin: 20px 0;

    @extend %title;

    font-size: 28px;
  }

  &__desc {
    margin: 20px 0;

    @extend %desc;
  }

  &__text {
    @extend %title;
  }

  &__li {
    @extend %title_ads;

    display: flex;
    justify-self: center;

    &_title {
      color: $black;
    }

    &_number {
      display: grid;
      grid-template-columns: 30px 1fr;
    }
  }

  &__name {
    @extend %name_profile;

    color: #000;
  }

  &__date {
    @extend %label;

    font-size: 14px;
  }

  &__li-active {
    background-color: rgba($color: $blue-light, $alpha: 0.1);
    border-radius: 12px;
  }

  &__item {
    padding: 22px 0 20px;
    display: grid;
    grid-template-columns: 38% 1fr 1fr 1fr 1fr;
    grid-column-gap: 8px;
    border-bottom: 1px solid $grey2;

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;

      &_title {
        grid-column: 1 /5;
        padding-bottom: 8px;
      }
    }

    &_titles {
      padding: 22px 30px 12px;

      @include adaptive-value(padding-left, 30, 10, 1);
      @include adaptive-value(padding-right, 30, 10, 1);
    }
  }

  &__score-li {
    display: flex;
    justify-content: center;

    span {
      width: 38px;
      height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;

      @extend %title;

      @include adaptive-value(font-size, 18, 16, 1);
    }
  }

  &__header {
    padding: 24px 24px 20px;
    display: flex;
    align-items: center;
  }

  &__body {
    padding: 0 30px;

    @include adaptive-value(padding-left, 30, 10, 1);
    @include adaptive-value(padding-right, 30, 10, 1);
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-bottom: 30px;

    @media screen and (max-width: 768px) {
      justify-content: flex-start;
    }
  }

  &__result {
    margin: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 86px 0 30px;

    @include adaptive-value(padding-left, 30, 10, 1);
    @include adaptive-value(padding-right, 86, 10, 1);

    @media screen and (max-width: 768px) {
      width: auto;
    }
  }

  &__result-header {
    display: grid;
    grid-template-columns: 35% 1fr 1fr 1fr 1fr;
    grid-column-gap: 12px;
    padding-top: 20px;

    @include adaptive-value(grid-column-gap, 12, 2, 1);

    .assesment-container__li {
      min-width: 15%;
      display: flex;
      justify-content: center;
      border-bottom: 2px solid black;
    }

    &_title {
      padding-left: 20px;

      @include adaptive-value(padding-left, 20, 0, 1);
    }
  }

  &__result-rank {
    margin-top: 16px;
    display: grid;
    grid-template-columns: 29% 1fr 1fr 1fr 1fr;
    grid-column-gap: 8px;

    @include adaptive-value(grid-column-gap, 8, 1, 1);

    @extend %title;

    .assesment-container__text:last-child {
      text-align: center;
    }
  }

  &__result-item {
    display: flex;
    justify-content: space-between;

    div {
      width: 38px;
      flex: 1;

      @media screen and (max-width: 768px) {
        width: auto;
      }
    }

    &_symbol {
      margin-left: -16px;
    }

    &_score {
      text-align: center;
      border-bottom: 2px solid #403f3f;
      margin-left: -8px;
    }

    &_center {
      text-align: center;
      margin-left: -8px;
    }
  }

  &__avatar {
    position: relative;
    width: 34px;
    height: 34px;
    border-radius: 10px;
    background-color: $grey;
    margin-right: 10px;
  }

  &__avatar-img {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    left: 0;
    top: 0;
    object-fit: cover;
    object-position: center;
  }

  &__avatar-fake {
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: $blue-light;

    @extend %fake-avatar;

    font-size: 14px;
  }

  .btn-form {
    border-radius: 0;
    cursor: default;
  }
}
