@import '../colors.scss';
@import './main.scss';

//%family_poppins
//%family_roboto

%title {
  @extend %family_poppins;

  font-size: 16px;
  line-height: 25px;
  font-weight: 600;
  letter-spacing: letter-spacing(6.25);
  color: $black;
}

%label {
  @extend %family_roboto;

  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  letter-spacing: letter-spacing(6.25);
  color: $grey3;
}

%list-label {
  @extend %family_roboto;

  font-size: 14px;
  line-height: 25px;
  font-weight: 600;
  letter-spacing: letter-spacing(6.25);
  color: $black-light;
}

%value {
  @extend %family_roboto;

  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  letter-spacing: letter-spacing(6.25);
  color: $black;
}

%begin {
  @extend %family_roboto;

  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: letter-spacing(7.14);
  color: $orange;
}
