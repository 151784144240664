@import '../../../assets/colors.scss';
@import '../../../assets/texts.scss';
@import '../../../assets/texts/forum_text.scss';

.forum-init-container {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__connect-to-forum {
    background-color: $white;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 15px;
    padding: 40px 5px 70px;
  }

  &__header {
    @extend %header;
    padding-bottom: 24px;
    text-align: center;
  }

  &__username {
    @extend %username;
    padding-bottom: 20px;
  }

  &__forum-description {
    @extend %waiting_main_text;
    text-align: center;
    padding: 24px 0;
    color: $grey7;
  }

  .btn-form {
    max-width: 301px;
  }
}
