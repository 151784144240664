@import '../../../../assets/texts/questions_text.scss';
@import '../../../../assets/colors.scss';

.progress-questions-modal {
  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-content {
    min-width: 320px;
    min-height: 340px;
    border-radius: 15px;
  }

  .ant-spin-spinning {
    width: 320px;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .qustion-container {
    max-width: 460px;
  }

  .qustion-container__answers {
    margin-top: 20px;
  }

  .change-group-container__question {
    text-align: start;
    font-weight: 700;
  }
}

.tracker-container {
  max-width: 1100px;
  min-height: 300px;

  &__header {
    display: flex;
    padding: 30px;

    svg {
      cursor: pointer;
    }
  }

  &__title {
    @extend %family_roboto;

    flex-grow: 1;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 26 / 18 * 100%;
    color: $black;
  }
}

.progress-questions-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__body {
    max-width: 310px;
  }

  &__title {
    margin: 15px 0;

    @extend %title;

    font-size: 28px;
  }

  p {
    margin: 9px 0;

    @extend %desc;

    font-size: 18px;
  }

  &__btn-check,
  &__btn-init {
    cursor: pointer;
    margin: 0 auto;
    max-width: 88px;
    height: 88px;
    border-radius: 20px;
    border: none;
    outline: none;
  }

  &__btn-init {
    background: url(../../../../assets/image/group/around.png) center no-repeat, #1891e5;
    background-size: 100% 100%;
  }

  .btn-form {
    margin-top: 20px;
  }
}

.details-tracker {
  width: 560px !important;

  @media screen and (max-width: 600px) {
    width: 100% !important;
  }

  .ant-modal-content {
    border-radius: 15px;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-close {
    top: 15px;
    right: 10px;
  }

  &__body {
    padding: 24px 30px;
    text-align: center;
  }

  &__title {
    @extend %family_poppins;

    padding-top: 45px;
    font-weight: 600;
    font-size: 28px;
    line-height: 36 / 28 * 100%;
    color: $black;
  }

  &__descr {
    @extend %family_roboto;

    font-size: 16px;
    line-height: 28 / 16 * 100%;
    color: $black;
    margin-top: 24px;
  }

  &__btn {
    margin-top: 66px;
    width: 100%;

    @media screen and (max-width: 678px) {
      margin-top: 25px;
    }
  }
}
