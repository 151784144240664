@import '../../../assets/texts/choice_group_text.scss';
@import '../../../assets/texts/history-text.scss';
@import '../../../assets/colors.scss';

.nps-score-container {
  max-height: 375px;
  overflow: auto;
  padding-right: 10px;

  &__collapse {
    border: none !important;
    background: unset !important;
  }

  &__title {
    margin: 5px;

    @extend %group_label;
  }

  &__text {
    @extend %group_text;
  }

  &__button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;

    @extend %button;
  }

  &__panel {
    margin: 16px 0;
    border: 1px solid $grey2 !important;
    border-radius: 10px !important;

    .ant-collapse-content {
      border-top: none !important;
      background-color: transparent !important;
    }
  }

  .ant-collapse-content-box {
    padding-top: 13px !important;
    padding-bottom: 43px;
    background: #f5f6fa;
    border-top: 0.5px solid #e6e7e9;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .smile-range__items {
      margin: 12px 0;
    }

    .score-smile__box {
      margin-bottom: 26px;
      border-bottom: 0.5px solid #e6e7e9;
    }

    .primary-reason {
      padding-bottom: 25px;

      &__text {
        margin-top: 13px;
        border-radius: 10px;
        background: #fefefe;
        border: 1px solid #e2e2ea;
        height: 72px;
        padding: 10px;
      }
    }
  }
}
