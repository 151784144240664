@import '../../../../assets/texts/account_text.scss';

.edit-card-modal {
  display: flex;
  width: 100% !important;

  @media screen and (min-width: 768px) {
    max-width: 460px !important;
    margin-left: 250px !important;
  }

  @media screen and (min-width: 1400px) {
    margin-left: 215px !important;
  }

  &__minimal {
    @media screen and (min-width: 768px) {
      margin-left: 60px !important;
    }

    @media screen and (min-width: 1400px) {
      margin-left: 25px !important;
    }
  }

  @media screen and (min-width: 840px) {
    max-width: 560px !important;
  }

  .ant-modal-content {
    border-radius: 15px;
  }

  .ant-spin-nested-loading {
    min-height: 240px;
  }

  .ant-modal-body {
    padding: 38px 0 12px;
  }
}

.edit-card-container {
  display: flex;
  flex-direction: column;

  &__title {
    @extend %title;

    font-weight: 500;
    text-align: center;
  }

  &__desc {
    @extend %desc;

    padding: 5px 30px 13px;
    text-align: center;
  }

  .input-field__label {
    background: #fafafc;
    border-radius: 5px;
  }
}
