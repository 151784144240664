@import '../../../assets/texts';
@import '../../../assets/colors';

.loading-modal {
  width: 100% !important;
  max-width: 520px !important;

  @media screen and (max-width: 768px) {
    max-width: 100% !important;
  }

  .ant-modal-content {
    border-radius: 20px;
  }

  .ant-modal-body {
    padding: 0;
  }

  &__body {
    padding: 50px 38px;
    text-align: center;
    color: $black;
    letter-spacing: 0.01em;
  }

  &__title {
    @extend %family_poppins;

    font-weight: 600;
    font-size: 24px;
    margin-bottom: 40px;
  }

  &__text {
    @extend %family_poppins;

    font-size: 16px;
    margin-top: 50px;
    padding: 0 50px;
  }

  &__loader {
    img {
      animation: loading 1.5s linear infinite normal running 0s none;
    }
  }
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
