.notification-list-container {
  width: 100%;
  background: #ffffff;
  border-radius: 20px;
  padding: 24px;
  margin-top: 26px;

  &__title {
    background: #f2f4f8;
    margin-top: 24px;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  &__list-item-container {
    margin-top: 10px;
  }

  &__list-item {
    background: rgba(68, 143, 223, 0.07);
    border-radius: 10px;
    margin-top: 2px;
  }

  &__list-item-old {
    background: #fff;
  }

  &__section-message {
    display: flex;
    justify-content: space-between;
  }

  &__message {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #44444e;
  }

  &__time {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #92929c;
  }
}
