@import '../../../assets/texts.scss';
@import '../../../assets/colors.scss';

.preview-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  &__title {
    @extend %preview_title;

    @media screen and (max-width: 768px) {
      font-size: 32px;
      line-height: 46px;
      margin-top: 115px;
    }

    @media screen and (min-width: 769px) {
      margin-bottom: 30px;
    }
  }

  &__after-title {
    @extend %preview_title;

    line-height: normal;
    max-width: 565px;
    font-size: 40px;
    font-weight: 500;

    @media screen and (max-width: 768px) {
      font-size: 32px;
      margin-top: 115px;
    }

    @media screen and (min-width: 768px) {
      margin-bottom: 30px;
    }
  }

  &__icon {
    cursor: pointer;
  }

  &__title-icon-container {
    display: flex;
    align-items: center;
  }

  &__title-container {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-right: 30px;
  }

  &__description {
    @extend %preview_description;

    max-width: 460px;

    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
  }

  &__description_consultation {
    @extend %form_desc_consultation_text;

    max-width: 638px;
    text-align: justify;
    text-align-last: center;
    font-weight: normal;
  }

  &__wait-container {
    padding: 20px 8px 10px 7px;
  }

  &__wait-desc {
    @extend %waiting_text;

    display: flex;
    flex-direction: column;

    & span {
      margin-top: 32px;
    }
  }

  &__wait-desc_center {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &__wait-desc_text {
    @extend %waiting_main_text;
  }

  &__wait-desc_title {
    @extend %waiting_title;
  }

  &__wait-desc_logout {
    cursor: pointer;
    color: $blue-light;
  }

  @media screen and (max-width: 768px) {
    padding: 0 15px;
  }
}
