@import '../../../../../assets/texts/questions_text.scss';

.reschedule-session-modal {
  width: 560px !important;

  @media screen and (max-width: 768px) {
    width: auto !important;
    max-width: 560px;
  }

  .ant-modal-body {
    padding: 0 !important;
    background: $white;
    border-radius: 15px;
  }

  .ant-modal-content {
    min-height: 340px;
    border-radius: 15px;
  }

  .ant-spin-nested-loading {
    min-height: 340px;
  }
}

.reschedule-session-container {
  display: flex;
  flex-direction: column;

  &__title {
    padding: 30px 42px 25px;
    text-align: center;

    @extend %title;
  }

  &__block {
    display: flex;
    flex-wrap: wrap;
  }

  &__column {
    width: 49%;
    display: flex;
    flex-direction: column;
  }

  &__line-icon {
    margin-bottom: -1px;
    margin-top: -1px;
    margin-left: 11.5px;
    position: relative;
  }
}

.reschedule-success-container {
  &__main {
    background-color: $background-light;
    border-top: solid 0.5px $border;
    padding: 30px 30px 60px;
  }

  &__main-wrapper {
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    background: $white;
    border: 1px solid $border;
    padding: 20px 20px 24px;
  }

  &__icons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 23px;
  }

  .different-container__titles {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .different-container__title {
    padding: 0;
  }
}

.form-reschedule-session {
  &__new {
    margin-top: 30px;
  }
}

.green-time-container {
  @extend %reschedule_session_succes;
}

.blue-time-container {
  color: $blue-light;

  @extend %reschedule_session_succes;
}
