@import '../../../../../assets/texts/questions_text.scss';
@import '../../../../../assets/colors.scss';

.ant-popover-inner {
  background-color: #f5f5f8 !important;
  border-radius: 10px;
}

.popover-container {
  max-width: 286px;
  display: flex;
  flex-direction: column;

  &__close {
    margin: 10px 0;
    display: flex;
    justify-content: flex-end;
  }

  &__title {
    @extend %title;
  }

  &__text {
    margin: 20px 0;

    @extend %answer;
  }
}

// modal

.progress-modal.ant-modal {
  width: 740px !important;

  .ant-modal-content {
    border-radius: 15px;
  }

  .ant-spin-spinning {
    width: 100%;
    min-height: 340px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.progress-modal-container {
  &__actions {
    margin-top: 50px;

    button {
      width: 100%;
      max-width: none;
    }
  }

  &__title {
    @extend %title;

    font-size: 28px;
    line-height: 36 / 28 * 100%;
    text-align: center;
    color: $black;
    margin-top: 40px;
  }

  &__text {
    @extend %desc;
    @extend %family_roboto;

    font-weight: 400;
    font-size: 16px;
    line-height: 28 / 16 * 100%;
    margin-top: 30px;
    text-align: center;
    color: $black;
  }

  &__sub-text {
    @extend %family_roboto;

    font-weight: 400;
    font-size: 16px;
    line-height: 28 / 16 * 100%;
    margin-top: 20px;
    text-align: center;
    color: $grey;
  }

  .btn-form {
    font-weight: 600;
  }

  &__later {
    @extend %family_poppins;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: $blue-light;
    padding: 5px;
    margin-top: 10px;
    border-radius: 10px;
    border: 1px solid transparent;
    transition: all 0.3s ease;
    cursor: pointer;
  }
}
