@import '../../../../assets/texts/account_text.scss';

.edit-zoom-modal {
  display: flex;
  width: 655px !important;
  min-height: 360px;

  @media screen and (max-width: 768px) {
    width: auto !important;
  }

  .ant-modal-content {
    border-radius: 20px;
  }

  .ant-modal-body {
    padding: 0 !important;
  }

  .ant-spin-nested-loading {
    min-height: 240px;
  }
}

.edit-zoom-container {
  display: flex;
  flex-direction: column;

  &__title {
    @extend %select_zoom_title;
    margin: 30px 0 24px;
    text-align: center;
    @extend %select_zoom_title;
    font-size: 18px;
    line-height: 26px;
  }

  &__desc {
    margin: 20px 0;

    @extend %desc;
  }
}
