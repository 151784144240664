.enabled-notifications {

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:first-child {
      margin-bottom: 12px;
    }
  }
}