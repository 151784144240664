@import '../../../../../assets/texts/change_group.scss';
@import '../../../../../assets/mixin.scss';

.confirm-modal-container {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__last-groups {
    justify-content: space-evenly;
    padding-top: 30px;
  }

  &__title {
    padding: 30px 58px 25px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;

    @include adaptive-value('padding-left', 58, 30, 1);
    @include adaptive-value('padding-right', 58, 30, 1);
  }

  &__buttons {
    padding: 25px 30px 9px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    button:first-child {
      width: 100%;
      display: flex;
    }

    button:last-child {
      background: none;
      width: auto;
      color: $blue-light;
      margin-top: 9px;
    }
  }

  &__body {
    padding: 30px 30px 52px;
    width: 100%;
    background: $background-light;
    display: flex;
    flex-direction: column;
  }

  &__text {
    text-align: center;
    padding: 0 15px;
  }

  &__body-box {
    padding: 20px;
    width: 100%;
    border: 1px solid #e2e2ea;
    border-radius: 10px;
    background: $white;
  }

  &__body-title {
    @extend %label;

    font-weight: normal;
    display: flex;
    align-items: center;
    padding-bottom: 17px;
  }
}
