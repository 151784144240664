@import '../../../../../assets/texts/reschedule_text.scss';
@import '../../../../../assets/mixin.scss';

.client-reschedule-session {
  .button {
    cursor: pointer;
    &.no {
      text-align: center;
      color: #1891e5;
      margin-top: 30px;
    }
  }
}
