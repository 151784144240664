@import './main.scss';
@import '../colors.scss';
@import '../functions.scss';
%simple_name_text {
  @extend %family_poppins;

  font-size: 14px;
  line-height: 21 / 14 * 100%;
  font-weight: 600;
  letter-spacing: letter-spacing(7.14);
  color: $white;
}

%simple_name_text_black {
  @extend %simple_name_text;

  color: $black;
}

%logout_text {
  @extend %family_roboto;

  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  letter-spacing: letter-spacing(7.14);
  color: $red;
}

%session_time_text {
  @extend %family_roboto;

  font-size: 10px;
  line-height: 14 / 10 * 100%;
  color: $grey;
}

%item_text {
  @extend %family_poppins;

  font-size: 14px;
  line-height: 21 / 14 * 100%;
  color: $black;
}
