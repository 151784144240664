@import '../../../assets/colors.scss';
@import '../../../assets/texts/progress_text.scss';

.tracker {
  padding: 24px 30px;

  @media screen and (max-width: 768px) {
    padding: 0;
  }

  &__header {
    margin-bottom: 16px;
  }

  &__title {
    @extend %block_title;
  }

  &__results {
    @extend %family_poppins;

    font-size: 14px;
    line-height: 21 / 14 * 100%;
    color: #000;
  }

  &__text {
    padding-top: 12px;
  }

  &__boxs {
    display: flex;
    justify-content: space-between;
    margin: 0 -12px;

    @media screen and (max-width: 991px) {
      flex-direction: column;
    }
  }

  &__col {
    flex: 0 0 50%;
    padding: 0 12px;
  }

  &__box {
    height: 100%;
    padding: 24px 30px;
    display: flex;
    border: 1px solid #e3e3ea;
    border-radius: 15px;

    @include adaptive-value('padding-left', 30, 10, 1);
    @include adaptive-value('padding-right', 30, 10, 1);
    @include adaptive-value('padding-top', 24, 15, 1);
    @include adaptive-value('padding-bottom', 24, 15, 1);

    @media screen and (max-width: 991px) {
      margin: 4px;
      width: auto;
    }
  }

  &__more {
    margin-top: 10px;

    button {
      @extend %family_poppins;

      background: transparent;
      border: none;
      font-weight: 600;
      font-size: 14px;
      color: $blue-light;
      cursor: pointer;
    }
  }

  &__description {
    @extend %family_poppins;

    max-width: 468px;
    font-size: 12px;
    line-height: 22 / 12 * 100%;
    color: $grey;
    margin-top: 5px;
  }
}

.single-track-info {
  &__title {
    @extend %box_title;
  }

  &__rank {
    @extend %box_title;
  }

  &__text {
    @extend %text;
  }
}

.list-track-info {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__title {
    @extend %box_title;
  }

  &__items {
    max-height: 140px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }

  &__item {
    padding: 4px 0;
    margin: 4px 12px 4px 0;

    @extend %item;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    &:not(:last-child) {
      border-bottom: 1px solid #eee;
    }
  }

  &__item-date {
    color: #6e6e6e;
  }
}

.progress-page-container {
  margin: 15px;

  .progress-block-box:not(:last-child) {
    margin-bottom: 24px;
  }

  @media screen and (max-width: 768px) {
    margin: 4px;
  }
}
