@import '../../../../../assets/colors';
@import '../../../../../assets/texts';
@import '../../../../../assets/mixin';

.group {
  position: relative;
  display: flex;
  align-items: flex-start;
  border: 1px solid $border;
  border-radius: 10px;
  padding: 17px;

  @include adaptive-value('padding', 17, 12, 1);

  @media screen and (max-width: 480px) {
    flex-wrap: wrap;
  }

  &__precent {
    @extend %family_roboto;

    position: absolute;
    min-width: 73px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 20px;
    top: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 20 / 12 * 100%;
    padding: 5px 10px;
    border-radius: 0 0 8px 8px;
    color: $orange;
    background: #ffeee2;
    z-index: 2;
  }

  &__avatar {
    position: relative;
    flex: 0 0 84px;
    height: 84px;
    border-radius: 10px;
    background: $grey;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    @media screen and (max-width: 480px) {
      flex: 1 1 100%;
      height: auto;
      padding-bottom: 40%;
      margin-bottom: 20px;
    }
  }

  &__info {
    padding-left: 14px;

    & > *:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__title {
    @extend %family_poppins;

    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.01em;
    color: $black;
  }

  &__therapist {
    @extend %family_roboto;

    font-size: 15px;
    letter-spacing: 0.01em;
    padding-left: 25px;
    line-height: 140%;
    background: url("../../../../../assets/image/dropdown/skills.svg") left no-repeat;
    color: $black-light;
  }

  &__time {
    @extend %family_roboto;

    font-size: 15px;
    letter-spacing: 0.01em;
    padding-left: 25px;
    line-height: 120%;
    background: url("../../../../../assets/image/dropdown/time.svg") 2px no-repeat;
    color: $grey;

    span {
      white-space: nowrap;
    }
  }
}