@import '../../../assets/texts/choice_group_text.scss';
@import '../../../assets/texts/modal_change_group.scss';
@import '../../../assets/colors.scss';
@import '../../../assets/mixin.scss';

.group-history-item {
  position: relative;
  cursor: pointer;
  margin: 8px;
  padding: 20px 18px;
  min-width: 215px;
  width: calc(50% - 8px);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #f1f1f5;

  @include adaptive-value(margin-right, 8, 0, 1);
  @include adaptive-value(margin-left, 8, 0, 1);
  @include adaptive-value(padding-right, 18, 8, 1);
  @include adaptive-value(padding-left, 18, 8, 1);

  @media screen and (max-width: 768px) {
    flex: 1 1 calc(50% - 8px);
  }

  &:nth-child(odd) {
    margin-left: 0;
  }

  &:nth-child(even) {
    margin-right: 0;
  }

  &_group {
    padding-top: 35px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__more {
    position: absolute;
    top: 4px;
    right: 8px;

    .dropdown-menu {
      max-width: 270px !important;
      min-width: 270px !important;

      &__text {
        margin-left: 8px !important;
        font-size: 12px !important;
      }
    }

    .ant-dropdown .ant-menu-item {
      padding: 0 5px 0 12px !important;
      min-height: 30px;
    }
  }

  &__title {
    @extend %group_name;

    font-size: 16px;
    line-height: 16px;
  }

  &__type {
    position: absolute;
    top: 8px;
    left: 0;
    width: 86px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $blue-light;
    border-radius: 0 5px 5px 0;

    @extend %group_text;

    color: $white;
    font-weight: 500;
    font-size: 10px;

    &::before {
      content: '';
      margin: 2px 4px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      display: flex;
      background-color: $white;
    }

    &.past {
      background-color: $green;
    }
  }

  &__text {
    @extend %group_text;
  }

  &__label {
    padding-top: 10px;

    @extend %group_desc_text;

    color: $grey3;
  }

  &__about {
    // margin-top: 12px;
  }

  &__about-text {
    @extend %group_desc_text;

    line-height: 10px;
    color: $grey10;
  }

  &__day-name {
    @extend %group_desc_text;

    line-height: 10px;
    color: $grey5;
  }

  &__name {
    margin-top: 5px;

    @extend %group_therapist_name;

    display: flex;
    flex-wrap: wrap;
  }

  &__body {
    display: flex;
    flex: 1 1;
  }

  &__avatar {
    margin-right: 10px;
    min-width: 58px;
    height: 58px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: $blue-light;

    @extend %therapist-avatar;

    img {
      width: 100%;
      position: absolute;
      border-radius: 10px;
      object-fit: cover;
      object-position: center;
    }
  }

  &__avatar-txt {
    font-size: 26px;
  }

  &__date {
    margin-top: 6.5px;
    display: flex;
  }

  &__icon {
    margin-right: 5px;
  }

  &__footer {
    margin: 5px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
}

.group-history-item_focus {
  background-color: #1891e51a;
  border: 1px solid $blue-light;
}

.history-container {
  &__title {
    margin: 5px;

    @extend %group_label;
  }

  &__box {
    overflow: auto;

    &_group {
      height: 200px;

      &_lg {
        height: 280px;
        transition: height 0.4s;
      }
    }

    &_session {
      height: 320px;
    }
  }

  &__btn {
    text-align: end;
    padding: 0 10px;

    @extend %therapist_value;
  }
}

.history-list {
  width: calc(100% - 10px);
  display: flex;
  flex-wrap: wrap;
}
