@import '../../../../assets/texts/main_menu_text.scss';
@import '../../../../assets/colors.scss';

.main-menu {
  flex-shrink: 0;
  width: 250px;
  height: calc(100vh - 70px);
  padding: 23px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: $white;
  transition: 250ms;
  overflow-y: auto;
  overflow-x: hidden;

  &__logo-header {
    position: relative;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 12px 25px rgba(10, 30, 135, 0.03);
    z-index: 2;

    @media screen and (min-width: 767px) {
      display: none;
    }
  }

  &.mini {
    width: 60px;
    padding: 23px 5px;
  }

  &__profile {
    cursor: pointer;
    padding: 12px 22px;
    display: flex;
    align-items: center;
    background: $blue-light;
    border-radius: 0 10px 10px 0;
    margin-right: 16px;
    transition: all 0.3s ease;
    border: 2px solid $blue-light;
    border-left: none;

    &.active {
      background: $white;
    }
  }

  &.mini &__profile {
    margin: 0;
    padding: 7px 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    span {
      display: none;
    }
  }

  &__profile-name {
    @extend %family_poppins;
    @extend %profile_name;

    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    letter-spacing: 0.01em;
    color: $white;
    transition: all 0.3s ease;
  }

  &__profile.active &__profile-name {
    color: $blue-light;
  }

  &__list {
    margin-top: 10px;
    margin-left: 16px;
  }

  &.mini &__list {
    margin-left: 0;
  }

  &__toggler {
    display: none;
    position: relative;
    margin: 20px 16px 0;

    &.close {
      margin-top: 6px;

      .toggler-btn {
        &__icon {
          transform: rotate(-90deg);
        }
      }
    }

    &, .toggler-btn {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media (max-width: 768px) {
      display: none;
    }

    .toggler-btn {
      padding: 6px;
      position: relative;
      z-index: 1;
      border-radius: 10px;
      border: 1px solid $grey2;
      background-color: $white;

      &__icon {
        width: 20px;
        height: 20px;
        transition: ease 0.3s;
      }
    }

    .toggler-line {
      position: absolute;
      display: block;
      z-index: 0;
      width: 100%;
      border-top: 1px solid $grey2;
    }
  }

  &__name {
    @extend %family_poppins;

    letter-spacing: 0.01em;
    line-height: 24 / 18 * 100%;
    margin-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: opacity 1s ease-out;

    @extend %item_name;
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding: 21px 23px;
    cursor: pointer;
    border-radius: 10px 0 0 10px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      height: 13px;
      width: 13px;
      right: 0;
    }

    &::after {
      top: 100%;
      background: radial-gradient(circle at bottom left, transparent 70%, transparent 35%);
    }

    &::before {
      bottom: 100%;
      background: radial-gradient(circle at top left, transparent 70%, transparent 35%);
    }

    &.active {
      background: #f2f4f8;

      &::before {
        background: radial-gradient(circle at top left, transparent 70%, #f2f4f8 35%);
      }

      &::after {
        background: radial-gradient(circle at bottom left, transparent 70%, #f2f4f8 35%);
      }

      .main-menu {
        &__name {
          color: $blue-light;
        }

        &__icon {
          svg {
            path {
              fill: $blue-light;
            }
          }
        }
      }
    }

    &.disabled .main-menu__name {
      color: $grey2;
    }
  }

  .forum-inactive {
    circle {
      display: none;
    }
  }

  &.mini &__item {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;

    &.active {
      border-radius: 10px;
    }

    &::before,
    &::after {
      display: none;
    }
  }

  &.mini &__name {
    display: none;
    transition: opacity 1s ease-out;
    opacity: 0;
  }

  span.anticon {
    transform: scale(1.5);
  }

  &.mini span.anticon {
    transform: scale(1.15);
  }

  &__footer {
    padding: 28px;
    padding-bottom: 10px;
    transition: all 0.3s ease;
    min-width: 250px;

    @media screen and (max-width: 768px) {
      padding: 14px;
    }
  }

  &__footer-body {
    border-radius: 10px;
    background: rgba(#f2f4f8, 0.5);
    border: 1px solid $grey2;
    padding: 24px 22px 32px;

    @media screen and (max-width: 768px) {
      padding: 14px;
    }
  }

  &__footer-text {
    font-size: 14px;
    letter-spacing: 0.01em;
    line-height: 22 / 14 * 100%;
    color: #696974;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__contact {
    color: $black;
    font-weight: 600;
  }

  &__link {
    text-decoration: underline;
    color: $blue-light;
  }

  &.mini &__footer {
    display: none;
  }
}

.main-menu-drawer {
  .ant-drawer-body {
    padding: 4px;
  }
}
