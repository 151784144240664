@import '../colors.scss';
@import './main.scss';
//%family_poppins
//%family_roboto
%title {
  @extend %family_poppins;

  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  letter-spacing: letter-spacing(7.14);
  color: $black;
}

%modal_title {
  @extend %title;

  font-size: 18px !important;
}

%show {
  @extend %family_roboto;

  font-size: 12px;
  line-height: 16px;
  color: $blue-light;
}

%member {
  @extend %family_poppins;

  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: $white;
}

%modal_text {
  @extend %family_poppins;

  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: $black-light;
}
