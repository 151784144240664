.groups-search {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 30px 30px 0 70px;

  @media screen and (max-width: 768px) {
    padding: 15px;
  }

  &__item {
    position: relative;
    max-width: 395px;
    background-color: white;
    margin-right: 15px;
    margin-top: 15px;
    border-radius: 15px;
    padding: 15px 15px 80px;

    .btn-form_join-group {
      position: absolute;
      bottom: 15px;
      left: 0;
      padding: 0 15px;
    }

    @media screen and (max-width: 768px) {
      margin-right: 0;
    }
  }
}
