@import '../../../../../assets/colors.scss';
@import '../../../../../assets/texts/progress_text.scss';

.history-container {
  &__text {
    margin: 0 15px 0 0;
  }

  &__title {
    font-size: 14px;
    color: $black-light !important;
    margin: 0 !important;
  }
}

.assesment-item {
  display: flex;
  flex-direction: column;
  padding: 10px 0 8px;
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 100px;
    padding-bottom: 5px;
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 100px;
  }

  &__title {
    @extend %assessments_title;
  }

  &__label {
    @extend %text_bold;

    color: #6e6e6e;
  }

  &__rank {
    @extend %text_bold;

    color: $grey;
  }

  &__score {
    @extend %text_bold;

    color: #6f6f6f;
  }

  &__percent {
    @extend %text_bold;

    color: $black-light;
  }

  &__link {
    cursor: pointer;

    @extend %text_bold;

    color: $blue-light;
  }
}

.assesments-items, .assesments-items.history-container__collapse {
  display: flex;
  flex-direction: column;
  min-width: 550px;
  max-height: 315px !important;
  overflow-x: auto;
  border: 1px solid #e3e3ea !important;
  border-radius: 14px !important;
  padding: 17px 30px 23px;
  margin: 18px 0 0 !important;

  @media screen and (max-width: 768px) {
    padding: 8px;
  }
}

@supports (-webkit-touch-callout: none) {
  .assesments-items {
    overflow-x: scroll;
  }
}
