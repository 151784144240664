@import '../../../assets/texts';

.begins {
  @extend %family_roboto;

  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px 5px;
  font-size: 11px;
  letter-spacing: 0.01em;
  color: #e2351e;
  border: 1px solid #e2e2ea;
  border-radius: 10px;

  #Oval_2,
  #Path_3 {
    stroke: #e2351e;
  }

  @media screen and (max-width: 768px) and (min-width: 480px) {
    padding: 5px;
    max-height: 28px !important;
  }

  &.background {
    background-color: rgba(#e2351e, 0.05);
  }

  &__week {
    color: #e2351e;
    border-radius: 10px;
    border: 1px solid #f1f1f5;
    // background: rgba(243, 155, 90, 0.05);
    max-width: 188px;
    min-height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;

    #Oval_2,
    #Path_3 {
      stroke: #e2351e;
    }
  }

  &.two-weeks {
    color: $orange;

    #Oval_2,
    #Path_3 {
      stroke: $orange;
    }

    &.background {
      background-color: rgba($orange, 0.05);
    }
  }

  &.three-weeks {
    color: #3b91de;

    #Oval_2,
    #Path_3 {
      stroke: #3b91de;
    }

    &.background {
      background-color: rgba(#3b91de, 0.05);
    }
  }

  &.four-weeks {
    color: #82c43c;

    #Oval_2,
    #Path_3 {
      stroke: #82c43c;
    }

    &.background {
      background-color: rgba(#82c43c, 0.05);
    }
  }

  &__text {
    margin-left: 10px;
  }
}
