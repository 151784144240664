@import '../../../../../assets/texts/main.scss';

.filters-modal {
  display: flex;
  width: 700px !important;

  .ant-modal-content {
    border-radius: 15px;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-close-x {
    margin: 15px;
  }

  @media screen and (max-width: 768px) {
    width: 100% !important;
  }
}

.btn-filter {
  cursor: pointer;
  width: 200px;
  height: 38px;
  display: flex;
  align-items: center;
  border: 1px solid #e2e2ea;
  border-radius: 10px;

  @extend %family_poppins;

  font-size: 16px;
  font-weight: 500;
  color: #92929d;

  &__active {
    border: 1px solid #1891e5;
  }

  &__events {
    :last-child {
      position: relative;
      width: 100%;

      &:before {
        content: url("../../../../../assets/image/forms/arrow.svg");
        position: absolute;
        right: 10px;
        transition: all 0.5s;
      }
    }

    &.btn-filter__active {
      :last-child {
        &:before {
          transform: rotateX(180deg) translateY(-25%);
          transition: all 0.5s;
        }
      }
    }
  }
}
