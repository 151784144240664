@import '../../../../assets/texts/progress_text.scss';
@import '../../../../assets/colors.scss';

.term-container {
  display: flex;
  flex-direction: column;

  &__header {
    @extend %family_roboto;

    max-width: 800px;
    font-size: 16px;
    line-height: 28 / 16 * 100%;
    text-align: left;
    color: $black-light;
  }

  &__title {
    @extend %page_title;

    font-size: 18px;
  }

  &__list {
    margin-top: 15px;
  }

  &__items {
    position: relative;
    margin: 10px 0;
    padding: 25px;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 140px;
    max-width: 580px;
    border-radius: 15px;
    border: 1px solid $grey6;

    @include adaptive-value('padding-left', 25, 20, 1);
    @include adaptive-value('padding-right', 30, 15, 1);
    @include adaptive-value('padding-top', 30, 20, 1);
    @include adaptive-value('padding-bottom', 30, 20, 1);

    &::after {
      content: '';
      position: absolute;
      width: 101%;
      height: 101%;
      left: -1px;
      top: 0;
      background: linear-gradient(rgba(255, 255, 255, 0) 0%, #fff 90%);
      z-index: 2;
    }
  }

  &__item {
    width: calc(100% - 10px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    span {
      margin-left: 15px;
    }

    &.done {
      span {
        text-decoration: line-through;
      }
    }
  }

  &__link {
    @extend %family_poppins;

    color: $blue-light;
    font-size: 14px;
    margin-top: 15px;
    cursor: pointer;
    font-weight: 600;
  }
}
