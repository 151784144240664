.navigation-table-container {
  margin-top: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 10px;
  flex: 1 1 auto;
  justify-content: flex-end;

  @media (max-width: 768px) {
    padding: 12px 16px 16px;
    width: unset;
    margin: 0 5px 24px;
    justify-content: start;
  }

  @media (max-width: 500px) {
    justify-content: start;
    padding: 12px 16px 0;
  }

  &__column {
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 68px;

    @media screen and (max-width: 991px) {
      margin: 5px 0;
      justify-content: flex-start;

      &:nth-child(2) {
        .btn-sort {
          margin-right: 10px;
        }
      }
    }

    @media (max-width: 768px) {
      padding: 0 0 16px;

      &:nth-child(1) {
        max-width: 231px;
      }

      .btn-sort {
        &:nth-child(1) {
          margin-right: 8px;
        }
      }
    }

    @media (max-width: 637px) {
      &:nth-child(1) {
        max-width: 100%;
      }
    }

    @media (max-width: 500px) {
      &:nth-child(1) {
        max-width: 100%;
        padding: 0;
      }

      &:nth-child(3) {
        padding: 0;
      }
    }

    &.therapist {
      @media screen and (max-width: 991px) {
        margin: 0;
      }

      @media (max-width: 768px) {
        &:nth-child(1) {
          max-width: 100%;
          padding: 0 24px 0 0;
        }
      }
    }
  }

  &__column:nth-child(1) {
    flex: 1;

    @media (min-width: 430px) and (max-width: 688px) {
      margin-right: 16px;
    }

    &.therapist {
      border-right: 1px solid #e2e2e9;

      .search-clients-field {
        .input-field__input {
          max-width: 100%;
        }
      }

      @media screen and (max-width: 768px) {
        border-right: none;
      }
    }
  }

  &__column:nth-child(2) {
    padding: 0;

    @media (min-width: 430px) and (max-width: 688px) {
      margin-right: 16px;
    }

    &.therapist {
      padding: 0 24px ;
    }
  }

  .sort-block-wrap {
    @media (min-width: 689px) and (max-width: 768px) {
      border-left: 1px solid #e2e2e9;
      border-right: 1px solid #e2e2e9;
      margin: 0 16px;
      padding: 0 16px;
    }

    &__container {
      .btn-sort {
        @media (max-width: 500px) {
          &:nth-child(1) {
            max-width: 105px;
            margin-right: 12px;
          }

          &:nth-child(2) {
            max-width: 139px;
            margin-right: 12px;
          }
        }

        @media (max-width: 365px) {
          &:nth-child(1) {
            max-width: 95px;
            margin-right: 10px;
          }

          &:nth-child(2) {
            max-width: 129px;
            margin-right: 10px;
          }
        }
      }
    }
  }

  .search-clients-field {
    .input-field__input {
      max-width: 390px;

      @media (max-width: 637px) {
        max-width: 100%;
      }
    }
  }

  .btn-filter {
    @media (max-width: 1170px) {
      width: 32px;
      height: 32px;

      span {
        &:nth-child(2) {
          display: none;
        }

        &[role="img"] {
          margin: 0 auto !important;
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    flex-wrap: wrap;
  }
}

.navigation-table-wrap {
  background: #fff;
  border-radius: 10px;
}

.navigation-table-items {
  padding: 0 24px;
  border-top: 0.5px solid #f1f1f5;
}
