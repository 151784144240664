.home-page-container {
  display: flex;
  align-items: flex-start;
  padding-top: 20px;

  @media screen and (max-width: 1350px) {
    flex-wrap: wrap;
  }

  @media screen and (min-width: 768px) {
    margin: 0 -10px;
  }

  @media screen and (max-width: 768px) {
    padding: 4px;
  }

  &__column {
    flex: 0 1 50%;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    @media screen and (max-width: 1350px) {
      flex: 1 1 100%;
      max-width: 100%;
    }

    @media screen and (min-width: 768px) {
      flex-wrap: nowrap;
      padding: 0 10px;
    }

    @media screen and (max-width: 768px) {
      margin-right: 0;
    }

    .card {
      padding: 24px 0 24px 16px;
    }
  }

  // modification
  .session-list__items {
    width: 100%;
    max-width: 100%;
    padding-right: 15px;
  }

  &__future {
    .session-item {
      display: flex;

      &__footer {
        align-items: stretch;
        justify-content: space-between;
        margin: 15px 0 0;

        > * {
          flex: 0 1 48%;
        }
      }

      &__item_last {
        padding-top: 14px;
      }

      @media screen and (max-width: 768px) {
        .session-item__footer {
          margin-top: 0;
        }
      }

      @media screen and (max-width: 450px) {
        flex-wrap: wrap;
      }

      @media screen and (max-width: 480px) {
        .session-item__footer > *:not(:last-child) {
          margin-bottom: 0 !important;
        }
      }
    }
  }

  &__future {
    .session-item {
      display: flex;

      &__footer {
        align-items: stretch;
        justify-content: space-between;
        margin: 15px 0 0;

        > * {
          flex: 0 1 48%;
        }
      }

      @media screen and (max-width: 768px) {
        .session-item__footer {
          margin-top: 0;
        }
      }

      @media screen and (max-width: 450px) {
        flex-wrap: wrap;
      }

      @media screen and (max-width: 480px) {
        .session-item__footer > *:not(:last-child) {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}
// TODO remove after redesign
.home-page-box {
  padding: 16px 24px;
  border-radius: 20px;
  background-color: #fff;

  @media screen and (max-width: 768px) {
    padding: 10px 18px;
  }
}
