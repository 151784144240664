@import './main.scss';
@import '../colors.scss';

%title {
  @extend %family_poppins;

  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #000;
}

%sub_title {
  @extend %family_poppins;

  font-weight: 600;
  font-size: 14px;
  color: #000;
}

%label {
  @extend %family_poppins;

  font-weight: 400;
  font-size: 14px;
  color: $black;
}
