@import '../../node_modules/reset-css/reset.css';
@import './fonts';
@import './buttons';
@import './mask-layout';
@import './antd';
@import './inputs';

.container {
  margin: 0 auto;
  max-width: 1200px;
}

.container__center {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lightClose {
  display: block;
}

.darkClose {
  display: none;
}

#root {
  .custom-style {
    &.login-page,
    &.registration-page {
      .preview-text {
        &__description {
          min-width: 550px;
          max-width: unset;

          @media (max-width: 550px) {
            min-width: auto;
            font-size: 14px;
          }
        }

        &__title {
          @media (max-width: 550px) {
            font-size: 29px;
          }
        }
      }

      .auth-template__footer {
        @media (min-height: 750px) {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
    }

    .box {
      background: url('../assets/image/headers/svg/background.svg') no-repeat bottom/contain;
      background-size: cover;
      min-height: calc(100vh - 70px);

      .preview-container {
        background: unset;

        @media (max-width: 767px) {
          margin: -50px 10px 0;
        }

        .preview-text {
          &__title {
            color: $darkBlue;
          }

          &__after-title {
            color: $darkBlue;
          }

          &__description {
            color: $darkBlue;
          }
        }

        &__header {
          background: unset;

          &::before {
            content: none;
          }
        }

        .text-link {
          color: $darkBlue;
          font-weight: 500;
        }

        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: $pink !important;
          border-color: $pink !important;
        }

        .ant-checkbox-checked::after {
          border-color: $pink !important;
        }

        .input-field {
          &__input input,
          .ant-select-selector {
            border-color: $darkBlue;

            .input-field .ant-select-selector .ant-select-selection-item {
              background: unset;
            }

            .select-tag,
            .ant-select-selection-item {
              background: $lightBlue;
              color: $darkBlue;

              &__label {
                color: $darkBlue;
              }

              .lightClose {
                display: none;
              }

              .darkClose {
                display: block;
              }
            }
          }

          .ant-select-item-option-selected {
            background: $lightBlue !important;
            color: $darkBlue !important;

            .ant-checkbox-inner {
              background-color: $teal !important;
              border-color: $teal !important;
            }
          }

          &__label {
            color: $darkBlue;
            font-weight: 500;
          }
        }

        .form-country-checker {
          color: $pink;
        }
      }

      .simple-template {
        &__body {
          background: unset;

          .card-therapist__value,
          .card-group__name-text,
          .show-more {
            color: $darkBlue;
          }
        }

        &__before {
          span {
            a {
              color: $pink;
            }
          }
        }
      }

      .btn-form,
      .btn-item {
        background: $pink;
      }

      .btn-light_cancel {
        color: $pink;
      }

      .btn-form[disabled] {
        background: #b5b5be;
      }

      &.stripe-container__body {
        .stripe-det {
          .toggle::after {
            background: $darkBlue;
          }
        }

        .stripe-list {
          &__item.active {
            background: $darkBlue;
          }

          &__discount {
            background-color: $lightBlue;
            color: $darkBlue;
          }

          &__save {
            color: $teal;
          }
        }

        .payment__savigns {
          color: $teal;
        }

        .promo__question,
        .promo__applied,
        .stripe-det__frequently {
          color: $darkBlue;
        }
      }

      .small-group-card__avatar-txt {
        background-color: $darkBlue;
      }

      &.first-consultation {
        padding: 0 10px 20px;
        min-height: 100vh;
      }

      .first-consultation {
        &__logo {
          background: url('../assets/image/headers/png/wordmark-ogt.png') center/contain no-repeat;
          width: 200px;
          height: 100px;
        }

        &__about {
          &__content {
            padding: 0;
          }

          padding: 0 35px;
        }

        &__title {
          color: $darkBlue;
        }

        &__subtitle {
          padding: 8px 35px;

          b {
            font-weight: 500;
          }
        }

        &__about__text {
          text-align: center;
          color: $darkBlue;
        }

        &__container {
          height: auto;
          justify-content: start;

          .iframe {
            padding: 0 35px;
            width: 100%;

            iframe {
              max-height: 500px;
            }
          }
        }
      }

      .form-consultation {
        .input-field {
          .ant-select-selector {
            border-color: $darkBlue !important;
            box-shadow: unset;

            .ant-select-selection-item {
              background: unset;
            }
          }

          .form-select__error {
            .ant-select-selector {
              border-color: $red3 !important;
            }
          }

          &__input_error .ant-select-selection-search input {
            border: unset !important;
          }
        }
      }
    }

    &.consultation-page {
      .auth-mask-layout {
        position: relative;
      }
    }
  }
}

.question__icon span {
  background: $teal;
}

.stripe-container__promo-alert {
  background: $darkBlue;
}

.consultation-page,
.login-page,
.choice-group-page,
.registration-page {
  height: 100vh;
  overflow: auto;
}

.choice-group-page,
.restart-subscriptions-groups {
  &.custom-style-group {
    .change-group-template__body {
      padding: 0 5px;
    }

    .select-groups {
      &__wrapper_prev {
        padding: 0;
      }

      &__btn {
        left: -70px;

        @media (max-width: 1430px) {
          left: -38px;
          width: 48px;
          height: 48px;
        }

        @media (max-width: 1360px) {
          left: 1px;
        }

        @media (max-width: 1280px) {
          width: 56px;
          height: 56px;
          top: -103px;
          left: 21px;
        }
      }

      &__title {
        @media (max-width: 374px) {
          font-size: 24px;
        }
      }
    }

    .card-group__status {
      display: none;
    }

    .card-group__about-text {
      min-height: 120px;
    }

    .card-therapist__description {
      min-height: 150px;
    }
  }
}

.loading-page {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    transform: translateX(-40%);
  }

  img {
    transform: translateX(90.5%);
  }

  .ant-spin-container {
    transform: translateX(-50%);
  }
}

.notfound-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title-back {
  padding-left: 25px;
  position: relative;
  cursor: pointer;

  &::before {
    content: url('./image/common/back.svg');
    position: absolute;
    top: 2px;
    left: 0;
  }
}

// statuses
.mild {
  color: #efac00;
}

.minimal {
  color: #4fb8ab;
}

.moderate {
  color: #f17e31;
}

.high {
  color: #f13131;
}
