@import '../../../assets/mixin.scss';
@import '../../../assets/texts.scss';
@import '../../../assets/colors.scss';

.clients-table {
  // min-width: 1200px;
  min-height: 510px;
  background-color: #fff;
  // padding: 24px 30px;

  .ant-table {
    height: 550px;
  }

  .ant-table-cell {
    border-bottom: none;
  }

  .ant-table-header {
    background-color: #f1f1f5;
    border-radius: 10px;
  }

  .ant-table-thead {
    th {
      background-color: #f1f1f5;
      color: $grey !important;

      @extend %family_poppins;
    }

    th:first-child {
      border-top-left-radius: 10px !important;
      border-bottom-left-radius: 10px !important;
    }

    th:nth-last-child(2) {
      border-top-right-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
    }

    .ant-table-cell-scrollbar {
      display: none;
    }
  }

  tbody {
    .ant-table-cell {
      //height: 48px;
      padding: 20px 15px;
    }
  }

  tr {
    cursor: pointer !important;

    &:last-child {
      .table-item-box_info-cart_list {
        top: -150px;
        right: 0;
        height: 150px;
      }
    }

    &:nth-last-child(2) {
      .table-item-box_info-cart_list {
        top: -150px;
        right: 0;
        height: 150px;
      }
    }

    &:nth-child(3) {
      .table-item-box_info-cart_list {
        top: 30px;
        right: 0;
        height: auto;
        max-height: 150px;
      }
    }

    &:nth-child(2) {
      .table-item-box_info-cart_list {
        top: 30px;
        right: 0;
        height: auto;
        max-height: 150px;
      }
    }
  }

  thead {
    .ant-table-cell {
      padding: 14px 0 16px 17px !important;

      &::before {
        background: none !important;
      }
    }
  }

  .ant-table-body {
    overflow-y: hidden;
    max-height: 510px !important;

    @include scrollbars(10px, #989898, $white);

    &::-webkit-scrollbar {
      height: 10px;
    }
  }

  .ant-spin-nested-loading {
    min-height: 510px;

    > .ant-spin-container {
      > .ant-table {
        > .ant-table-container {
          border-radius: 10px;
          padding: 10px;
          border: 1px solid #e2e2ea;
        }
      }
    }
  }

  .ant-table-pagination {
    margin: 65px 0 0 !important;
  }

  .ant-pagination-item {
    margin: 0 8px !important;

    a {
      color: $grey;
    }
  }

  .ant-pagination-item-active {
    border-radius: 20px;
    background: $blue-light;

    a {
      color: $white !important;
    }
  }

  .ant-pagination-options-quick-jumper {
    color: $grey;

    input {
      border-radius: 10px;
    }
  }
}
