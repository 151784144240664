@import '../../../../assets/colors.scss';
@import '../../../../assets/texts/consultation_text.scss';

.admin-info-client-container {
  min-height: 480px;
  display: flex;
  flex: 1 1;

  @media screen and (max-width: 1200px) {
    flex-wrap: wrap;
  }

  &__column {
    display: flex;
    align-items: flex-start;

    @media screen and (max-width: 1200px) {
      margin: 5px;
      width: 100%;
    }

  }

  &__column:last-child {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 23px;
    width: 100%;

    @media screen and (max-width: 1200px) {
      margin: 5px;
      width: 100%;
    }

    .account-edit-block,
    .account-details {
      padding: 7px 38px;
    }
  }

  &__edit-btn {
    cursor: pointer;
    padding: 7px 17px 7px 12px;
    background: #fafafb;
    border: 1px solid #e2e2ea;
    border-radius: 5px;

    span {
      margin-left: 5px;
    }

    @extend %item_active;
  }

  &__right {
    display: flex;
    justify-content: flex-end;
  }
  // tabs
  .info-tabs {
    width: 100%;
    background: white;
    border-radius: 10px;
    padding: 0 16px 30px;

    &__item_assessments {
      .ant-spin-container {
        overflow: auto;
      }
    }

    @media screen and (max-width: 1200px) {
      max-width: 655px;
    }

    .ant-tabs-tab {

      .ant-tabs-tab-btn {
        @extend %item;
      }
    }

    .ant-tabs-nav {
      padding: 43px 14px 0;
      @media screen and (max-width: 768px) {
        padding: 0 5px;
      }
    }

    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        @extend %item_active;
      }
    }

    .ant-tabs-ink-bar {
      border-bottom: 2px solid $blue-light;
    }

    .ant-tabs-nav::before {
      border-bottom: none !important;
    }

    .ant-tabs-tab+.ant-tabs-tab {
      margin: 0 0 0 40px;
    }
  }
}

.consultation-info-container {
  display: flex;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }

  &__avatar {
    margin: 14px 33px 14px 14px;
    width: 90px;
    height: 90px;
    border-radius: 10px;
    background: $grey4;
  }

  &__avatar-fake {
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: $blue-light;

    @extend %fake-avatar;
  }

  &__img {
    width: 90px;
    height: 90px;
    border-radius: 10px;
  }

  &__block {
    margin: 14px 0 0;
    display: flex;
    flex-direction: column;
  }

  &__title {
    @extend %block_title;
  }
}
