$vw-viewport: 1920;
$vh-viewport: 1080;

@function get-vw($font) {
  $vw-context: $vw-viewport * 0.01 * 1px;

  @return $font / $vw-context * 1vw;
  @return $font;
}

@function letter-spacing($letter-spacing) {
  @return ($letter-spacing / 1000) * 1em;
}
