@import '../../../assets/colors.scss';
@import '../../../assets/texts/progress_text.scss';

.progress-tabs {
  .ant-tabs-tab {
    @extend %family_poppins;

    padding: 5px 0;
    min-width: 260px;

    @include adaptive-value('min-width', 260, 80, 1);

    .ant-tabs-tab-btn {
      font-weight: 500;
      color: $grey;
      font-size: 20px;

      @include adaptive-value('font-size', 20, 13, 1);
    }

    &.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $blue-light;
      font-weight: 600;
    }

    & + .ant-tabs-tab {
      margin-left: 32px;

      @include adaptive-value('margin-left', 32, 10, 1);
    }
  }

  .ant-tabs-nav {
    padding: 0;

    @media screen and (max-width: 768px) {
      padding: 0 5px;
    }
  }

  .ant-tabs-ink-bar {
    border-bottom: 2px solid $blue-light;
  }

  .ant-tabs-nav::before {
    border-bottom: none !important;
  }
}
