@import '../../../../../assets/texts/session_item_text.scss';
@import '../../../../../assets/texts/modal_change_group.scss';
@import '../../../../../assets/colors.scss';
@import '../../../../../assets/mixin';

.session-item {
  padding: 22px 20px 17px;
  border-radius: 10px;
  border: 1px solid #e2e2ea;
  min-height: 160px;
  position: relative;

  @media screen and (max-width: 991px) {
    padding: 22px 12px 12px;
  }

  &_bg {
    min-height: 214px;
  }

  &__wrap {
    display: flex;

    @media screen and (max-width: 450px) {
      flex-wrap: wrap;
    }
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &__status-history {
    display: none;
    width: 100px;
    position: absolute;
    top: 3px;
    right: 0;
    z-index: 1;
    padding: 0 20px;
    border-radius: 5px 4px 0 5px;
    font-size: 12px;
    color: $white-grey;
    font-weight: 500;

    &.cancel {
      display: block;
      background: $red4;
      border: 1px solid $red4;
    }

    &.rescheduled {
      display: block;
      background: $green;
      border: 1px solid $green;
    }
  }

  &__avatar {
    align-self: flex-start;
    height: 120px;
    width: 120px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: $blue-light;
    @extend %therapist-avatar;

    @media screen and (max-width: 768px) {
      height: 90px;
      width: 90px;
    }

    @media screen and (max-width: 450px) {
      margin-bottom: 16px;
      margin-right: 5px;
    }
  }

  &__event-type {
    @extend %family_poppins;

    position: absolute;
    bottom: 0;
    font-size: 11px;
    line-height: 150%;
    padding: 3px 10px;
    text-align: center;
    color: $white;

    &.webinar {
      background: $green;
    }

    &.session {
      background: $red4;
    }
  }

  &__body {
    min-height: 120px;
    flex: 1 1 auto;
    padding-left: 20px;

    @media screen and (max-width: 768px) {
      min-height: 100px;
      padding-left: 14px;
    }

    @media screen and (max-width: 450px) {
      min-height: auto;
      padding-left: 0;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 7px;
  }

  &__time-block {
    margin-bottom: 15px;

    @include adaptive-value('margin-bottom', 15, 8, 1);
  }

  &__members {
    @media screen and (min-width: 600px) {
      margin-bottom: 20px;

      @include adaptive-value('margin-bottom', 20, 1, 1);
    }

    &_future {
      @media screen and (min-width: 600px) {
        margin-bottom: 0;
        margin-top: 15px;

        @include adaptive-value('margin-top', 15, 1, 1);
      }
    }
  }

  &__title {
    @extend %title;

    padding-right: 10px;
  }

  &__item {
    display: flex;
  }

  &__label {
    @extend %label;
  }

  &__value {
    margin: 0 5px;

    @extend %value;

    sup {
      font-size: 11px;
    }
  }

  &__footer {
    display: flex;
    align-items: center;

    &.footer-top {
      display: none;

      @media screen and (min-width: 600px) {
        display: flex;
      }
    }

    &.footer-bottom {
      display: none;

      @media screen and (max-width: 600px) {
        display: flex;
        max-width: 300px;
      }
    }

    @media screen and (min-width: 480px) {
      margin: 0 -7px;
    }

    & > * {
      flex: 0 1 50%;
      padding: 0 7px;
      max-width: 160px !important;
    }

    @media screen and (max-width: 600px) {
      margin-top: 16px;

      & > * {
        flex-grow: 1;
      }
    }

    @media screen and (max-width: 480px) {
      flex-wrap: wrap;

      & > * {
        padding: 0;
        flex: 1 1 100%;

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
  }

  .btn-item {
    max-width: 100%;

    @media screen and (max-width: 768px) and (min-width: 480px) {
      padding: 5px;
      height: 28px;
      min-height: 28px;
    }
  }

  // TODO DELETE THIS CLASS
  &__begin {
    margin: 10px 0;
    padding: 0 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: rgba(255, 151, 74, 0.1);
  }

  &__begin-text {
    @extend %begin;
  }

  &__row {
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__avatar-img {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
  }

  &__avatar-txt {
    font-size: 100px;
  }

  &__home-avatar-txt {
    font-size: 56px;
  }

  &__block {
    display: flex;
  }

  &__column-end {
    width: 40%;
    align-items: flex-end;
    justify-content: flex-end;
  }

  &__event-label {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background: #fafafb;

    @extend %begin;
  }
}
