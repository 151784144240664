@import '../../../../assets/texts/session_item_text.scss';

.session-list {
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &.content {
    height: 610px;

    @media screen and (max-width: 768px) {
      height: auto;
    }
  }

  &__items {
    max-width: 460px;
    overflow-y: auto;
    line-height: 120%;

    @media screen and (max-width: 768px) {
      width: 100%;
      max-height: 100% !important;
    }
  }

  &__label {
    margin: -4px 0;

    @extend %list-label;
  }

  .session-item {
    margin: 20px 0;
  }
}
