@import '../../../../assets/texts/previews_text';
@import '../../../../assets/colors';
@import '../../../../assets/mixin';

.recommended-groups-from {
  max-width: 560px;
  padding-top: 50px;
  margin-bottom: 30px;

  @media screen and (max-width: 580px) {
    max-width: calc(100vw - 20px);
  }

  @include adaptive-value('padding-top', 50, 10, 1);


  &__title {
    @extend %family_roboto;
    font-weight: 500;
    font-size: 22px;
    letter-spacing: 0.01em;
    line-height: 26px;
    text-align: center;
    color: $darkBlue;
    margin-bottom: 30px;
  }

  @media screen and (max-width: 768px) {
    margin: 0 10px 30px;
  }
}

.form-restart {
  padding: 36px 30px;
  background: $white;
  border-radius: 15px;

  @include adaptive-value('padding-top', 36, 10, 1);
  @include adaptive-value('padding-bottom', 36, 10, 1);
  @include adaptive-value('padding-left', 30, 10, 1);
  @include adaptive-value('padding-right', 30, 10, 1);


  &:focus-within label:not(.input-field__label_error) {
    color: $darkBlue;
  }


  .input-field {
    margin-top: 12px;

    &__input:not(.input-field__input_error) input {
      border-color: $darkBlue;
    }
  }

  .btn-form {
    margin-top: 12px;
  }

  .select-tag,
  .ant-select-selection-item {
    background: $lightBlue;
    color: $darkBlue;

    &__label {
      color: $darkBlue;
    }

    .lightClose {
      display: none;
    }

    .darkClose {
      display: block;
    }
  }
}