@import '../../../../assets/texts/account_text.scss';

.pay-info {
  padding: 48px 0 63px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $white;
  color: $white;

  &__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__title {
    @extend %title;
  }

  &__name {
    @extend %item;

    color: $white;
  }

  &__expires {
    @extend %form_title ;

    color: $white;
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 246px;
    width: 100%;
    border-radius: 8px;
    background: linear-gradient(to right, #3754b2 10.8%, #142c81 75.02%);
    padding: 5px 19px 16px;
  }

  &__header-text {
    display: flex;
    align-items: center;
  }

  &__dots {
    height: 5px;
    width: 5px;
    background-color: $white;
    border-radius: 50%;
    margin: 8px 3px 0;

    &:first-child {
      margin-left: 0;
    }
  }

  &__text {
    @extend %card_title;
  }

  &__edit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 56px;
    width: 100%;
    max-height: 20px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.2);
    padding: 2px 6.5px 2px 7.5px;
    cursor: pointer;
  }

  &__up {
    @extend %label_profile;

    font-weight: 500;
    color: $white;
  }
}
