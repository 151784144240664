@import './main.scss';
@import '../colors.scss';
@import '../functions.scss';

%item {
  @extend %family_poppins;

  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  letter-spacing: letter-spacing(7.14);
  color: $grey;
}

%item_active {
  @extend %item;

  color: $black-light !important;
}

%edit_title {
  @extend %item;

  color: #000 !important;
  font-weight: 600 !important;
}

%btn_edit {
  @extend %family_poppins;

  font-size: 14px;
  line-height: 21px;
}

%prev_title {
  @extend %family_poppins;

  font-size: 16px;
  line-height: 25px;
  font-weight: 600;
  letter-spacing: letter-spacing(6.25);
  color: $grey3;
}

%label {
  @extend %family_roboto;

  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: $grey;
}

%value {
  @extend %family_roboto;

  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  letter-spacing: letter-spacing(7.14);
  color: $black-light;
}

%form_title {
  @extend %family_poppins;

  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: letter-spacing(6.25);
  color: $black-light;
}

%name_profile {
  @extend %family_poppins;

  font-size: 16px;
  line-height: 25px;
  font-weight: 600;
  letter-spacing: letter-spacing(6.25);
  color: $black;
}

%text_success {
  @extend %family_roboto;

  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  letter-spacing: letter-spacing(6.25);
  color: $black;
}

%title_success {
  @extend %family_poppins;

  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  letter-spacing: letter-spacing(6.25);
  color: $blue-light;
}

%title_ads {
  @extend %text_success;

  letter-spacing: normal;
  font-weight: 500;
  color: $black-light;
}

%label_profile {
  @extend %family_roboto;

  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #b5b5be;
}

%value_profile {
  @extend %family_roboto;

  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  letter-spacing: letter-spacing(7.14);
  color: #44444f;
}

%text_account {
  @extend %family_roboto;

  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  letter-spacing: letter-spacing(7.14);
  color: #44444f;
}

%title {
  @extend %family_poppins;

  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  color: $black;
}

%card_title {
  @extend %family_poppins;

  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: letter-spacing(7.14);
  color: $white;
}

%card_non-active {
  @extend %family_poppins;

  font-size: 10px;
  color: $white;
}

%desc {
  @extend %family_roboto;

  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: letter-spacing(7.14);
  color: #707070;
}

%desc2 {
  @extend %family_roboto;

  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  letter-spacing: letter-spacing(4.17);
  color: $black;
}

%select_zoom_title {
  @extend %family_poppins;

  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: $black-light;
}

%archive_button_text {
  @extend %family_poppins;

  font-weight: normal;
  font-size: 12px;
  text-align: center;
  color: #1891e5;
}

%delete_button_text {
  @extend %family_poppins;

  font-weight: normal;
  font-size: 12px;
  text-align: center;
  color: #e2351e;
}
