@import '../../../assets/colors';
@import '../../../assets/texts';

.approve {
  margin-top: 16px;
  padding: 24px 24px 14px;
  border-radius: 10px;
  width: 100%;
  background-color: $white;

  &:empty {
    display: none;
  }

  @media screen and (max-width: 1200px) {
    max-width: 655px;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;

    @media screen and (max-width: 500px) {
      flex-direction: column;
    }

    .btn-form {
      background-color: white;
      width: 32%;
      font-size: 12px;

      @media screen and (max-width: 500px) {
        width: 100%;
        margin-bottom: 8px;
      }

      &:nth-child(1) {
        color: $green;
        border: 1px solid $green;
      }

      &:nth-child(2) {
        color: $orange;
        border: 1px solid $orange;
      }

      &:nth-child(3) {
        color: $red;
        border: 1px solid $red;
      }
    }
  }

  .btn-form[disabled] {
    color: $black-light;
    border: 1px solid $black-light;
    cursor: not-allowed;
  }

  &__text {
    padding-bottom: 10px;
    @extend %form_desc_text;
    color: $black;
    font-weight: 400;

    span {
      font-weight: 500;
    }
  }
}