@import '../../../assets/texts/form_btn_text.scss';
@import '../../../assets/colors.scss';

.field-container {
  display: flex;
  justify-content: space-between;
  margin: -11px 0 22px;

  &__label {
    @extend %form_desc_text;

    font-weight: 400;
  }

  @media (max-width: 576px) {
    margin: 0 0 18px;
  }
}

.field-container_error {
  margin: -11px 0 2px;

  .ant-checkbox-inner {
    border-color: $red;
  }

  &__label {
    @extend %form_desc_text;

    font-weight: 400;
  }

  @media (max-width: 576px) {
    margin: 0 0 18px;
  }
}
