@import '../../../../assets/texts/questions_text';
@import '../../../../assets/texts';
@import '../../../../assets/colors';
@import '../../../../assets/mixin';

.edit-group-container, .edit-details-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__title {
    padding: 30px 42px 25px;
    text-align: center;

    @include adaptive-value(padding-left, 42, 10, 1);
    @include adaptive-value(padding-right, 42, 10, 1);

    @extend %title;
  }

  &__header-undertitle {
    @extend %form_desc_text;
    font-weight: 300;
    margin-top: 8px;
    color: $grey9;
  }

  &__main {
    padding: 24px 30px 28px;
    background: rgba($background-light, 0.5);
    border-top: 0.5px solid $border;
    border-bottom: 0.5px solid $border;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @include adaptive-value(padding-left, 30, 5, 1);
    @include adaptive-value(padding-right, 30, 5, 1);

    .input-field__label {
      background-color: $background-light;
      border-radius: 15px;
    }

    .ant-input {
      border-radius: 10px;
      padding: 10px 20px 0;
      resize: none;
      background-color: $background-light;
    }

    input {
      background-color: rgba($background-light, 0.5);
    }

    &.photo {
      align-items: center;

      span.ant-upload {
        position: relative;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    padding: 24px 30px;
    justify-content: center;

    @include adaptive-value(padding-left, 30, 5, 1);
    @include adaptive-value(padding-right, 30, 5, 1);

    .cancel-button {
      padding-bottom: 0;
    }
  }
}