$grey: #92929d;
$grey2: #e2e2ea;
$grey3: #696974;
$grey4:#626262;
$grey5: #b5b5be;
$grey6: #e2e2ea;
$grey7: #535353;
$grey8: #d5d5dc;
$grey9: #707070;
$grey10: #3a3a3a;
$background-light: #f5f6fa;
$border: #e6e7e9;
$black: #171725;
$black-light: #44444f;
$orange: #ff974a;
$red: #fc5a5a;
$red2: #e51820;
$red3: #d3452e;
$red4: #e2351e;
$blue: #6c63ff;
$blue-light: #1891e5;
$blue-light2: #50b5ff;
$green: #82c43c;
$white: #fff;
$white-grey: #fafafb;
$shadow: #cdcdd4;
$darkBlue: #3D5581;
$pink: #E19D89;
$lightBlue: #D0E6F0;
$teal: #88B3B5;
