@import './mixin';

.ant-spin-blur {
  overflow: visible;
}

.ant-checkbox-inner {
  background: $white-grey !important;
  border: 2px solid #a4a6a9 !important;
  border-radius: 3px !important;

  @media screen and (max-width: 1060px) {
    &::after {
      top: 40%;
      left: 17%;
    }
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $blue-light !important;
  border-color: $blue-light !important;
  border-radius: 3px !important;
}

.ant-dropdown {
  overflow: hidden;
  border-radius: 8px 0 8px 8px !important;
  box-shadow: 0 10px 30px rgba(10, 30, 135, 0.1) !important;
  margin-top: 20px;

  .ant-dropdown-arrow {
    display: none !important;
  }

  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: none !important;
  }

  .ant-menu-item {
    display: flex;
    align-items: center;
    padding: 0 21px !important;
    min-height: 40px;
    height: auto !important;
  }
}

.ant-menu {
  overflow: hidden;
}

.ant-dropdown-show-arrow.ant-dropdown-placement-bottomRight {
  padding: 0;
}

// aside menu
.ant-drawer-body {
  padding: 0 !important;
}

// spin
.ant-spin-nested-loading > div > .ant-spin {
  max-height: max-content;

  @include safari-only {
    max-height: initial;
  }
}

.ant-modal-wrap {
  padding: 15px;
}

.check-in-modal {
  &.ant-modal {
    max-width: 560px !important;
    width: 100% !important;
  }

  .ant-modal-content {
    border-radius: 15px;
    overflow: hidden;
  }

  .ant-modal-body {
    padding: 30px 30px 15px;
  }

  .ant-modal-close {
    right: 10px;
    top: 10px;
  }
}
