@import '../../../../assets/texts/questions_text.scss';
@import '../../../../assets/colors.scss';
@import '../../../../assets/mixin.scss';

.upload-history {
  &__title {
    padding: 30px 42px 25px;
    text-align: center;

    @include adaptive-value(padding-left, 42, 10, 1);
    @include adaptive-value(padding-right, 42, 10, 1);

    @extend %title;
  }

  &__main {
    padding: 24px 30px 36px;
    background: rgba($background-light, 0.5);
    border-top: 0.5px solid $border;
    border-bottom: 0.5px solid $border;

    @include adaptive-value(padding-left, 30, 5, 1);
    @include adaptive-value(padding-right, 30, 5, 1);

    .input-field {
      margin-top: 10px;

      &__label {
        background: #fafafc !important;
      }
    }

    .input-datepicker {
      background: rgba(255, 255, 255, 0.5) !important;
    }
  }

  &__buttons {
    padding: 24px 30px;

    @include adaptive-value(padding-left, 30, 5, 1);
    @include adaptive-value(padding-right, 30, 5, 1);

    button:nth-child(2) {
      margin-top: 17px;
    }
  }
}