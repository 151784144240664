@import '../../../../../assets/texts/questions_text.scss';

.reschedule-session-modal {
  width: 560px !important;

  @media screen and (max-width: 768px) {
    width: auto !important;
    max-width: 560px;
  }

  .ant-modal-body {
    padding: 0 !important;
    background: $white;
    border-radius: 15px;
  }

  .ant-modal-content {
    min-height: 340px;
    border-radius: 15px;
  }

  .ant-spin-nested-loading {
    min-height: 340px;
  }
}

.reschedule-session-container {
  display: flex;
  flex-direction: column;

  &__title {
    padding: 30px 42px 25px;
    text-align: center;

    @extend %title;
  }
  &__description {
    position: relative;
    top: -10px;
    text-align: center;
    @extend %title;
    font-weight: normal;
    font-size: 14px;
    padding-bottom: 30px;
    max-width: 500px;
    margin: 0 auto;
  }

  &__block {
    display: flex;
    flex-wrap: wrap;
  }

  &__column {
    width: 49%;
    display: flex;
    flex-direction: column;
  }
}
