@import './main_header_text.scss';
@import '../colors.scss';

%profile_name {
  @extend %simple_name_text;

  color: $black;
}

%item_name {
  @extend %simple_name_text;

  color: $black;
}
