.header-page-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    padding: 5px;
  }

  .text-page-header-before {
    margin: 0 5px;
  }
}

.body-page-container {
  // padding: 15px 50px 0 50px;
  padding-top: 24px;

  @media screen and (max-width: 768px) {
    // padding: 5px;
  }
}
