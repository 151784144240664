@import './colors.scss';
@import './functions.scss';

%family_poppins {
  font-family: 'Poppins', sans-serif;
}

%family_roboto {
  font-family: 'Roboto', sans-serif;
}

// previews texts
%preview_title {
  @extend %family_poppins;

  font-size: 56px;
  line-height: 26px;
  font-weight: 400;
  letter-spacing: letter-spacing(4.17);
  color: $white;
}

%preview_description {
  @extend %family_roboto;

  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  letter-spacing: letter-spacing(6.25);
  color: $white;

  @media (max-width: 374px) {
    font-size: 14px;
  }
}

// input field texts
%field_label {
  @extend %family_roboto;

  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #707070;
}

%field_label_error {
  @extend %family_roboto;

  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: $red;
}

%field_input {
  @extend %family_roboto;

  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  letter-spacing: letter-spacing(7.14);
  color: $black-light;
}

%field_input_placeholder {
  @extend %family_roboto;

  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  letter-spacing: letter-spacing(7.14);
  color: $grey8;
}

// form btn text
%btn_form_text {
  @extend %family_poppins;

  font-size: 16px;
  line-height: 25px;
  color: $white;
}

%form_desc_text {
  @extend %family_roboto;

  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  letter-spacing: letter-spacing(7.14);
  color: $grey3;
}

%form_desc_consultation_text {
  @extend %family_poppins;

  font-size: 22px;
  line-height: 26px;
  font-weight: 500;
  letter-spacing: letter-spacing(7.14);
  color: $white;
}

// for waiting flow
%waiting_text {
  @extend %family_poppins;

  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: letter-spacing(6.25);
  color: $black;
}

%waiting_main_text {
  @extend %family_poppins;

  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  letter-spacing: letter-spacing(6.25);
  color: $black;
}

%waiting_title {
  @extend %family_poppins;

  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.01em;
  color: $black;
}
