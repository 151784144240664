@import '../../../assets/texts/previews_text.scss';
@import '../../../assets/colors.scss';

.auth-template {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: $background-light;

  &__footer {
    margin-top: 0;
    display: flex;
    flex: 1 0 auto;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 28px;

    @extend %template_footer;
  }
}
