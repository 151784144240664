@import './main.scss';
@import '../colors.scss';

%header {
  @extend %family_poppins;

  font-weight: bold;
  font-size: 24px;
  letter-spacing: letter-spacing(6.25);
}

%username {
  @extend %family_poppins;

  font-weight: 500;
  text-align: center;
  letter-spacing: letter-spacing(6.25);
  font-size: 28px;
}
