@import '../../../../assets/texts/previews_text';
@import '../../../../assets/colors';
@import '../../../../assets/mixin';


.restart-subscriptions-questions {
  min-height: calc(100vh - 70px);
  display: grid;
  grid-template-rows: 1fr 50px;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 768px) {
      padding: 30px 10px 0;
    }
  }

  &__footer {
    text-align: center;
    @extend %template_footer;
  }

  &__title {
    @extend %preview_title;
    margin-bottom: 30px;

    color: $darkBlue !important;

    @media screen and (max-width: 768px) {
      font-size: 32px;
      line-height: 46px;
    }
  }

  &__main {
    width: 560px;
    max-width: 560px;
    min-height: 200px;
    border-radius: 15px;
    background-color: $white;
    margin-bottom: 30px;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  &__main-title {
    @extend %family_poppins;
    padding: 30px 30px 0;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 28 /  18 * 100%;
    color: $black;

    @include adaptive-value('padding-left', 30, 10, 1);
    @include adaptive-value('padding-right', 30, 10, 1);
    @include adaptive-value('padding-top', 30, 10, 1);
  }

  &__main-subtitle {
    @extend %family_roboto;
    padding: 6px 30px 20px;
    text-align: center;
    font-size: 14px;
    color: $grey9;
  }

  &__group-container {
    padding: 60px 30px 50px;
    background: rgba($background-light, 0.5);
    border-top: 0.5px solid $border;
    border-bottom: 0.5px solid $border;

    @include adaptive-value('padding-left', 30, 10, 1);
    @include adaptive-value('padding-right', 30, 10, 1);
    @include adaptive-value('padding-top', 60, 30, 1);
    @include adaptive-value('padding-bottom', 50, 20, 1);

    .small-group-card {
      border: 1px solid $border;
      border-radius: 10px;
      background-color: $white;
      padding: 20px;
      width: 100%;

      &__avatar {
        height: 100px;
        width: 100px;
      }

      .begins {
        &__text {
          font-size: 12px;
        }
      }
    }
  }

  &__button-container {
    padding: 24px 24px 8px;

    @include adaptive-value('padding-left', 24, 10, 1);
    @include adaptive-value('padding-right', 24, 10, 1);
    @include adaptive-value('padding-top', 24, 10, 1);
  }
}