@import '../../../../assets/dropdown.scss';

.form-edit {
  @import '../../../../assets/selects.scss';

  .input-field-other {
    margin-bottom: 18px;
  }

  .input-field-other_error {
    .ant-select-selector {
      border: 1px solid $red !important;
    }
  }

  .ant-select-selector {
    background-color: #f5f6fa !important;
  }
}
