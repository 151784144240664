.upload-history-modal {
  width: 560px !important;

  @media screen and (max-width: 768px) {
    width: auto;
    max-width: 100% !important;
  }

  .ant-modal-content {
    min-height: 410px;
    border-radius: 15px;
  }

  .ant-spin-nested-loading {
    min-height: 410px;
  }

  .ant-modal-body {
    padding: 0 !important;
  }

  &__icon {
    cursor: pointer;
  }
}
