@import '../../../../assets/colors.scss';
@import '../../../../assets/mixin';
@import '../../../../assets/texts/stripe_text.scss';

.btn-light {
  border: white;
  margin-top: 12px;
  font-size: 16px;

  &:focus {
    border: white;
  }
}

.subscription-container {
  display: flex;
  flex-direction: column;
  border: 1px solid $border;
  border-radius: 10px;
  padding: 24px 30px 10px;
  margin-top: 10px;

  @include adaptive-value('padding-top', 24, 14, 1);
  @include adaptive-value('padding-left', 30, 14, 1);
  @include adaptive-value('padding-right', 30, 14, 1);

  @media screen and (min-width: 991px) {
    margin-top: 60px;
  }

  &__update {
    border: none;
    border-radius: 0;
    padding: 0;
    margin-top: 10px;
  }

  &__wrapper {
    border: 1px solid $border;
    border-radius: 10px;
    padding: 24px 30px 10px;
    margin-top: 60px;
  }

  &__footer {
    margin-top: 30px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 480px) {
      margin-top: 10px;
    }
  }

  &__text {
    @extend %family_poppins;

    font-size: 13px;
    letter-spacing: 0.01em;
    line-height: 26 /  13 * 100%;
    text-align: center;
    color: $grey;
  }

  &__images {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;

    div {
      flex: 1 1 33.333%;
      text-align: center;
    }
  }

  .btn-light {
    border: none;
  }
}

.card-form {
  &__title {
    @extend %family_poppins;

    font-weight: 600;
    font-size: 24px;
    color: $black;
    margin-bottom: 16px;

    @include adaptive-value('font-size', 24, 20, 1);
  }

  &__input {
    @extend %family_roboto;

    width: 100%;
    font-size: 14px;
    letter-spacing: 0.01em;
    color: $black-light;
    padding: 15px;
    border: 1px solid $border;
    border-radius: 10px;
    outline: none;
    transition: all 0.3s ease;

    &.error {
      border-color: $red4;
    }

    &::placeholder {
      color: rgba($black-light, 0.5);
      transition: all 0.3s ease;
    }

    &:focus,
    &:hover,
    &:active,
    &:focus-visible {
      border-color: $blue-light;
    }

    &:focus::placeholder {
      color: transparent;
    }

    &_edit {
      &:focus,
      &:hover,
      &:active,
      &:focus-visible {
        border-color: $border;
      }
    }
  }

  &__error {
    min-height: 20px;
    font-size: 12px;
    color: $red4;
    line-height: 20px;
    padding-left: 15px;
    margin-bottom: 5px;

    &_edit {
      margin-bottom: 15px;
    }
  }

  .btn-form {
    margin-top: 15px;
    font-weight: 500;
    transition: all 0.3s ease;

    @media screen and (max-width: 480px) {
      margin-top: 5px;
    }

    &[disabled] {
      color: $white;
      cursor: not-allowed;
    }
  }

  &__main {
    padding: 33px 30px 5px;
    background: rgba($background-light, 0.5);
    border-top: 0.5px solid $border;
    border-bottom: 0.5px solid $border;
  }

  &__buttons {
    padding: 25px 30px 0;
  }

  &__desc {
    @extend %title_text;

    text-align: center;
  }
}

.card-element {
  position: relative;
  padding: 15px 10px;
  border: 1px solid $border;
  background: $white;
  will-change: opacity, transform;
  border-radius: 10px;
  color: $black;
}

.card-error-message {
  @extend %item;

  font-size: 10px;
  color: $red;
}
