@import './main.scss';
@import '../colors.scss';
@import '../functions.scss';

%form_select_text {
  @extend %family_roboto;

  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: $blue-light;
  border-radius: 8px;
  border: none;
}

%form_label_text {
  @extend %family_roboto;

  font-weight: 300;
  font-size: 12px;
  color: $black-light;
}
