@import '../../../assets/texts/group_details.scss';

.group-details-container {
  // padding: 15px 50px 0 46px;
  min-height: 480px;
  display: flex;
  align-items: flex-start;
  padding-top: 24px;
  column-gap: 24px;

  @media screen and (max-width: 1360px) {
    flex-wrap: wrap;
    justify-content: center;
  }

  @media screen and (max-width: 768px) {
    padding: 4px;
  }

  &__column {
    width: 100%;
    max-width: 532px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
      max-width: 100%;
    }

    &:first-child {
      margin-bottom: 24px;
    }

    .session-item {
      display: flex;
      min-height: 0;

      &__footer {
        align-items: stretch;
        justify-content: space-between;
        margin: 15px 0 0;

        > * {
          flex: 0 1 48%;
        }
      }

      @media screen and (max-width: 768px) {
        .session-item__footer {
          margin-top: 0;
        }
      }

      @media screen and (max-width: 450px) {
        flex-wrap: wrap;

        .session-item__footer {
          margin-top: 10px;
        }
      }

      @media screen and (max-width: 480px) {
        .session-item__footer > *:not(:last-child) {
          margin-bottom: 0 !important;
        }
      }
    }
  }

  // modification
  .card-group__text {
    font-size: 12px;
  }
}

.session-tab-box {
  padding-bottom: 30px;
  width: 100%;
  //padding: 30px;
  //max-height: 660px;
  border-radius: 20px;
  background-color: #fff;
  //overflow: auto;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  .ant-tabs-nav-list {
    justify-content: normal !important;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 57px !important;

    @media screen and (max-width: 768px) {
      margin-left: 22px !important;
    }
  }
}

.group-btns {
  margin-top: 27px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  &.end {
    justify-content: end;
  }

  .btn-form {
    width: 32%;
    min-width: 160px;
    margin-bottom: 11px;
    height: 38px;
    min-height: 38px;
    font-size: 12px;

    &__cancel {
      background: $white;
      color: $red4;
      border: 1px solid $red4;
    }

    &__light {
      background: $white;
      color: $blue-light;
      border: 1px solid $blue-light;
    }
  }
}

.wait-members {
  &__page {
    background-color: $white;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 15px;
    margin-top: 24px;
    padding: 40px 5px 60px;
  }

  &__greeting {
    @extend %greeting;
  }

  &__therapist-name {
    @extend %therapist_name;

    padding: 16px 0 26px;
    text-align: center;
  }

  &__group-details-description {
    @extend %group_description;

    padding-top: 33px;
    text-align: center;
  }

  &__image-container {
    position: relative;
    width: 100%;
    max-width: 401px;
    height: 324px;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
