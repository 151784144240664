@import '../../../../../assets/texts/questions_text.scss';
@import '../../../../../assets/mixin.scss';

.form-reschedule-group {
  width: 100%;
  max-width: 560px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }

  @import '../../../../../assets/selects.scss';

  .input-field__input,
  .input-field-other__input {
    margin: 8px 0;
  }

  .input-field__label {
    top: 1px;
  }

  &__small-row {
    max-width: 400px;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__column {
    width: 49%;
    display: flex;
    flex-direction: column;
  }

  .input-field_error {
    .ant-select-selector,
    .form-time-select {
      border: 1px solid $red !important;
    }
  }

  &__buttons {
    padding: 25px 30px;
    background-color: $white;
    border-radius: 0 0 15px 15px;
    border-top: 0.5px solid #e6e7e9;

    @include adaptive-value('padding-left', 30, 10, 1);
    @include adaptive-value('padding-right', 30, 10, 1);
  }

  &__fields {
    padding: 25px 30px 70px;
    background: rgba(245, 246, 250, 0.5);
    border-top: 0.5px solid #e6e7e9;

    @include adaptive-value('padding-left', 30, 10, 1);
    @include adaptive-value('padding-right', 30, 10, 1);
    @include adaptive-value('padding-bottom', 70, 40, 1);
  }
}
