@import './main.scss';
@import '../colors.scss';
@import '../functions.scss';
//@extend %family_roboto;
//@extend %family_poppins;
%item {
  @extend %family_poppins;

  font-size: 16px;
  line-height: 25px;
  font-weight: 600;
  letter-spacing: letter-spacing(6.25);
  color: $black;
}

%label {
  @extend %family_poppins;

  font-size: 18px;
  line-height: 21px;
  font-weight: 600;
  color: $black;
}

%text {
  @extend %family_poppins;

  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  color: $grey5;
}

%avatar {
  @extend %family_poppins;

  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.01em;
  text-align: left;
  color: $white;
}

%add-therapist-label {
  @extend %family_poppins;

  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: left;
  color: #44444f;
}

%add-therapist-btn {
  @extend %family_poppins;

  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: $white-grey;
}

%therapist-avatar {
  @extend %family_poppins;

  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.01em;
  color: $white;
}

%header {
  @extend %family_roboto;

  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: $blue-light;
}

%username {
  @extend %family_poppins;

  font-weight: bold;
  font-size: 14px;
  color: $black;
  line-height: 1em;
}

%name {
  @extend %family_poppins;

  font-weight: bold;
  font-size: 14px;
  color: $black;
  line-height: 1;
}

%time {
  @extend %family_roboto;

  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0.01em;
  color: #3b3b3b;
}

%success-change-group-title {
  @extend %family_poppins;

  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: $black;
}

%every-label {
  @extend %family_poppins;

  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: $black;
}

%restart-group-label {
  @extend %family_poppins;

  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: left;
  color: $black-light;
}
