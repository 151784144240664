@import '../../../../assets/texts/account_text.scss';
@import '../../../../assets/colors.scss';

.account-therapist {
  width: 404px;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 20px;
  // box-shadow: 0 0 5px $shadow;
  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  &__avatar {
    margin-bottom: 24px;
    height: 138px;
    display: flex;
    justify-content: center;
    background: url(../../../../assets/image/account/therapist-info-bg.png) no-repeat top center;

    @media screen and (max-width: 1200px) {
      background: none;
    }
  }

  &__avatar-wrapper {
    position: absolute;
    justify-content: center;
    align-items: center;
  }

  &__avatar-edit-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    margin-top: 122px;
    margin-left: 80%;
    cursor: pointer;
  }

  &__avatar-change {
    position: absolute;
    bottom: -6px;
    right: -6px;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: $blue-light url(../../../../assets/image/account/camera.svg) no-repeat 6px 7px;

    input {
      cursor: pointer;
      opacity: 0;
      height: 24px;
      width: 24px;
    }
  }

  &__img {
    margin-top: 54px;
    width: 84px;
    height: 84px;
    border: 1px solid $white-grey;
    border-radius: 5px;
    background-color: $white-grey;
  }

  &__title-name {
    padding: 0 25px 26px;
    display: flex;
    justify-content: center;

    @extend %name_profile;

    &.admin {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 35px;
    }
  }

  &__bio {
    margin: 0 15px 0 25px;
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    border-top: 1px solid $white-grey;
    overflow: auto;
    overflow-wrap: anywhere;

    span {
      @extend %value_profile;

      .show-more {
        color: #50b5ff;
        font-weight: 500;
        cursor: pointer;
        white-space: nowrap;
      }
    }
  }

  &__list-info {
    margin: 0 15px 25px 25px;
    display: flex;
    flex-direction: column;
  }

  &__item {
    margin: 7px 0;
    display: flex;
    flex-direction: column;
  }

  &__label {
    @extend %label_profile;

    padding-bottom: 5px;
  }

  &__value {
    @extend %value_profile;
  }

  &__edit {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    span {
      color: $blue-light;
    }
  }
}

.account-therapist-box {
  padding: 0 14px 7px 30px;

  @media screen and (max-width: 768px) {
    padding: 4px;
  }
}

@supports (-webkit-touch-callout: none) {
  .account-therapist__bio {
      overflow: scroll;
    }
}
