@import '../../assets/colors.scss';

.change-group-template {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &__body {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    background: $background-light;
  }

  &__header {
    padding: 21px 21px 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  &__groups {
    width: 100%;
  }

  &__confirm {
    padding-top: 88px;
  }

  //.ant-spin-nested-loading {
  //  height: 100%;
  //}
  //
  //.ant-spin-container {
  //  height: 100%;
  //}

  &__close {
    max-height: 56px;
    button {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      background: $white url("../../assets/image/account/close4.svg") center no-repeat;
    }
  }
}
