@import './main.scss';
@import '../colors.scss';
@import '../functions.scss';
//  @extend %family_poppins;
//   @extend %family_roboto;

%title {
  @extend %family_poppins;

  font-size: 14px;
  line-height: 25px;
  font-weight: 500;
  letter-spacing: letter-spacing(6.25);
  color: $grey3;
}

%history_value {
  @extend %family_poppins;

  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #3a3a3a;
}

%sub_title {
  @extend %family_roboto;

  font-size: 14px;
  line-height: 25px;
  font-weight: 600;
  letter-spacing: letter-spacing(6.25);
  color: $black-light;
}

%button {
  @extend %family_roboto;

  font-size: 14px;
  color: $blue-light;
}

%name {
  @extend %family_poppins;

  font-size: 16px;
  line-height: 25px;
  font-weight: 600;
  letter-spacing: letter-spacing(6.25);
  color: $black;
}

%text {
  @extend %family_poppins;

  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  letter-spacing: letter-spacing(6.25);
  color: $grey3;
}

%status_text {
  @extend %family_poppins;

  font-size: 10px;
  font-weight: 500;
  color: $grey3;
}
