@import '../colors.scss';
@import './main.scss';

%slider_title {
  @extend %family_poppins;

  font-size: 20px;
  line-height: 28 / 20 * 100%;
  font-weight: 500;
  color: $white;
}

%slider_link {
  @extend %family_poppins;

  font-size: 14px;
  line-height: 23 / 14 * 100%;
  font-weight: 400;
  letter-spacing: letter-spacing(6.25);
  color: $white;
}
