@import '../../../assets/texts/change_group.scss';
@import '../../../assets/colors.scss';
@import '../../../assets/mixin.scss';

.change-group-wrap {
  padding: 88px 15px 100px;
  width: 100%;

  @media screen and (max-width: 768px) {
    padding: 40px 15px;
  }

  @media screen and (max-width: 480px) {
    padding: 20px 15px;
  }
}

.change-group {
  max-width: 560px;
  margin: 0 auto;
  border-radius: 15px;
  background: $white;
  transition: all 0.3s ease;

  &.full-width {
    max-width: none;

    @media screen and (min-width: 768px) {
      margin: 0 25px;
    }
  }

  &_pad {
    padding-top: 120px;

    @media screen and (max-width: 768px) {
      padding-top: 60px;
    }

    @media screen and (max-width: 480px) {
      padding-top: 30px;
    }
  }

  .box-account-container {
    min-height: auto;
    max-height: 100%;
  }

  .ant-spin-nested-loading {
    min-height: 320px;
  }

  .custom-box-account {
    max-height: none;
    background-color: #fafafb;
  }
}

.check-in-start {
  padding: 120px 15px 50px;
}

.change-group-container {
  position: relative;
  padding: 24px 30px;
  text-align: center;
  max-width: 560px;
  margin: 0 auto;
  border-radius: 15px;
  background: $white;
  transition: all 0.3s ease;

  @media screen and (max-width: 768px) {
    padding: 18px;
  }

  &__cancel {
    @extend %family_poppins;

    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    color: $blue-light;
    margin-top: 10px;
    transition: all 0.3s ease;
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid transparent;
  }

  &__cancel:hover {
    border: 1px solid $blue-light;
  }

  &__back-wrap {
    max-width: 88px;
    height: 88px;
    margin: 0 auto;
    border-radius: 22px;
    background: $blue-light;
    box-shadow: 3px -5px 40px rgba(205, 205, 212, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    margin-top: 8px;

    &.reverse {
      img {
        transform: scaleX(-1);
      }
    }
  }

  &__before-image {
    margin-top: 29px;
    margin-bottom: 25px;

    &.mb-sm {
      margin-top: 10px;
    }

    @media screen and (max-width: 480px) {
      width: 60px;
    }
  }

  &__before-image {
    margin-top: 29px;
    margin-bottom: 25px;

    @media screen and (max-width: 480px) {
      width: 60px;
    }
  }

  &__main-title {
    @extend %main-title;

    margin-bottom: 50px;
    text-align: center;

    &.mb-sm {
      margin-bottom: 20px;
    }

    @media screen and (max-width: 480px) {
      margin-bottom: 20px;
      font-size: 24px;
    }
  }

  &__desc-text {
    @extend %family_roboto;

    font-size: 18px;
    line-height: 32 / 18 * 100%;
    text-align: center;
    color: $black;
    margin-bottom: 50px;

    &.no-mar {
      margin-bottom: 0;
    }

    &.mb-sm {
      margin-bottom: 20px;
    }

    @extend %desc-text;

    @media screen and (max-width: 480px) {
      margin-bottom: 20px;
      font-size: 16px;
    }
  }

  &__sub-text {
    @extend %family_roboto;

    font-size: 18px;
    line-height: 32 / 18 *100%;
    text-align: center;
    color: $grey;
    margin-top: 15px;
  }

  &__article {
    margin: 30px 0;

    @extend %label;
  }

  &__question {
    text-align: center;

    @extend %questin;
  }

  &__sub-question {
    margin: 15px 0 45px;
    text-align: center;

    @extend %sub-questin;
  }

  &__text-label,
  &__label {
    margin: 29px 0 6px;

    @extend %label;
  }

  &__answers {
    width: 100%;
    display: flex;
    flex-direction: column;

    @import '../../../assets/selects.scss';

    .form-select {
      margin: 20px 0 100px;
    }
  }

  &__answer {
    margin: 5px 15px 5px 5px;
    height: 34px;
    display: flex;
    align-items: center;

    @extend %answer;

    .ant-checkbox-wrapper {
      margin-right: 11px;
    }
  }

  .textarea-cotnainer {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .btn-back {
    position: absolute;
    top: 3px;
    left: -15px;
    cursor: pointer;
    border: none;
    outline: none;
    width: 75px;
    height: 75px;
    background: url(../../../assets/image/questions/back.svg) no-repeat center;
  }

  &__btn-close {
    padding-right: 6px;
    text-align: right;

    svg {
      cursor: pointer;
    }

    &.circle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 56px;
      height: 56px;
      background-color: #fff;
      border-radius: 50%;
      padding-right: 0;
    }
  }
}

.change-group-container.group-list {
  width: 100%;
  height: auto;

  .select-groups-item {
    .card-group__about-text {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }
  }

  .select-groups-item_more {
    .card-group__about-text {
      -webkit-line-clamp: unset;
    }
  }
}

.textarea-checker-cotnainer {
  width: 100%;
  display: flex;

  .ant-checkbox-wrapper {
    margin-left: 5px;
    margin-right: 11px;
  }
}

.single-large-answers {
  .btn-form {
    margin: 5px;
  }

  .btn-item {
    @extend %family_poppins;

    margin: 0 auto;
    max-width: 335px;
    font-size: 16px;
    color: $white-grey;
    height: 40px;

    @media screen and (max-width: 768px) {
      max-width: 100%;
    }
  }

  .btn-item:disabled {
    background-color: $grey9;
  }

  &__text {
    @extend %label;

    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 335px;
    margin: 0 auto;
    text-align: center;
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid white;
    margin-top: 10px;
    height: 40px;
    transition: border 200ms;
    color: $grey9;

    &:hover {
      border: 1px solid $grey9;
    }
  }
}

.confirm-finish {
  max-width: 560px;
  border-radius: 15px;
  background: $white;
  margin: 0 auto;

  &__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 42px 24px;
    font-size: 18px;
    font-weight: 500;

    @include adaptive-value('padding-left', 42, 24, 1);
    @include adaptive-value('padding-right', 42, 24, 1);

    img {
      padding-bottom: 17px;
    }
  }

  &__body {
    padding: 30px 30px 52px;
    width: 100%;
    background: rgba(245, 246, 250, 0.5);
    border-top: 0.5px solid #e6e7e9;
    border-bottom: 0.5px solid #e6e7e9;
    display: flex;
    flex-direction: column;
  }

  &__body-box {
    padding: 20px;
    width: 100%;
    border: 1px solid #e2e2ea;
    border-radius: 10px;
    background: $white;
  }

  &__body-title {
    @extend %label;

    font-weight: normal;
    display: flex;
    align-items: center;
    padding-bottom: 17px;
  }

  &__button {
    padding: 44px 30px 53px;
    width: 100%;
  }
}
