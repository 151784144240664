@import '../../../../assets/texts/account_text.scss';
@import '../../../../assets/colors.scss';

.group-details-card {
  padding: 32px 15px 50px;
  max-width: 532px;
  border-radius: 20px;
  background-color: #fff;

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }

  &__edit {
    display: flex;
    justify-content: flex-end;
  }

  &__edit-btn {
    cursor: pointer;
    white-space: pre;

    span {
      margin-left: 8px;
    }

    @extend %item_active;

    color: $blue-light !important;
  }

  .session-item {
    display: flex;
    border: none !important;
    padding: 0 !important;
    margin-bottom: 33px !important;

    &__footer > * {
      flex: 1 !important;
    }

    &__members {
      margin: 0;
    }

    @media screen and (max-width: 600px) {
      .session-item__footer {
        margin-top: 0;
      }
    }
  }

  &_admin {
    padding-bottom: 30px;

    .card-group {
      padding-bottom: 31px;

      &__tags-container {
        padding-bottom: 25px;
      }

      &__name, &__title {
        min-height: 0 !important;
      }
    }

    .card-therapist {
      &__name, &__info {
        min-height: 0;
      }
    }
  }

  &__non-active {
    max-width: 110px;
    padding: 4px 0 4px 15px;
    margin: -15px 0 20px -15px;
    border-radius: 0 5px 5px 0;
    background: $red4;

    @extend %card_non-active;
  }
}

.group-details-card-custom {
  &__status {
    max-width: 170px;
    width: unset;
    padding: 4px 0 4px 15px;
    margin: -15px 0 20px -15px;
    border-radius: 0 5px 5px 0;
  }
}
