@import '../../../../assets/texts/table_text.scss';
@import '../../../../assets/colors.scss';

.user-status-container {
  padding: 5px 0;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  @extend %row_status;

  &__status-1 {
    background-color: rgba($color: $blue-light, $alpha: 0.16);
    color: $blue-light;
  }

  &__status-2, &__status-8 {
    background-color: rgba($color: $red2, $alpha: 0.16);
    color: $red2;
  }

  &__status-3, &__status-9 {
    background-color: rgba($color: $orange, $alpha: 0.16);
    color: $orange;
  }

  &__status-4 {
    background-color: rgba($color: #e5b618, $alpha: 0.16);
    color: #e5b618;
  }

  &__status-5, &__status-6, &__status-7 {
    background-color: rgba($color: $grey3, $alpha: 0.16);
    color: $grey3;
  }
}