@import '../../../../assets/colors.scss';

.edit-therapist-photo-modal {
  .ant-modal-content {
    width: 560px !important;
    border-radius: 15px !important;
    padding: 0 !important;

    @media screen and (max-width: 768px) {
      width: auto !important;
    }
  }
  .ant-modal-body {
    padding: 0;
  }
}

.edit-therapist-photo-container {
  &__download-photo {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $background-light;
    border-top: 0.5px solid $border;
    border-bottom: 0.5px solid $border;
    padding: 24px 0 36px;
  }

  &__button {
    padding: 25px 30px;
  }

  span.ant-upload {
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
