@import './main.scss';
@import '../colors.scss';
@import '../functions.scss';
%template_title {
  @extend %family_poppins;

  font-size: 26px;
  line-height: 32px;
  font-weight: 500;
  color: $black;
}

%group_name {
  @extend %family_poppins;

  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: $black;
}

%group_text {
  @extend %family_roboto;

  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: letter-spacing(6.25);
  color: $grey;
}

%group_desc_text {
  @extend %family_roboto;

  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: letter-spacing(6.25);
  color: $grey;
}

%group_label {
  @extend %family_poppins;

  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  color: $black;
}

%group_therapist_name {
  @extend %family_roboto;

  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: letter-spacing(6.25);
  color: $blue-light;
}

%group_user_name {
  @extend %family_roboto;

  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: letter-spacing(6.25);
  color: $black;
}

%therapist_name {
  @extend %family_poppins;

  font-size: 18px;
  font-weight: 600;
  letter-spacing: letter-spacing(6.25);
  color: $black;
}

%therapist_before {
  @extend %family_roboto;

  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: letter-spacing(6.25);
  color: $grey;
}

%therapist_value {
  @extend %family_roboto;

  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: letter-spacing(6.25);
  color: $blue-light;
}

%fake-avatar {
  @extend %family_poppins;

  font-size: 44px;
  font-weight: 400;
  letter-spacing: letter-spacing(7.14);
  color: $white;
}
